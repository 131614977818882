import { faLightbulb } from '@fortawesome/pro-solid-svg-icons';

import { HoverExpandIcon } from '@angloeastern/react-library';
import useViewModel from './useViewModel';

const WhatsNewIcon: React.FC = () => {
  const { hide, color, label } = useViewModel();

  return (
    !hide && <HoverExpandIcon icon={faLightbulb} bgColor={color} text={label} />
  );
};

export default WhatsNewIcon;
