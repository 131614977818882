import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useTranslator } from '@hooks/useTranslator';
import { getCurrentLanguage, getDefaultLanguage } from '@utils/index';
import Decorator from './Decorator';

const useViewModel = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { language, changeLanguage } = useTranslator();
  const [currentLang, setCurrentLang] = useState(getCurrentLanguage(language));

  const options = [
    {
      id: 'en',
      name: 'EN'
    },
    {
      id: 'zh-hk',
      name: '中文'
    },
    {
      id: 'ja',
      name: '日語'
    }
  ];

  const handleClick = (lang: string) => {
    const defaultLang = getDefaultLanguage();
    const pathSplit = pathname.split('/');
    pathSplit.shift();

    if (currentLang === defaultLang) {
      if (defaultLang === lang) {
        pathSplit.splice(0, 0);
      } else {
        pathSplit.splice(0, 0, lang);
      }
    } else if (lang !== defaultLang) {
      pathSplit.splice(0, 1, lang);
    } else {
      pathSplit.splice(0, 1);
    }
    navigate(`/${pathSplit.join('/')}`);
    changeLanguage(lang);
  };

  useEffect(() => {
    setCurrentLang(getCurrentLanguage(language));
  }, [language]);

  return {
    currentLang,
    options,
    Decorator,
    handleClick
  };
};

export default useViewModel;
