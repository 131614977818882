import { useTheme } from 'styled-components';

import { useConfig } from '@context/Config';
import { useSlide } from '../context';

const useViewModel = () => {
  const {
    state: { previewUrl },
    hasError,
    isVideoFile
  } = useSlide();
  const {
    deviceCheck: { isAboveTablet }
  } = useConfig();
  const {
    colors: { buttercup }
  } = useTheme();

  return {
    previewUrl,
    isVideoFile,
    hasError,
    ripples: {
      size: isAboveTablet ? 80 : 48,
      color: buttercup
    }
  };
};

export default useViewModel;
