import { TableHead as THead } from '@angloeastern/react-library';
import useViewModel from './useViewModel';

const TableHead: React.FC = () => {
  const headers = useViewModel();

  return headers ? <THead headers={headers} /> : null;
};

export default TableHead;
