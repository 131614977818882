import { Loaders } from '@angloeastern/react-library';
import Layout from '@components/Layout';
import SC from './style';

const PageLoader: React.FC = () => (
  <Layout>
    <SC.Container>
      <Loaders.Ripples />
    </SC.Container>
  </Layout>
);

export default PageLoader;
