import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

const Content = styled.div`
  width: fit-content;
  height: auto;
  margin: 0 auto;
  align-self: center;
  text-align: center;

  svg {
    color: ${({ theme }) => theme.colors.blueDeFrance};
    font-size: 52px;
    margin-bottom: 20px;
  }
`;

const Filename = styled.div`
  color: white;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
`;

const Message = styled.div`
  color: white;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
`;

const Button = styled.a`
  display: inline-block;
  color: white;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  background-color: ${({ theme }) => theme.colors.darkMidnightBlue};
  padding: 12px 55px;
  border-radius: 8px;
  margin-top: 40px;
  outline: none;
  text-decoration: none;
`;

const SC = {
  Container,
  Content,
  Filename,
  Message,
  Button
};

export default SC;
