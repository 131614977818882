import SC from '../style';
import ClearButton from './ClearButton';
import DuplicateAlert from './DuplicateAlert';
import Input from './Input';
import SaveButton from './SaveButton';
import useViewModel from './useViewModel';

const SaveFavorite: React.FC = () => {
  const { dirty, label, duplicate, activeItem, onDuplicate } = useViewModel();

  return (
    <>
      {dirty && (
        <>
          <SC.Row>
            <SC.Label>{label}</SC.Label>
          </SC.Row>
          <Input onDuplicate={onDuplicate} />
        </>
      )}
      <SC.ButtonsRow>
        <ClearButton />
        {!duplicate ? (
          <SaveButton />
        ) : activeItem.guid || dirty ? (
          <DuplicateAlert />
        ) : null}
      </SC.ButtonsRow>
    </>
  );
};

export default SaveFavorite;
