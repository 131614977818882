import { createContext, useState } from 'react';

import type { ContextType } from './types';

export const Context = createContext({} as ContextType);

export const Provider = <P extends { children: JSX.Element }>({
  children
}: P) => {
  const [show, setShow] = useState(true);

  const openNotifications = () => setShow(true);

  const closeNotifications = () => setShow(false);

  return (
    <Context.Provider value={{ show, openNotifications, closeNotifications }}>
      {children}
    </Context.Provider>
  );
};
