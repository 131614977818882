import { FileTypes } from '@components/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const Container = styled.div<{
  $fileType: string;
  $isReady?: boolean;
  $hasError?: boolean;
}>`
  font-size: 18px;

  @media only screen and (min-width: 768px) {
    font-size: 27px;
  }

  ${({ $fileType, theme, $isReady, $hasError }) => {
    if ($isReady !== false) {
      switch ($fileType) {
        case FileTypes.FOLDER:
          return `
          font-size: 18px;
          color: ${theme.colors.buttercup50};
  
          @media only screen and (min-width: 768px) {
            font-size: 36px;
          }
        `;

        case FileTypes.PDF:
          return `
          color: ${theme.colors.flamingo};
        `;
        case FileTypes.EXCEL:
        case FileTypes.CSV:
        case FileTypes.SPREADSHEET:
          return `
          color: ${theme.colors.darkSpringGreen};
        `;
        case FileTypes.WORD:
          return `
          color: ${theme.colors.celticBlue};
        `;
        case FileTypes.POWERPOINT:
          return `
          color: ${theme.colors.darkPastelRed};
        `;
        case FileTypes.IMAGE:
          return `
          color: magenta;
        `;
        case FileTypes.VIDEO:
          return `
          color: blue;
        `;
        case FileTypes.AUDIO:
          return `
          color:  ${theme.colors.magicPotion};
        `;
        case FileTypes.ARCHIVE:
          return `
          color: ${theme.colors.buttercup50};
        `;
        case FileTypes.CODE:
          return `
          color: ${theme.colors.purpleNavy};
        `;
        default:
          return `
          color: ${theme.colors.licorice};
        `;
      }
    } else if ($hasError) {
      return `
      color: ${theme.colors.lightSilver};
      cursor: not-allowed;
    `;
    } else {
      return `
      @keyframes colorChange {
        0% { color: ${theme.colors.lightSilver}; }
        50% { color: ${theme.colors.philippineGray}; }
        100% { color: ${theme.colors.weldonBlue}; }
      }
      animation: colorChange 1s infinite;
      cursor: not-allowed;
    `;
    }
  }}
`;

const DocIcon = styled(FontAwesomeIcon)`
  font-size: inherit;
  color: inherit;
`;

const SC = {
  Container,
  DocIcon
};

export default SC;
