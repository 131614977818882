import styled from 'styled-components';

const Filename = styled.h3`
  flex: 1 0 40%;
  margin: 0;
  align-self: center;
  text-align: left;

  @media only screen and (min-width: 768px) {
    flex: 1 0 65%;
  }
`;

const Icon = styled.div`
  display: inline-block;
  width: 27px;
  height: 36px;
  margin-right: 12px;
`;

const Filler = styled.div`
  flex: 1 0;
`;

const SC = {
  Filename,
  Icon,
  Filler
};

export default SC;
