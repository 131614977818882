import { useContext } from 'react';

import createDataContext from '../createDataContext';
import { setCrumbs, setTitle } from './actions';
import reducer from './reducer';
import type { State } from './types';

const initialState: State = {
  crumbs: null,
  title: null
};

export const { Context, Provider } = createDataContext(
  reducer,
  {
    setCrumbs,
    setTitle
  },
  initialState
);

export const useView = () => useContext<any>(Context);
