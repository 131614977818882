import { apiGet } from '@api/callAPI';

export const fetchVesselVoyage = (
  vesselAeCode: string,
  fosVesselId: string,
  endDateTime: string,
  signal?: AbortSignal
) =>
  apiGet(
    `/navigation/voyage/${vesselAeCode}/${fosVesselId}/${endDateTime}`,
    true,
    { signal }
  );
