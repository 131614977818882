import { createElement, useEffect, useMemo } from 'react';

import { useView } from '@context/View';

const useViewModel = (widget: any, clearCrumbs = true) => {
  const {
    state: { title, crumbs },
    setCrumbs,
    setTitle
  } = useView();

  const theCrumbs = useMemo(() => (crumbs ? crumbs.join(' / ') : ''), [crumbs]);

  const theTiltle = useMemo(() => {
    const theTitle = Array.isArray(title) ? title : [title];

    return theTitle ? theTitle.join(' - ') : '';
  }, [title]);

  const theWidget = useMemo(() => {
    if (widget) {
      const Component = widget;
      return createElement(Component);
    }
    return null;
  }, [widget]);

  useEffect(() => {
    return () => {
      if (clearCrumbs) {
        setCrumbs(null);
        setTitle(null);
      }
    };
  }, []);

  return {
    theCrumbs,
    theTiltle,
    theWidget
  };
};

export default useViewModel;
