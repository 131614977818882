import { useEffect, useState } from 'react';

import { FileTypes } from '@components/index';
import type {
  SharePointfile,
  SharePointfiles
} from '@context/Sharepoint/types';
import useFileTypes from '@hooks/useFileTypes';

export interface Props {
  gallery: SharePointfiles;
  slides?: SharePointfiles | null;
}

const useViewModel = (slides?: SharePointfiles | null) => {
  const { isFileVideo } = useFileTypes();
  const [theSlides, setTheSlides] = useState<SharePointfiles | undefined>(
    undefined
  );

  useEffect(() => {
    if (slides && theSlides === undefined) {
      const filtered = slides.filter(
        (file: SharePointfile) =>
          file.type === FileTypes.IMAGE || isFileVideo(file.name)
      );
      setTheSlides(filtered);
    }
  }, [slides, theSlides]);

  return {
    theSlides,
    sliderfiles: theSlides || []
  };
};

export default useViewModel;
