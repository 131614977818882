import type { ReactNode } from 'react';
import React, { createContext, useContext, useState } from 'react';

export type ContextType = {
  showList: boolean;
  unreadCount: number;
  setShowList(b: boolean): void;
  setUnreadCount(n: number): void;
};

const Context = createContext({} as ContextType);

export const Provider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [showList, setShowList] = useState(false);
  const [unreadCount, setUnreadCount] = useState(9);

  return (
    <Context.Provider
      value={{
        showList,
        unreadCount,
        setShowList,
        setUnreadCount
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useSystemNotifications = () => useContext(Context);

export default Provider;
