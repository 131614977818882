import { useViewFile } from '../../context';
import FileIcon from './FileIcon';
import SC from './style';

const FileNameArea: React.FC = () => {
  const { showFileIcon, selected, fileType } = useViewFile();

  return showFileIcon ? (
    <>
      <SC.Icon>
        <FileIcon filetype={fileType} filename={selected.name} />
      </SC.Icon>
      <SC.Filename>{selected.name}</SC.Filename>
    </>
  ) : (
    <SC.Filler />
  );
};

export default FileNameArea;
