import styled from 'styled-components';

const Background = styled.div<{ $show: boolean }>`
  display: none;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 300;
  background: ${({ theme }) => theme.colors.sliderBackground};
  color: white;

  ${({ $show }) =>
    $show &&
    `
    display: flex;
  `}
`;

const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  height: 100%;
  background: transparent;
  display: flex;
  align-self: center;
`;

const Chevron = styled.div`
  display: none;

  @media only screen and (min-width: 1280px) {
    display: revert;
    width: 150px;
    flex: 0 0 150px;
    align-self: center;
    color: white;

    > svg {
      width: auto;
      font-size: 80px;
      cursor: pointer;

      &:hover {
        color: ${({ theme }) => theme.colors.brightYellow};
      }
    }
  }
`;

const Spacer = styled.div`
  width: 0;
  @media only screen and (min-width: 1280px) {
    flex: 1 1 150px;
  }
`;

const Center = styled.div`
  width: 100%;
  flex: 1 0;
  margin: 14px 0;

  @media only screen and (min-width: 1280px) {
    flex: 0 0;
    margin: 0;
    min-width: 1138px;
    align-self: center;
  }
`;

const SC = {
  Background,
  Container,
  Chevron,
  Spacer,
  Center
};

export default SC;
