import SC from './style';
import useViewModel from './useViewModel';

const BlankViewVessel: React.FC<{ view?: string }> = ({ view }) => {
  const message = useViewModel(view);

  return (
    <SC.Container>
      <SC.Message data-testid="message">{message}</SC.Message>
    </SC.Container>
  );
};

export default BlankViewVessel;
