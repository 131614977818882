import styled from 'styled-components';

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 24px 38px 30px;
`;

const HiddenInput = styled.input`
  display: none;
`;

const ImageHeading = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  padding-left: 21px;
  color: ${({ theme }) => theme.colors.tiffanyBlue};
`;

const Image = styled.img`
  width: 69px;
  height: 69px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.tiffanyBlue};
`;

const SC = {
  ImageContainer,
  ImageHeading,
  Image,
  HiddenInput
};

export default SC;
