import { Table } from '@angloeastern/react-library';
import SC from './style';

const Filler: React.FC<{ message?: string }> = ({ message }) => {
  const fakeData = [
    {
      dummy1: ' ',
      dummy2: ' ',
      dummy3: ' ',
      dummy4: ' ',
      dummy5: ' '
    }
  ];

  return (
    <SC.Container>
      {message ? <SC.Message>{message}</SC.Message> : <Table data={fakeData} />}
    </SC.Container>
  );
};

export default Filler;
