import { useEffect, useRef } from 'react';

import { useSidebar } from '@angloeastern/react-library';
import { useAuthUser } from '@context/AuthUser';
import { PageID, usePageID } from '@context/PageID';
import { useError } from '@hooks/useError';
import SC from './style';

const useViewModel = () => {
  const { error } = useAuthUser();
  const { setError } = useError();
  const { isPage } = usePageID();

  const {
    state: { isOpen: isSidebarOpen },
    closeSidebar
  } = useSidebar();

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (isSidebarOpen && ref.current && !ref.current.contains(e.target)) {
        closeSidebar();
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isSidebarOpen]);

  useEffect(() => {
    if (error) {
      setError(true);
    }
  }, [error]);

  const Main = isPage(PageID.LOCATION) ? SC.MainMap : SC.Main;
  const Inner = isPage(PageID.LOCATION) ? SC.MainInnerMap : SC.MainInner;

  if (error) return null;

  return {
    ref,
    Main,
    Inner,
    isSidebarOpen
  };
};

export default useViewModel;
