import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';

import { FileTypes } from '@components/index';
import { useConfig } from '@context/Config';
import { useSpDriveCtx } from '@context/Sharepoint/DriveCtx';
import type { SharePointfile } from '@context/Sharepoint/types';
import useFileTypes from '@hooks/useFileTypes';
import { useShortLivedUrls } from '@hooks/useShortLivedUrls';
import { errorTracker } from '@utils/errorTracker';
import { useSlider } from '../../context';
import { fetchSlide } from './api';

const Context = createContext({});

export const Provider: React.FC<{
  file: SharePointfile;
  children: any;
}> = ({ file, children }) => {
  const [hasError, setHasError] = useState(false);
  const { getDownloadUrl } = useShortLivedUrls(file);
  const {
    state: { driveId }
  } = useSpDriveCtx();
  const {
    state: { show, cache },
    cacheUrls,
    onPrevious,
    onNext
  } = useSlider();
  const {
    deviceCheck: { isAboveTablet }
  } = useConfig();
  const { isFileVideo } = useFileTypes();
  const [state, setState] = useState<{
    previewUrl: string | undefined;
    downloadUrl: string | undefined;
  }>({
    previewUrl: undefined,
    downloadUrl: undefined
  });
  const [originalOverflow, setOriginalOverflow] = useState('');
  const controller = new AbortController();
  const isVideoFile = isFileVideo(file.name);
  const resetState = {
    previewUrl: undefined,
    downloadUrl: undefined
  };

  const getUrls = useCallback(
    async (signal: AbortSignal) => {
      if (driveId) {
        try {
          const stateCopy = { ...state };
          const { url: previewUrl } = await fetchSlide(driveId, file, signal);
          stateCopy.previewUrl = previewUrl;
          stateCopy.downloadUrl = await getDownloadUrl(driveId);

          setState(stateCopy);
          if (!isVideoFile) {
            cacheUrls({
              id: file.id,
              ...stateCopy
            });
          }
        } catch (e: any) {
          errorTracker(e);
          setHasError(true);
        }
      }
    },
    [driveId, file]
  );

  useEffect(() => {
    if (!isAboveTablet) {
      if (show) {
        setOriginalOverflow(document.body.style.overflow);
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = originalOverflow;
      }
    }
  }, [show, isAboveTablet, originalOverflow]);

  useEffect(() => {
    if (file) {
      if (file.id && cache && cache[file.id] && !isVideoFile) {
        setState(cache[file.id]);
      } else if ((file.type === FileTypes.IMAGE || isVideoFile) && show) {
        setState(resetState);
        getUrls(controller.signal);
      }
    }
  }, [file, show, cache, getUrls]);

  useEffect(() => {
    return () => {
      setState(resetState);
      controller.abort();
      document.body.style.overflow = originalOverflow;
    };
  }, []);

  return (
    <Context.Provider
      value={{
        show: show && file,
        error: hasError,
        state,
        fileName: file.name,
        isVideoFile,
        onPrevious,
        onNext
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useSlide = () => {
  return useContext<any>(Context);
};
