import { apiGet } from '@api/callAPI';

export const getDrives = (signal?: AbortSignal) =>
  apiGet(`/sp/drives`, true, { signal });

export const getCategories = (
  driveId: string,
  signal?: AbortSignal,
  code = ''
) => apiGet(`/sp/${driveId}/categories/${code}`, true, { signal });

export const getCategoriesTechnical = (driveId: string, signal?: AbortSignal) =>
  apiGet(`/sp/${driveId}/technical`, true, { signal });
