import { useEffect, useState } from 'react';

import SC from './style';

const MessageRotator: React.FC<{ messages: string[]; frequency?: number }> = ({
  messages,
  frequency = 10000
}) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const msglen = messages.length;

    if (msglen > 1) {
      setTimeout(() => {
        let newIndex = index + 1;
        newIndex = newIndex >= msglen ? 0 : newIndex;
        setIndex(newIndex);
      }, frequency);
    }
  }, [index]);

  return <SC.Container>{messages[index]}</SC.Container>;
};

export default MessageRotator;
