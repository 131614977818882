import { useTranslator } from '@hooks/useTranslator';
import { getCurrentLanguage } from '@utils/index';
import { useMemo } from 'react';
import type { Size } from './style';

const useViewModel = (disable: boolean, size: Size) => {
  const { language } = useTranslator();

  const fontSize = useMemo(() => {
    if (disable) return null;
    const fontSizes = {
      en: 13,
      ja: 9,
      'zh-hk': 10
    } as any;
    switch (size) {
      case 'small':
        return fontSizes[getCurrentLanguage(language)];
      case 'large':
        return 16;
      default:
        return 13;
    }
  }, [size, language, disable]);

  return fontSize;
};

export default useViewModel;
