import styled from 'styled-components';

const Container = styled.div`
  background: white;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Message = styled.div`
  color: ${({ theme }) => theme.colors.weldonBlue};
`;

const SC = {
  Container,
  Message
};

export default SC;
