import { Suspense, lazy, memo } from 'react';

import { Loaders } from '@angloeastern/react-library';
import { FileTypes } from '@components/index';
import type {
  SharePointfile,
  SharePointfiles
} from '@context/Sharepoint/types';
import useFileTypes from '@hooks/useFileTypes';

const NotImage = lazy(() => import('../NotImage'));
const Thumbnail = lazy(() => import('../Thumbnail'));
const VideoThumb = lazy(() => import('../VideoThumb'));

const Thumbnails: React.FC<{
  gallery: SharePointfiles;
  theSlides: SharePointfiles | undefined;
}> = memo(({ gallery, theSlides }) => {
  const { isFileVideo } = useFileTypes();

  return (
    <Suspense fallback={<Loaders.Ripples />}>
      {gallery.map((file: SharePointfile) => {
        return theSlides && file.type === FileTypes.IMAGE ? (
          <Thumbnail
            key={file.id}
            image={file}
            slideIndex={theSlides.findIndex((slide) => slide.id === file.id)}
          />
        ) : theSlides && isFileVideo(file.name) ? (
          <VideoThumb
            key={file.id}
            data={file}
            slideIndex={theSlides.findIndex((slide) => slide.id === file.id)}
          />
        ) : (
          <NotImage data={file} key={file.id} />
        );
      })}
    </Suspense>
  );
});

export default Thumbnails;
