import type { IAutoExceptionTelemetry } from '@microsoft/applicationinsights-web';
import { trackException } from '../../appinsights';

export const errorTracker = (error: any) => {
  console.error('Error << ', error.message);

  const json = error?.toJSON();

  const exception: IAutoExceptionTelemetry = {
    // status: json?.status || 'Unknown Status',
    message: error?.message || 'Unknown Message',
    url: error?.config?.url || 'Unknown URL',
    lineNumber: 0,
    columnNumber: 0,
    error: json || error,
    stackDetails: error?.stack
  };

  trackException(exception);
};
