import { faCircleQuestion } from '@fortawesome/pro-light-svg-icons';
import { useRef, useState } from 'react';
import type AvatarEditor from 'react-avatar-editor';

import { useProfile } from '@context/Profile';
import useImgUtils from '@hooks/useImgUtils';
import { useTranslator } from '@hooks/useTranslator';
import { useImageResizer } from './context';
import { avatarEditorStyle } from './style';

const useViewModel = () => {
  const { t } = useTranslator();
  const {
    state: {
      user: { profile }
    },
    edit
  } = useProfile();
  const { removePrefix } = useImgUtils();
  const { show, setShow } = useImageResizer();
  const avatarRef = useRef<AvatarEditor | null>(null);
  const [scale, setScale] = useState<number>(0);

  const onSave = () => {
    const data = avatarRef?.current?.getImageScaledToCanvas().toDataURL();
    edit({
      ...profile,
      imageData: data ? removePrefix(data) : ''
    });
    setShow(false);
  };

  const imageData = profile.imageData
    ? `data:image/jpeg;base64,${profile.imageData}`
    : '';

  return {
    show,
    scale,
    icon: faCircleQuestion,
    labels: {
      uploadPhoto: t('myProfile.uploadPhoto'),
      cancel: t('myProfile.cancel'),
      save: t('myProfile.save')
    },
    editorProps: {
      ref: avatarRef,
      image: imageData,
      border: 0,
      scale: scale + 1,
      rotate: 0,
      style: avatarEditorStyle
    },
    onDecrease: () => scale > 0 && setScale(scale - 1),
    onIncrease: () => scale < 100 && setScale(scale + 1),
    onChangeScale: (e: { target: { value: any } }) =>
      setScale(Number(e.target.value)),
    onCancel: () => setShow(false),
    onSave
  };
};

export default useViewModel;
