import { Provider } from '@context/SearchableView';
import type { Props } from './Presentor';
import Presentor from './Presentor';

const SearchableView: React.FC<Props> = (props) => (
  <Provider>
    <Presentor {...props} />
  </Provider>
);

export default SearchableView;
