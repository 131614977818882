import { Fragment, lazy } from 'react';
import { Route } from 'react-router-dom';

import { getSupportedLanguages } from '@utils/index';
import Providers from './Providers';

const Login = lazy(() => import('@routes/Login'));
const SSOPage = lazy(() => import('@routes/SSOPage'));
const UserCallback = lazy(() => import('@routes/UserCallback'));
const ErrorPage = lazy(() => import('@routes/ErrorPage'));
const NotFound = lazy(() => import('@routes/NotFound'));

const PublicArea: React.FC = () => {
  return (
    <Providers>
      <Route path="/authSuccess" element={<Login />} />
      <Route path="/sso" element={<SSOPage />} />
      <Route path="/usercallback" element={<UserCallback />} />
      {getSupportedLanguages().map((locale: string) => {
        const lang = locale === 'en' ? '' : `/${locale}`;
        return (
          <Fragment key={locale}>
            <Route
              path={`/${locale === 'en' ? '' : locale}`}
              element={<Login />}
            />
            <Route path={`${lang}/sso`} element={<SSOPage />} />
            <Route path={`${lang}/login`} element={<Login />} />
            <Route path={`${lang}/error`} element={<ErrorPage />} />
            <Route path={`${lang}/500`} element={<ErrorPage />} />

            <Route path={`${lang}/location`} element={<Login />} />
            <Route path={`${lang}/worldmap`} element={<Login />} />
            <Route path={`${lang}/ourexperts`} element={<Login />} />
            <Route
              path={`${lang}/opex-supporting/:vesselAeCode/:transactionId`}
              element={<Login />}
            />
            <Route path={`${lang}/whatsnew`} element={<Login />} />
            <Route path={`${lang}/crew`} element={<Login />} />
            <Route path={`${lang}/documents`} element={<Login />} />
            <Route path={`${lang}/accounts`} element={<Login />} />
            <Route path={`${lang}/certificates`} element={<Login />} />
            <Route path={`${lang}/reports`} element={<Login />} />
            <Route path={`${lang}/drawings`} element={<Login />} />
            <Route path={`${lang}/kpi`} element={<Login />} />
            <Route path={`${lang}/users`} element={<Login />} />
            <Route path={`${lang}/*`} element={<NotFound />} />
          </Fragment>
        );
      })}
    </Providers>
  );
};

export default PublicArea;
