import { useConfig } from '@context/Config';
import { faUserCog } from '@fortawesome/pro-solid-svg-icons';

import { useTranslator } from '@hooks/useTranslator';

const useViewModel = () => {
  const { t } = useTranslator();
  const { USER_MANAGER_URL } = useConfig();

  const handleClick = () => {
    window.location.href = USER_MANAGER_URL;
  };

  return {
    icon: faUserCog,
    text: t('menu.userManagement'),
    handleClick
  };
};

export default useViewModel;
