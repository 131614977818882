import UnloadButton from './UnloadButton';
import DeleteButton from './DeleteButton';
import SC from './style';

const Controls: React.FC<{ show: boolean; onRemove(): void }> = ({
  show,
  onRemove
}) => {
  return show ? (
    <SC.Row>
      <SC.RightButton>
        <UnloadButton />
        <DeleteButton onClick={onRemove} />
      </SC.RightButton>
    </SC.Row>
  ) : null;
};

export default Controls;
