import { FileViewProvider } from '@angloeastern/react-library';
import type { Props } from './Presentor';
import Presentor from './Presentor';

const SharePointViewTab: React.FC<Props> = (props) => (
  <FileViewProvider>
    <Presentor {...props} />
  </FileViewProvider>
);

export default SharePointViewTab;
