import { Dialog } from '@angloeastern/react-library';
import SC from './style';
import useViewModel from './useViewModel';

const CancelConfirmation: React.FC = () => {
  const { confirmCancel, alertText, dialogProps } = useViewModel();

  return confirmCancel ? (
    <Dialog {...dialogProps}>
      <SC.AlertText>{alertText}</SC.AlertText>
    </Dialog>
  ) : null;
};

export default CancelConfirmation;
