export interface Thumbnail {
  height: number;
  width: number;
  url: string;
}

export interface SharePointfile {
  id?: string | null;
  parentId?: string | null;
  name: string;
  modifiedOnUtc: string;
  modifiedBy: string;
  type: string;
  thumbnail?: Thumbnail | null;
  isGalleryFolder?: boolean | null;
  mimeType: string;
}

export type SharePointfileField = keyof SharePointfile;

export type SharePointfiles = Array<SharePointfile>;

export interface TechPaperFile {
  id: string;
  name: string;
  modifiedOnUtc: string;
}

export type TechPaperFileField = keyof TechPaperFile;

export type Downloadable = SharePointfile | TechPaperFile;

export type TechPaperFiles = Array<TechPaperFile>;

export interface SharepointDrive {
  id: string;
  name: string;
}

export interface SharepointCategory {
  id: string;
  name: string;
}

export enum DriveName {
  DOCUMENTS = 'Vessel Documents',
  REPORTS = 'Vessel Documents',
  TECH_PAPERS = 'AE Resources - Technical',
  DRAWINGS = 'AE Ship Drawings',
  LEADERSHIP = 'AE Resources',
  FINANCIAL = 'Client Financial Reports',
  OUREXPERTS = 'AE Resources - Our Experts',
  WHATSNEW = 'AE Resources - Whats New'
}

export enum RootFolder {
  DOCUMENTS = 'Client Documents',
  REPORTS_CLAIMS = 'Claims',
  REPORTS_COMMERCIAL = 'Commercial',
  REPORTS_GALLERY = 'Gallery',
  REPORTS_OTHER = 'Other Reports',
  REPORTS_DRY_DOCK = 'Dry-Dock',
  DRAWINGS = 'Shipyard Drawings',
  MANUALS = 'Instruction Manuals',
  RESOURCES_LOOKOUT = 'Lookout',
  RESOURCES_PSC = 'PSC',
  RESOURCES_TECH_PAPERS = 'Technical Papers'
}
