import SystemNotifications from '../SystemNotifications';
import Avatar from './Avatar';
import Favorite from './Favorite';
import LangSelector from './LangSelector';
import LeftBar from './LeftBar';
import WhatsNewIcon from './WhatsNewIcon';
import SC from './style';
import useViewModel from './useViewModel';

const Topbar: React.FC = () => {
  const { icon, title, showTitle, openSidebar, onClick } = useViewModel();

  return (
    <SC.Container>
      <SC.TopSide>
        <SC.Bars onClick={openSidebar} aria-label="hamburger">
          <SC.Hamburger icon={icon} />
        </SC.Bars>
        {showTitle && <SC.Title>{title}</SC.Title>}
        <SC.TopRightSide>
          <SystemNotifications />
          <SC.LangBox>
            <LangSelector />
          </SC.LangBox>
          <Avatar />
        </SC.TopRightSide>
      </SC.TopSide>
      <SC.BottomSide>
        <SC.ControlArea>
          <LeftBar />
          <Favorite />
          <SC.WhatsNewBox onClick={onClick}>
            <WhatsNewIcon />
          </SC.WhatsNewBox>
        </SC.ControlArea>
      </SC.BottomSide>
    </SC.Container>
  );
};

export default Topbar;
