import { useContext } from 'react';

import { Context, Provider } from './context';
import { PageID } from './types';

export { PageID };

export const usePageID = () => {
  const ctx = useContext(Context);
  const { pageID } = ctx;

  const isPage = (pageid: PageID) => pageID === pageid;

  return {
    ...ctx,
    isPage
  };
};

export { Context, Provider };

export default Provider;
