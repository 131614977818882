import styled from 'styled-components';

const LoadersLayInner = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: wait;
  z-index: 9999;
`;

const LoadersLayContent = styled.div`
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const SC = {
  LoadersLayInner,
  LoadersLayContent
};

export default SC;
