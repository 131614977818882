import { createContext, useContext, useEffect, useState } from 'react';

import { useDeviceCheck } from '@angloeastern/react-library';
import useEncryption from '@hooks/useEncryption';
import { fetchConfig } from './api';

export const Context = createContext({});

export const Provider: React.FC<{ children: any }> = ({ children }) => {
  const { decode64 } = useEncryption();
  const [config, setConfig] = useState({});
  const [error, setError] = useState(false);
  const deviceCheck = useDeviceCheck();

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    async function fetchData() {
      const { data } = await fetchConfig(signal);
      setConfig(JSON.parse(decode64(data)));
    }

    try {
      fetchData();
    } catch (e: any) {
      console.error('Error!', e.message);
      setError(true);
      window.location.href = '/error';
    }

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <Context.Provider value={{ ...config, error, deviceCheck }}>
      {children}
    </Context.Provider>
  );
};

export const useConfig = () => useContext<any>(Context);
