import styled from 'styled-components';

const Container = styled.div`
  display: block;
  position: absolute;
  top: 50px;
  right: 15px;
  width: 320px;
  max-height: calc(100vh - 90px);
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.whiteSmoke};
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.5));
`;

const TmpBtn = styled.div`
  cursor: pointer;
  padding: 20px;
  border: 1px solid black;
`;

const SC = {
  Container,
  TmpBtn
};

export default SC;
