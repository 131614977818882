import { Loaders } from '@angloeastern/react-library';
import SC from './style';

const Loader: React.FC<{ cols?: number }> = ({ cols = 7 }) => {
  return (
    <SC.Container>
      <Loaders.Table rows={1} cols={cols} />
    </SC.Container>
  );
};

export default Loader;
