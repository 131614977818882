import type { SortOrder } from '@angloeastern/react-library';
import type {
  SharePointfile,
  SharePointfileField,
  SharePointfiles
} from '@context/Sharepoint/types';

export interface SearchResult {
  nextLink: string;
  results: SharePointfiles;
}

export interface RootFolder {
  id?: string | null;
  name: string;
}

export enum Actions {
  SET_DRIVE_ID = 'set-drive-id',
  FETCH_DATA = 'fetch-data',
  RESET_DATA = 'reseet-data',
  SET_PARENT_ID = 'set-parent-id',
  SET_ROOT_NAME = 'set-root-name',
  SET_ROOT_CODE = 'set-root-code',
  SET_ROOT_FOLDER = 'set-root-folder',
  SET_CURRENT_FOLDER = 'set-current-folder',
  FOLDER_STACK_POP = 'folder-stack-pop',
  FOLDER_STACK_PUSH = 'folder-stack-push',
  SET_PAGE = 'set-page',
  SET_ERROR = 'set-error',
  SEARCH_MORE = 'search-more',
  SEARCH = 'search',
  SET_BULK_DOWNLOAD = 'set-bulk-download'
}

export interface State {
  driveId?: string | null;
  rootName?: string | null;
  rootCode?: string | null;
  rootFolder?: RootFolder | null;
  categoryId?: string | null;
  data?: SharePointfiles | null;
  currentFolder?: SharePointfile | null;
  folderStack: SharePointfiles | [];
  searchToken?: string | null;
  loadMore: boolean;
  isReady: boolean;
  page: number;
  pageStack: Array<number> | [];
  canBulkDownload?: boolean | null;
  error?: boolean | null;
}

export interface Ctx {
  state: State;
  isAtRootFolder: boolean;
  folderStackPush(...args: any): void;
  folderStackPop(): void;
  setRootName(s: string): void;
  setRootCode(s: string): void;
  setPage(n: number): void;
  getCurrentFolder(): string;
  getParentFolder(): string;
  getParentFolderName(): string;
  sortFiles(sortBy: SharePointfileField, sortOrder: SortOrder): any;
  seeMore(b: boolean): void;
  setBulkDownload(b: boolean): void;
  resetData(): void;
}

export type Action =
  | { type: Actions.FETCH_DATA; payload: SharePointfiles }
  | { type: Actions.SEARCH; payload: SearchResult }
  | { type: Actions.RESET_DATA }
  | { type: Actions.SET_ERROR }
  | { type: Actions.SEARCH_MORE; payload: boolean }
  | { type: Actions.SET_DRIVE_ID; payload: string }
  | { type: Actions.SET_ROOT_NAME; payload: string }
  | { type: Actions.SET_ROOT_CODE; payload: string }
  | { type: Actions.SET_ROOT_FOLDER; payload: RootFolder | null }
  | { type: Actions.SET_CURRENT_FOLDER; payload: SharePointfile }
  | { type: Actions.FOLDER_STACK_PUSH; payload: SharePointfile }
  | { type: Actions.FOLDER_STACK_POP }
  | { type: Actions.SET_PAGE; payload: number }
  | { type: Actions.SET_BULK_DOWNLOAD; payload: boolean };
