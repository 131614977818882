import { createContext, useContext, useState } from 'react';

export const Context = createContext({});

interface Props {
  children: any;
}

export const Provider: React.FC<Props> = ({ children }: Props) => {
  const [show, setShow] = useState<boolean>(false);

  return (
    <Context.Provider value={{ show, setShow }}>{children}</Context.Provider>
  );
};

export const useSearchableView = () => useContext<any>(Context);
