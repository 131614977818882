import { apiGet, apiPost } from '@api/callAPI';

export const fetchUrl = (
  url: string,
  signal?: AbortSignal,
  cache = false,
  payload?: any
) =>
  payload
    ? apiPost(url, payload, cache, { signal })
    : apiGet(url, cache, { signal });
