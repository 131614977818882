export enum PageID {
  LOCATION = 'location',
  CREW = 'crew',
  ACCOUNTS = 'accounts',
  KPI = 'kpi',
  CERTIFICATES = 'certificates',
  REPORTS = 'reports',
  DOCUMENTS = 'documents',
  DRAWINGS = 'drawings',
  RESOURCES = 'resources',
  OUREXPERTS = 'ourexperts',
  OPEXSUPPORTING = 'opex-supporting',
  WHATSNEW = 'whatsnew',
  WORLDMAP = 'worldmap'
}

export type ContextType = {
  pageID: PageID | null;
  setPageID(p: PageID | null): void;
};
