import { createContext, useContext, useState } from 'react';

const Context = createContext({});

export const Provider: React.FC<{
  children: any;
}> = ({ children }) => {
  const [show, setShow] = useState(false);

  return (
    <Context.Provider
      value={{
        show,
        setShow
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useImageResizer = () => {
  return useContext<any>(Context);
};
