import {
  faCircleArrowDownRight,
  faCircleArrowUpRight
} from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SC from '../../style';
import { Color, Direction } from '../../types';

const Arrow: React.FC<{
  color: string;
  direction: Direction;
}> = ({ color, direction }) => {
  if (direction !== Direction.FLAT) {
    const icon =
      direction === Direction.UP
        ? faCircleArrowUpRight
        : faCircleArrowDownRight;
    const IconWrapper = color === Color.GREEN ? SC.GreenArrow : SC.YellowArrow;

    return (
      <IconWrapper>
        <FontAwesomeIcon icon={icon} />
      </IconWrapper>
    );
  }
  return null;
};

export default Arrow;
