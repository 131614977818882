import SC from './style';

const SummaryHeader: React.FC<{ children: any }> = ({ children }) => {
  return (
    <SC.Container>
      <SC.Inner>
        <SC.Items>{children}</SC.Items>
      </SC.Inner>
    </SC.Container>
  );
};

export default SummaryHeader;
