export enum Actions {
  SET_CRUMBS = 'set-crumbs',
  SET_TITLE = 'set-title'
}

export type Crumbs = Array<string> | null;
export type Title = Array<string> | string | null;

export interface State {
  crumbs: Crumbs;
  title: Title;
}

export type Action =
  | { type: Actions.SET_CRUMBS; payload: Crumbs }
  | { type: Actions.SET_TITLE; payload: Title };
