import { apiGet, apiPost } from '@api/callAPI';

export const fetchItems = (
  driveId: string,
  categoryId: string,
  signal?: AbortSignal
) => apiGet(`/sp/${driveId}/${categoryId}/items`, true, { signal });

export const search = (
  driveId: string,
  categoryId: string,
  term: string,
  nextLink: string | null,
  signal?: AbortSignal
) => {
  const url = `/sp/${driveId}/${categoryId}/search`;

  const payload: any = { query: term, nextLink: nextLink || '' };

  return apiPost(url, payload, true, { signal });
};
