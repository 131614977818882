import { Features } from '@components/index';
import { useAuthUser } from '@context/AuthUser';
import FreshdeskLink from './FreshdeskLink';
import UMLink from './UMLink';
import SC from './style';

const Footer: React.FC = () => {
  const { hasPermission, profile } = useAuthUser();

  return (
    <SC.Container>
      {(hasPermission(Features.ADMIN) ||
        profile?.roleName === 'AE Support') && <UMLink />}
      <FreshdeskLink />
    </SC.Container>
  );
};

export default Footer;
