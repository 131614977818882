import { Tooltip } from '@angloeastern/react-library';
import SC from './style';
import useViewModel from './useViewModel';

const FilterButton: React.FC = () => {
  const { icon, isOpen, count, tooltip, onClick } = useViewModel();

  return (
    <Tooltip direction="bottom" content={tooltip} width={80}>
      <SC.FilterBox $isOpen={isOpen} onClick={onClick} aria-label="filter">
        <SC.Filter icon={icon} />
        {count && <SC.Badge>{count}</SC.Badge>}
      </SC.FilterBox>
    </Tooltip>
  );
};

export default FilterButton;
