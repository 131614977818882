import { createContext, useContext, useState } from 'react';

export const Context = createContext({});

export const Provider: React.FC<{
  children: any;
}> = ({ children }) => {
  const [open, setOpen] = useState(false);

  return (
    <Context.Provider value={{ open, setOpen }}>{children}</Context.Provider>
  );
};

export const useListCardMenu = () => useContext<any>(Context);
