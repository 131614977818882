import { createContext, useState } from 'react';

import type { ContextType, PageID } from './types';

export const Context = createContext({} as ContextType);

export const Provider = <P extends { children: JSX.Element }>({
  children
}: P) => {
  const [pageID, setPageID] = useState<PageID | null>(null);

  return (
    <Context.Provider value={{ pageID, setPageID }}>
      {children}
    </Context.Provider>
  );
};
