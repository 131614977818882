import styled from 'styled-components';

const Container = styled.div`
  background: white;
  box-shadow: 0px 4px 4px ${({ theme }) => theme.colors.barShadow};
  border-radius: 0 0 5px 5px;
  padding: 3px 0;
  width: 166px;
`;

const Option = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.licorice};
  padding: 7.5px 14px;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.colors.tiffanyBlue25};
  }
`;

const SC = {
  Container,
  Option
};

export default SC;
