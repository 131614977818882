import { Input as BaseInput } from '@angloeastern/react-library';
import useViewModel from './useViewModel';

const Input: React.FC<{ onDuplicate(x: boolean): void }> = ({
  onDuplicate
}) => {
  const props = useViewModel(onDuplicate);

  return <BaseInput {...props} />;
};

export default Input;
