import { useEffect, useMemo, useState } from 'react';

import { errorTracker } from '@utils/errorTracker';
import { useNotifications as useNotif } from '../../../context/Notifications';
import { fetchNotificaitons } from './api';

interface INotification {
  message: string;
}

const messageTime = 5000;
const autoClose = false;

const useViewModel = () => {
  const [active, setActive] = useState(true);
  const [notifications, setNotifications] = useState<INotification[] | null>(
    null
  );
  const { show, openNotifications, closeNotifications } = useNotif();

  const getNotifications = async (signal?: AbortSignal) => {
    try {
      const response = await fetchNotificaitons(signal);
      setNotifications(response);
      if (response?.length && show) {
        openNotifications();
      }
    } catch (error: any) {
      if (error.name !== 'AbortError' && error.message !== 'canceled') {
        errorTracker(error);
      }
    }
    return null;
  };

  const messages = useMemo(() => {
    if (notifications) {
      return notifications.map((m) => m.message);
    }
    return [];
  }, [notifications]);

  useEffect(() => {
    if (show && notifications && notifications.length) {
      if (autoClose) {
        setTimeout(() => {
          closeNotifications();
        }, messageTime);
      }
    }
  }, [show]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    if (active && !notifications) getNotifications(signal);

    return () => {
      setTimeout(() => {
        controller.abort();
      }, 1000);
      setActive(false);
    };
  }, [active, notifications]);

  return {
    show: show && !(!notifications || notifications.length === 0),
    messages,
    closeNotifications
  };
};

export default useViewModel;
