import { Toast } from '@angloeastern/react-library';

import SC from '../style';
import useViewModel from './useViewModel';

const ToastBox: React.FC = () => {
  const { label, toastState, icons, handleToastClose } = useViewModel();

  return (
    <>
      {toastState.show && (
        <Toast onClose={handleToastClose} btnLabel={label}>
          <SC.ThumbFlex>
            {toastState.success ? (
              <SC.ThumbsUp icon={icons.success} />
            ) : (
              <SC.ThumbsDown icon={icons.failure} />
            )}
          </SC.ThumbFlex>
          <SC.ToastMsg>{toastState.message}</SC.ToastMsg>
        </Toast>
      )}
    </>
  );
};

export default ToastBox;
