import { Image } from '@angloeastern/react-library';
import { memo } from 'react';
import useViewModel from './useViewModel';

import SC from '../style';

const SlideImage: React.FC = memo(() => {
  const {
    fileName,
    previewUrl,
    fallbackImage,
    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
    handleMouseDown,
    handleMouseUp
  } = useViewModel();

  return (
    <Image
      renderer={SC.Image}
      fallbackSrc={fallbackImage}
      src={previewUrl}
      alt={fileName}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
    />
  );
});

export default SlideImage;
