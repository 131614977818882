import styled from 'styled-components';

const ControlIcon = styled.div<{ disabled: boolean }>`
  cursor: pointer;
  width: 18px;
  align-self: center;

  ${({ disabled }) =>
    disabled
      ? `
      pointer-events: none;  
      color: grey;
      a {
        color: grey;
      }
    `
      : `  
      color: white;
      a {
        color: white;
      }
  `};

  svg {
    height: auto;
  }

  @media only screen and (min-width: 768px) {
    &:hover {
      color: ${({ theme }) => theme.colors.brightYellow};

      a {
        color: ${({ theme }) => theme.colors.brightYellow};
      }
    }
  }
`;

const MenuBox = styled.div`
  position: relative;
`;

const Menu = styled.ul`
  display: block;
  position: absolute;
  top: 10px;
  right: 8px;
  margin: 0;
  background: white;
  list-style: none;
  box-shadow: 0px 2px 4px ${({ theme }) => theme.colors.gray400};
  border-radius: 0 0 10px 10px;
  padding-inline-start: 0;
  color: black;
`;

const MenuItem = styled.li`
  padding: 8px 12px;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.trout};
  cursor: pointer;
  background: white;

  &:hover {
    background: ${({ theme }) => theme.colors.tiffanyBlue25};
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightSmoke};
  }

  &:last-child {
    border-radius: 0 0 10px 10px;
  }

  > svg,
  > div {
    margin-right: 8px;
  }
`;

const SC = {
  ControlIcon,
  MenuBox,
  Menu,
  MenuItem
};

export default SC;
