import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const Container = styled.div`
  position: fixed;
  padding: 5px;
  width: 100%;
  min-height: 16px;
  text-align: center;
  background-color: rgba(243, 173, 28, 0.8);
  z-index: 1;
  display: flex;

  @media only screen and (min-width: 768px) {
    left: 56px;
    top: 56px;
    width: calc(100% - 56px);
  }
`;

const Inner = styled.div`
  display: flex;
  position: relative;
  gap: 3px;
  width: 100%;

  @media only screen and (min-width: 768px) {
    flex-direction: row-reverse;
  }
`;

const CrossIcon = styled(FontAwesomeIcon).attrs({
  'aria-label': 'close-icon'
})`
  color: black;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  :hover {
    color: white;
  }
`;

const BellIcon = styled(FontAwesomeIcon).attrs({
  'aria-label': 'bell-icon'
})`
  color: ${({ theme }) => theme.colors.flamingo};
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  position: fixed;
  top: 138px;
  left: 5px;
  z-index: 1;
  :hover {
    color: ${({ theme }) => theme.colors.maize};
  }

  @media only screen and (min-width: 768px) {
    top: 60px;
    right: 5px;
    left: unset;
  }
`;

const Message = styled.div`
  display: flex;
  flex: 1 0;
  width: calc(100% - 36px);
`;

const IconBox = styled.div`
  flex: 0 0 36px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SC = {
  Container,
  Inner,
  CrossIcon,
  Message,
  IconBox,
  BellIcon
};

export default SC;
