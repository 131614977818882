import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

export type Size = 'small' | 'large' | 'default';

const Container = styled.div<{ $size: Size }>`
  background: white;
  position: relative;
  flex: 1 0;
  --tooltip-icon-size: 20px;
  ${({ $size, theme }) => {
    switch ($size) {
      case 'small':
        return `
          padding: 0 6px;
          --title-margin-bottom: 4px;
          --title-line-height: 20px;
          --title-color: ${theme.colors.weldonBlue};
          --body-text-size: 22px;
          --body-line-height: 24px;
          --footer-text-size: 8px;
          --footer-transform: uppercase;
          --footer-whitespace: break-spaces;
          --footer-line-height: 20px;
        `;
      case 'large':
        return `
          padding: 20px;
          border-radius: 4px;
          --title-margin-bottom: 24px;
          --title-line-height: 24px;
          --title-color: ${theme.colors.yankeesBlue};
          --body-text-size: 34px;
          --body-line-height: 40px;
          --footer-text-size: 10px;
          --footer-transform: uppercase;
          --footer-whitespace: nowrap;
          --footer-line-height: 16px;
        `;
      default:
        return `
          padding: 0 15px;
          --title-margin-bottom: 4px;
          --title-line-height: 20px;
          --title-color: ${theme.colors.weldonBlue};
          --body-text-size: 22px;
          --body-line-height: 24px;
          --footer-text-size: 13px;
          --footer-transform: none;
          --footer-whitespace: nowrap;
          --footer-line-height: 20px;
        `;
    }
  }}
`;

const TitleBar = styled.div`
  display: flex;
  gap: 5px;
`;

const Title = styled.h3<{ $fontSize?: number }>`
  color: var(--title-color);
  margin: 4px 0 var(--title-margin-bottom);
  font-weight: 500;
  font-size: ${({ $fontSize }) => $fontSize}px;
  line-height: var(--title-line-height);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const IconBox = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  z-index: 10;
`;

const TooltipIcon = styled(FontAwesomeIcon)`
  width: var(--tooltip-icon-size);
  height: var(--tooltip-icon-size);
  color: ${({ theme }) => theme.colors.gray};

  &:hover {
    color: ${({ theme }) => theme.colors.robinEggBlue};
  }
`;

const Body = styled.div`
  color: ${({ theme }) => theme.colors.trout};
  margin: 4px 0;
  font-weight: bold;
  font-size: var(--body-text-size);
  line-height: var(--body-line-height);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Footer = styled.div`
  color: ${({ theme }) => theme.colors.weldonBlue};
  margin: 4px 0;
  line-height: var(--footer-line-height);
  font-size: var(--footer-text-size);
  text-transform: var(--footer-transform);
  white-space: var(--footer-whitespace);
`;

const Disabled = styled(Body)`
  color: ${({ theme }) => theme.colors.weldonBlue50};
`;

const SC = {
  Container,
  Title,
  Body,
  Disabled,
  Footer,
  TitleBar,
  IconBox,
  TooltipIcon
};

export default SC;
