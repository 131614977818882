import styled from 'styled-components';

const Background = styled.div<{
  $show: boolean;
  $topGutter?: number;
  $fullScreen?: boolean;
  $leftGutter?: number;
}>`
  display: flex;
  position: fixed;
  top: 0;
  right: -100%;
  background: ${({ theme }) => theme.colors.nero80};
  width: 100%;
  z-index: 20;

  ${({ $fullScreen }) =>
    !$fullScreen &&
    `   
    transition-property: right;
    transition-duration: 0.3s;
    transition-timing-function: linear;
    transition-delay: 0;
  `}

  ${({ $show }) =>
    $show &&
    `
    right: 0;
  `}

  @media only screen and (min-width: 768px) {
    width: ${({ $fullScreen, $leftGutter }) =>
      $fullScreen ? `calc(100% - ${$leftGutter}px)` : '40%'};
    ${({ $topGutter }) =>
      $topGutter &&
      `
      top: ${$topGutter}px;
    `}
  }

  @media only screen and (min-width: 768px) {
    div[aria-label='fail-icon'] {
      background: white;

      > div:last-child {
        max-width: 350px;
      }
    }
  }
`;

const Container = styled.div`
  position: relative;
  color: white;
  width: 100%;

  @media only screen and (min-width: 768px) {
    height: 100vh;
    margin: 20px;
    margin-bottom: 0;
  }

  @media only screen and (max-width: 640px) {
    canvas {
      height: auto !important;
      width: 100% !important;
    }
  }
`;

const CanvasContainer = styled.div<{
  $topGutter?: number;
  $fullScreen?: boolean;
}>`
  overflow: auto;
  height: calc(100vh - 86px);
  padding: 0 20px;

  @media only screen and (min-width: 768px) {
    height: ${({ $fullScreen }) => ($fullScreen ? `calc(100% - 96px)` : '80%')};
    padding: 0 10px;

    ::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }

  iframe {
    width: 100%;
    height: 100%;
  }
`;

const SC = {
  Background,
  Container,
  CanvasContainer
};

export default SC;
