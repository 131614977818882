import styled from 'styled-components';

const Pagination = styled.div.attrs({ id: 'pagination-wrap' })`
  display: flex;
  border-top: 1px solid ${({ theme }) => theme.colors.brightGray};
  background: ${({ theme }) => theme.colors.platinum};

  @media only screen and (min-width: 768px) {
    padding-right: 40px;
  }
`;

const Info = styled.div`
  flex: 1 0;
  padding: 12px 0;
  align-self: center;
  display: none;

  @media only screen and (min-width: 768px) {
    display: revert;
  }
`;

const Navigation = styled.div`
  flex: 1 0;
  display: flex;
  align-items: center;

  @media only screen and (min-width: 768px) {
    flex: 0 0;
  }
`;

const SC = {
  Pagination,
  Info,
  Navigation
};

export default SC;
