export enum Actions {
  HIDE_LABELS = 'hide-labels',
  SET_FULLSCREEN = 'set-fullscreen',
  SET_ZOOM_LEVEL = 'set-zoom-level',
  SET_CENTER = 'set-center',
  SHOW_LABELS = 'show-labels'
}

export type MapPosition = {
  lat: number;
  lng: number;
};

export interface State {
  fullScreen: boolean;
  labelsShown: boolean;
  zoomLevel: number;
  center: MapPosition;
}

export type Action =
  | { type: Actions.SET_ZOOM_LEVEL; payload: number }
  | { type: Actions.SET_FULLSCREEN; payload: boolean }
  | { type: Actions.SET_CENTER; payload: MapPosition }
  | { type: Actions.SHOW_LABELS }
  | { type: Actions.HIDE_LABELS };
