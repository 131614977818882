import { useOrganizations } from '@context/Organizations';
import { useTranslator } from '@hooks/useTranslator';

const useViewModel = (view?: string) => {
  const { t } = useTranslator();
  const {
    state: { organization }
  } = useOrganizations();

  return view
    ? t('text.selectOrgToViewPage', { drive: view })
    : organization
      ? t('text.selectedOrganization', { name: organization.name })
      : t('text.selectOrganization');
};

export default useViewModel;
