import {
  faChevronLeft,
  faChevronRight
} from '@fortawesome/pro-light-svg-icons';
import { useEffect } from 'react';

import type { SharePointfiles } from '@context/Sharepoint/types';
import { useSlider } from '../context';

const useViewModel = (files: SharePointfiles) => {
  const {
    state: { show, current, maxIndex },
    setMaxIndex,
    onPrevious,
    onNext
  } = useSlider();

  useEffect(() => {
    if (files && files.length && !maxIndex) {
      setMaxIndex(files.length - 1);
    }
  }, [files, maxIndex]);

  return {
    show,
    icons: {
      previous: faChevronLeft,
      next: faChevronRight
    },
    slide: files[current],
    onPrevious,
    onNext
  };
};

export default useViewModel;
