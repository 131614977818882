import {
  faFile,
  faFileAudio,
  faFileBinary,
  faFileCode,
  faFileCsv,
  faFileExcel,
  faFileImage,
  faFilePdf,
  faFilePowerpoint,
  faFileSpreadsheet,
  faFileVideo,
  faFileWord,
  faFileZipper
} from '@fortawesome/pro-light-svg-icons';
import {
  faFolderClosed,
  faFolderImage
} from '@fortawesome/pro-solid-svg-icons';
import { useMemo } from 'react';

import { FileTypes } from '@components/index';
import type { SharePointfile } from '@context/Sharepoint/types';
import useFileTypes from '@hooks/useFileTypes';

const useViewModel = (data: SharePointfile, fileType: FileTypes | string) => {
  const { getExtension } = useFileTypes();

  const theIcon = useMemo(() => {
    const ext = getExtension(data.name);
    switch (fileType) {
      case FileTypes.FOLDER:
        return data.isGalleryFolder ? faFolderImage : faFolderClosed;
      case FileTypes.PDF:
        return faFilePdf;
      case FileTypes.WORD:
        return faFileWord;
      case FileTypes.EXCEL:
        return ext === 'csv' ? faFileCsv : faFileExcel;
      case FileTypes.SPREADSHEET:
        return faFileSpreadsheet;
      case FileTypes.POWERPOINT:
        return faFilePowerpoint;
      case FileTypes.VIDEO:
        return faFileVideo;
      case FileTypes.AUDIO:
        return faFileAudio;
      case FileTypes.IMAGE:
        return faFileImage;
      case FileTypes.ARCHIVE:
        return faFileZipper;
      case FileTypes.CODE:
        return faFileCode;
      default:
        switch (ext) {
          case 'csv':
            return faFileCsv;
          case 'exe':
            return faFileBinary;
          default:
            return faFile;
        }
    }
  }, [data, fileType]);

  return theIcon;
};

export default useViewModel;
