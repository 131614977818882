import {
  faCompressWide,
  faDownload,
  faExpandWide,
  faXmark
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import FileNameArea from './FileNameArea';
import FileSelection from './FileSelection';
import SC from './style';
import useViewModel from './useViewModel';

export interface Props {
  topGutter?: number;
  leftGutter?: number;
  fullscreen?: boolean;
  showIframeToolbar?: boolean;
}

const Top: React.FC<Props> = () => {
  const {
    show,
    isFullScreen,
    showDowloadButton,
    downloading,
    showCrossmark,
    closeViewer,
    onDownloadClick,
    toggleFullScreen,
    showFullscreenButton
  } = useViewModel();

  const expandIcon = isFullScreen ? faCompressWide : faExpandWide;

  return (
    show && (
      <SC.Top>
        <FileNameArea />
        <SC.Controls>
          <FileSelection />
          {showFullscreenButton && (
            <SC.ControlIcon disabled={downloading}>
              <FontAwesomeIcon icon={expandIcon} onClick={toggleFullScreen} />
            </SC.ControlIcon>
          )}
          {showDowloadButton && (
            <SC.ControlIcon disabled={downloading}>
              <FontAwesomeIcon icon={faDownload} onClick={onDownloadClick} />
            </SC.ControlIcon>
          )}
          {showCrossmark && (
            <SC.ControlIcon disabled={false} onClick={closeViewer}>
              <FontAwesomeIcon icon={faXmark} />
            </SC.ControlIcon>
          )}
        </SC.Controls>
      </SC.Top>
    )
  );
};

export default Top;
