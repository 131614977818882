import SC from './style';

const CustomTab: React.FC<{
  $isActive: boolean;
  [x: string]: any;
}> = ({ $isActive, ...rest }) => {
  const { children } = rest;

  return (
    <SC.Tab $isActive={$isActive} {...rest}>
      <SC.TabLabel>{children}</SC.TabLabel>
      <SC.Bar />
    </SC.Tab>
  );
};

export default CustomTab;
