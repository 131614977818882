import { SelectDropdown } from '@angloeastern/react-library';
import SC from './style';
import useViewModel from './useViewModel';

const LanguageSelector: React.FC = () => {
  const { label, show, selected, options, Decorator, handleSelect } =
    useViewModel();

  return show ? (
    <SC.LangControl>
      <SC.Label>{label}</SC.Label>
      <SelectDropdown
        selected={selected}
        options={options}
        handleSelect={handleSelect}
        enableUnselect={false}
        displayComponent={Decorator}
      />
    </SC.LangControl>
  ) : null;
};

export default LanguageSelector;
