import SC from '../style';
import useViewModel from './useViewModel';

const EmailField: React.FC = () => {
  const { label, value } = useViewModel();

  return (
    <SC.FormControl>
      <SC.Label>{label} </SC.Label>
      <SC.Input type="email" name="email" value={value} disabled />
    </SC.FormControl>
  );
};

export default EmailField;
