import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

const Video = styled.video`
  width: 100%;
  object-fit: contain;

  @media only screen and (min-width: 1280px) {
    height: 100%;
    margin: 0 auto;
  }
`;

const SC = {
  Container,
  Video
};

export default SC;
