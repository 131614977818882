import { useFileViewer } from '@angloeastern/react-library';
import Display from './Display';
import Top from './Top';
import { useViewFile } from './context';
import SC from './style';

export interface Props {
  topGutter?: number;
  leftGutter?: number;
  showCrossmark?: boolean;
}

const Presentor: React.FC<Props> = ({ topGutter = 0, leftGutter = 0 }) => {
  const {
    state: { show }
  } = useFileViewer();

  const { isFullScreen } = useViewFile();
  return (
    <SC.Background
      $show={show}
      $topGutter={topGutter}
      $leftGutter={leftGutter}
      $fullScreen={isFullScreen}
    >
      {show ? (
        <SC.Container>
          <Top />
          <SC.CanvasContainer $fullScreen={isFullScreen} $topGutter={topGutter}>
            <Display />
          </SC.CanvasContainer>
        </SC.Container>
      ) : null}
    </SC.Background>
  );
};

export default Presentor;
