import { apiGet, apiPost } from '@api/callAPI';

export const fetchVessels = (
  orgAeCodes: string[] | null,
  signal?: AbortSignal
) => apiPost(`/vessels`, { orgAeCodes }, true, { signal });

export const vesselImage = (aeCode: string, signal?: AbortSignal) =>
  apiGet(`/vessels/image/${aeCode}`, true, { signal });

export const fetchVesselCurrency = (
  vesselAeCodes: Array<string> = [],
  signal?: AbortSignal
) => apiPost(`/opex/vessel/currency`, vesselAeCodes, true, { signal });
