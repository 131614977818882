import styled from 'styled-components';

const Container = styled.ul.attrs({ 'aria-label': 'list-loader' })`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 0;
  padding: 20px;
`;

const Item = styled.li`
  display: flex;
  justify-content: center;
  padding: 10px 16px;
  box-shadow: 0px 2px 2px rgb(0 0 0 / 10%);
  border-radius: 8px;
  background: white;
`;

const SC = {
  Container,
  Item
};

export default SC;
