import { Tooltip } from '@angloeastern/react-library';
import SC from './style';
import useViewModel from './useViewModel';

const DuplicateAlert: React.FC = () => {
  const { tooltip, icon } = useViewModel();

  return (
    <Tooltip direction="top" content={tooltip}>
      <SC.Box>
        <SC.Icon icon={icon} />
      </SC.Box>
    </Tooltip>
  );
};

export default DuplicateAlert;
