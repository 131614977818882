import styled from 'styled-components';

const Container = styled.div<{ $disabled: boolean }>`
  background: ${({ theme }) => theme.colors.platinum};
  padding: 12px 0;

  ${({ $disabled }) =>
    $disabled &&
    `
    cursor: not-allowed;
  `};
`;

const SC = {
  Container
};

export default SC;
