import { useAuthUser } from '@context/AuthUser';
import { useTranslator } from '@hooks/useTranslator';

const useViewModel = () => {
  const { t } = useTranslator();
  const {
    profile: { email }
  } = useAuthUser();

  return {
    label: t('myProfile.email'),
    value: email || ''
  };
};

export default useViewModel;
