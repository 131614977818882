import { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useLocation, useNavigate } from 'react-router-dom';

import { useConfig } from '@context/Config';
import { errorTracker } from '@utils/errorTracker';

const TokenRefresh: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const auth = useAuth();
  const { REFRESH_INTERVAL, IDLE_TIMEOUT } = useConfig();
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    const handleTokenRefresh = async () => {
      try {
        await auth.signinSilent();
      } catch (error: any) {
        console.warn('Token refresh failed', error);
        errorTracker(error);
        auth.removeUser();
        navigate('/login');
      }
    };

    const refreshInterval = parseInt(REFRESH_INTERVAL, 10) || 10 * 60 * 1000; // 10 minutes in milliseconds
    let intervalId: NodeJS.Timeout;

    if (isActive) {
      intervalId = setInterval(handleTokenRefresh, refreshInterval as number);
    }

    return () => clearInterval(intervalId);
  }, [auth, isActive]);

  useEffect(() => {
    const resetTimeout = () => {
      const timeout = parseInt(IDLE_TIMEOUT, 10) || 30 * 60 * 1000; // 30 minutes in milliseconds

      const timeoutId = setTimeout(() => {
        setIsActive(false);
      }, timeout);

      return () => clearTimeout(timeoutId);
    };

    if (isActive) {
      resetTimeout();
    }
  }, [isActive]);

  // Reset activity status on user interaction
  useEffect(() => {
    const resetActivity = () => {
      setIsActive(true);
    };

    const handleActivity = () => {
      resetActivity();
    };

    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keypress', handleActivity);

    return () => {
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keypress', handleActivity);
    };
  }, []);

  // Apply idle detection only if not in the /worldmap page or route
  useEffect(() => {
    const shouldApplyIdleDetection = !location.pathname.includes('/worldmap');
    setIsActive(shouldApplyIdleDetection);
  }, [location]);

  return null;
};

export default TokenRefresh;
