import { InputSkins } from '@angloeastern/react-library';
import { useFavorite } from '@context/Favorite';

const useViewModel = (onDuplicate: (x: boolean) => void) => {
  const {
    state: { activeItem },
    setActiveItem,
    exists
  } = useFavorite();

  const onChange = (value: string) => {
    if (!exists(value)) {
      setActiveItem({ ...activeItem, title: value });
      onDuplicate(false);
    } else {
      onDuplicate(true);
    }
  };

  return {
    outerComponent: InputSkins.Shadowed.Container,
    innerComponent: InputSkins.Shadowed.Input,
    value: activeItem?.title || '',
    onChange
  };
};

export default useViewModel;
