import { FailIcon, Loaders } from '@angloeastern/react-library';
import SlideImage from '../SlideImage';
import VideoPlayer from '../VideoPlayer';
import SC from '../style';
import useViewModel from './useViewModel';

const Viewport: React.FC = () => {
  const { previewUrl, isVideoFile, hasError, ripples } = useViewModel();

  return (
    <SC.Viewport>
      <SC.Slide>
        {previewUrl ? (
          isVideoFile ? (
            <VideoPlayer />
          ) : (
            <SlideImage />
          )
        ) : hasError ? (
          <FailIcon />
        ) : (
          <Loaders.Ripples {...ripples} />
        )}
      </SC.Slide>
    </SC.Viewport>
  );
};

export default Viewport;
