import { faRotateLeft } from '@fortawesome/pro-regular-svg-icons';

import { useFavorite } from '@context/Favorite';
import { useVessels } from '@context/Vessels';
import { useTranslator } from '@hooks/useTranslator';

const useViewModel = () => {
  const { t } = useTranslator();
  const { unselect, clearActiveItem } = useFavorite();
  const { clearFavorite } = useVessels();

  const onClick = () => {
    clearActiveItem();
    unselect();
    clearFavorite();
  };

  return {
    tooltip: t('favorites.unload'),
    icon: faRotateLeft,
    onClick
  };
};

export default useViewModel;
