import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SC from '../style';
import useViewModel from './useViewModel';

const SlideTop: React.FC = () => {
  const { icons, fileName, downloadUrl, onClose, onDownload } = useViewModel();

  return (
    <SC.SlideTop>
      <SC.BackIcon icon={icons.arrow} onClick={onClose} />
      <SC.Filename>{fileName}</SC.Filename>
      <SC.TopControls>
        {downloadUrl !== undefined && (
          <SC.TopControl onClick={onDownload}>
            <FontAwesomeIcon
              icon={icons.download}
              aria-label="download-image"
            />
          </SC.TopControl>
        )}
        <SC.CloseIcon onClick={onClose}>
          <FontAwesomeIcon icon={icons.close} aria-label="close-slider" />
        </SC.CloseIcon>
      </SC.TopControls>
    </SC.SlideTop>
  );
};

export default SlideTop;
