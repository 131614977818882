import type { Dispatch } from 'react';

import * as Api from '@context/Profile/api';
import type { Action, ProfileData } from './types';
import { Actions } from './types';

const getProfile = async (signal?: AbortSignal) => {
  const data = await Api.fetchProfile(signal);
  return data;
};

const getPermissions = async (signal?: AbortSignal) => {
  const data = await Api.fetchPermissions(signal);
  return data;
};

const getUserDetails = async (signal?: AbortSignal) => {
  const data = await Api.fetchUserDetails(signal);

  return data;
};

export const fetchData = (dispatch: Dispatch<Action>) => async () => {
  try {
    const permissions = await getPermissions();
    const profile = await getProfile();
    const userDetails = await getUserDetails();

    const data = { profile, permissions, userDetails };
    dispatch({ type: Actions.FETCH_DATA, payload: data });
  } catch (e: any) {
    const json = e?.toJSON?.();
    if (!json) console.error('Error >>: ', e);
    if (!json || json.status === 401) {
      dispatch({ type: Actions.SET_ERROR_UNAUTHORIZED });
    } else {
      dispatch({ type: Actions.SET_ERROR });
    }
    throw e;
  }
};

export const update =
  (dispatch: Dispatch<Action>) =>
  async (data: ProfileData, signal?: AbortSignal) => {
    await Api.updateProfile(data, signal);
    dispatch({ type: Actions.UPDATE, payload: data });
  };

export const edit = (dispatch: Dispatch<Action>) => (data: ProfileData) => {
  dispatch({ type: Actions.UPDATE, payload: data });
};

export const openMenu = (dispatch: Dispatch<Action>) => () => {
  dispatch({ type: Actions.SHOW_MENU, payload: true });
};

export const openForm = (dispatch: Dispatch<Action>) => () => {
  dispatch({ type: Actions.SHOW_FORM, payload: true });
};

export const closeMenu = (dispatch: Dispatch<Action>) => () => {
  dispatch({ type: Actions.SHOW_MENU, payload: false });
};

export const closeForm = (dispatch: Dispatch<Action>) => () => {
  dispatch({ type: Actions.SHOW_FORM, payload: false });
};
