import { usePagination as useBase } from '@angloeastern/react-library';
import { useConfig } from '@context/Config';
import { useTranslator } from '@hooks/useTranslator';

const useViewModel = <PageNav extends { (n: number): void }>(
  page: number,
  total: number,
  pageSize: number,
  navigate?: PageNav,
  info = false
) => {
  const { t } = useTranslator();
  const {
    deviceCheck: { isAboveMobile }
  } = useConfig();

  const siblingCount = isAboveMobile ? 1 : 0;

  const range = useBase({
    currentPage: page,
    totalCount: total,
    siblingCount,
    pageSize
  });

  const start = (page - 1) * pageSize + 1;
  const end = page * pageSize;

  if (range.length < 2) {
    return null;
  }

  let infoText = '';

  if (info) {
    if (start === total) {
      infoText = t('format.paginationInfoLast', { last: start, total });
    } else if (end > total) {
      infoText = t('format.paginationInfo', { start, end: total, total });
    } else {
      infoText = t('format.paginationInfo', { start, end, total });
    }
  }

  return {
    isAboveMobile,
    infoText: info ? infoText : '',
    baseProps: {
      totalCount: total,
      currentPage: page,
      pageSize,
      siblingCount,
      navigate
    }
  };
};

export default useViewModel;
