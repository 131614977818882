import {
  faBullhorn,
  faCompass,
  faCompassDrafting,
  faDollarSign,
  faFileCertificate,
  faFileChartPie,
  faFileLines,
  faGauge,
  faHeadSideBrain,
  faUsers
} from '@fortawesome/pro-light-svg-icons';

import type { MenuItemArray } from '@angloeastern/react-library';
import { Features } from '@components/types';

export const MenuConfig: MenuItemArray = [
  {
    id: 'location',
    name: 'menu.locationAndNavigation',
    icon: faCompass,
    isPage: true
  },
  {
    id: 'crew',
    name: 'menu.crew',
    icon: faUsers,
    isPage: true
  },
  {
    id: 'accounts',
    name: 'menu.financialOverview',
    icon: faDollarSign,
    isPage: true
  },
  {
    id: 'kpi',
    name: 'menu.kpis',
    icon: faGauge,
    isPage: true
  },
  {
    id: 'certificates',
    name: 'menu.certificates',
    icon: faFileCertificate,
    isPage: true
  },
  {
    id: 'reports',
    name: 'menu.reports',
    icon: faFileChartPie,
    isPage: true
  },
  {
    id: 'drawings',
    name: 'menu.vesselDrawings',
    icon: faCompassDrafting,
    isPage: true
  },
  {
    id: 'documents',
    name: 'menu.documents',
    icon: faFileLines,
    isPage: true
  },

  {
    id: 'resources',
    name: 'menu.aeResources',
    icon: faBullhorn,
    isPage: false
  },
  {
    id: 'ourexperts',
    name: 'menu.ourexperts',
    icon: faHeadSideBrain,
    isPage: true
  }
];

export const PermissionMap: { [x: string]: Features } = {
  location: Features.LOCATION_NAVIGATION,
  accounts: Features.FINANCIAL,
  certificates: Features.CERTIFICATES,
  crew: Features.CREWS,
  documents: Features.DOCUMENTS,
  drawings: Features.DRAWINGS,
  kpi: Features.KPI,
  reports: Features.REPORTS,
  resources: Features.AERESOURCES,
  ourexperts: Features.OUR_EXPERTS
};
