import { useOrganizations } from '@context/Organizations';
import type { Organization } from '@context/Organizations/types';
import { useTranslator } from '@hooks/useTranslator';

const useViewModel = () => {
  const { t } = useTranslator();
  const {
    state: { organizations }
  } = useOrganizations();

  const orgList = organizations
    ? organizations.map((org: Organization) => org.name).join(', ')
    : '';

  return {
    label: t('myProfile.organisation'),
    value: orgList
  };
};

export default useViewModel;
