import { Pagination, ViewFile } from '@components/index';
import { Provider } from './context';
import EmptyContent from './EmptyContent';
import Gallery from './Gallery';
import NoDriveOrData from './NoDriveOrData';
import SeeMoreButton from './SeeMoreButton';
import SC from './style';
import useViewModel from './useViewModel';

export interface Props {
  moduleName: string;
  gutterSize?: number;
}

const SharePointDocList: React.FC<Props> = ({
  moduleName,
  gutterSize = 227
}) => {
  const {
    noDataOrDrive,
    isReady,
    error,
    atRootWithEmptyData,
    showParentFolder,
    currentFolder,
    showGallery,
    dataset,
    slides,
    DisplayComponent,
    displayProps,
    paginationProps,
    searchToken,
    loadMore,
    folderStackPop,
    onSeeMore
  } = useViewModel();

  if (noDataOrDrive) {
    return (
      <NoDriveOrData isReady={isReady} error={error} gutterSize={gutterSize} />
    );
  }

  if (atRootWithEmptyData) return <EmptyContent gutterSize={gutterSize} />;

  return (
    <Provider moduleName={moduleName}>
      <SC.TabContent>
        {showParentFolder && (
          <SC.ParentFolder onClick={folderStackPop}>
            {currentFolder?.name}
          </SC.ParentFolder>
        )}
        {showGallery ? (
          <Gallery gallery={dataset} slides={slides} />
        ) : (
          <>
            {dataset.length > 0 ? (
              <>
                <DisplayComponent {...displayProps} />
                <ViewFile topGutter={56} />
              </>
            ) : (
              <EmptyContent gutterSize={gutterSize} />
            )}
          </>
        )}
      </SC.TabContent>
      <Pagination {...paginationProps} />
      {searchToken && <SeeMoreButton loading={loadMore} onClick={onSeeMore} />}
    </Provider>
  );
};

export default SharePointDocList;
