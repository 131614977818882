import { createContext, useReducer } from 'react';

const createDataContext = (reducer: any, actions: any, defaultValue: any) => {
  const Context = createContext({});

  const Provider: React.FC<{ children: any; [x: string]: any }> = ({
    children,
    ...rest
  }) => {
    const [state, dispatch] = useReducer(reducer, defaultValue);

    const boundActions: any = {};
    for (const key in actions) {
      boundActions[key] = actions[key](dispatch);
    }

    return (
      <Context.Provider value={{ state, ...boundActions, ...rest }}>
        {children}
      </Context.Provider>
    );
  };

  return { Context, Provider };
};

export default createDataContext;
