import { useTranslator } from '@hooks/useTranslator';

const useViewModel = () => {
  const { t } = useTranslator('common', 'thead');

  return [
    {
      id: 'name',
      name: t('sharepoint.name'),
      sortAble: true
    },
    {
      id: 'modifiedOnUtc',
      name: t('sharepoint.modifiedOnUtc'),
      sortAble: true
    },
    {
      id: 'modifiedBy',
      name: t('sharepoint.modifiedBy'),
      sortAble: true
    },
    {
      id: 'type',
      name: t('sharepoint.type'),
      sortAble: true
    },
    {
      id: 'action',
      name: t('common.action'),
      sortAble: false
    }
  ];
};

export default useViewModel;
