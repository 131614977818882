import { FaLogo } from '@angloeastern/react-library';
import SC from '../BarDisplay/style';

export const ANGLO_EASTERN = 'Anglo-Eastern';

const AEText: React.FC = () => (
  <SC.HeadContainer>
    <SC.IconContainer>
      <FaLogo $size={18} />
    </SC.IconContainer>
    <SC.LabelContainer>{ANGLO_EASTERN}</SC.LabelContainer>
  </SC.HeadContainer>
);

export default AEText;
