import type { Action, State } from './types';
import { Actions } from './types';

const messageBoxReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case Actions.SHOW:
      return {
        ...state,
        title: action.payload.title,
        content: action.payload.content,
        show: true
      };
    case Actions.HIDE:
      return {
        ...state,
        show: false
      };
    default:
      return state;
  }
};

export default messageBoxReducer;
