import AvatarEditor from 'react-avatar-editor';

import { Button, SecondaryButton } from '@angloeastern/react-library';
import SC from './style';
import useViewModel from './useViewModel';

const ImageResizer: React.FC = () => {
  const {
    show,
    scale,
    icon,
    labels,
    editorProps,
    onDecrease,
    onIncrease,
    onChangeScale,
    onCancel,
    onSave
  } = useViewModel();

  return show ? (
    <SC.Container>
      <SC.Inner>
        <SC.Heading>
          {labels.uploadPhoto} <SC.Icon icon={icon} />
        </SC.Heading>
        <SC.Avatar>
          <AvatarEditor {...editorProps} />
        </SC.Avatar>
        <SC.Wrapper>
          <SC.Decrease onClick={onDecrease}>-</SC.Decrease>
          <SC.Range value={scale} onChange={onChangeScale} type="range" />
          <SC.Increase onClick={onIncrease}>+</SC.Increase>
        </SC.Wrapper>
        <SC.ButtonsRow>
          <SecondaryButton onClick={onCancel}>{labels.cancel}</SecondaryButton>
          <Button onClick={onSave}>{labels.save}</Button>
        </SC.ButtonsRow>
      </SC.Inner>
    </SC.Container>
  ) : null;
};

export default ImageResizer;
