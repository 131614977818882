import type { FileTypes } from '@components/index';
import type { SharePointfile } from '@context/Sharepoint/types';
import SC from './style';
import useViewModel from './useViewModel';

const DocIcon: React.FC<{
  data: SharePointfile;
  fileType: FileTypes | string;
  isReady?: boolean;
  hasError?: boolean;
}> = ({ data, fileType, isReady = true, hasError = false }) => {
  const icon = useViewModel(data, fileType);

  return (
    <SC.Container $isReady={isReady} $hasError={hasError} $fileType={fileType}>
      <SC.DocIcon icon={icon} />
    </SC.Container>
  );
};

export default DocIcon;
