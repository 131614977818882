import type { Dispatch } from 'react';

import type { Action, UrlCache } from './types';
import { Actions } from './types';

export const openSlider = (dispatch: Dispatch<Action>) => () => {
  dispatch({ type: Actions.SHOW });
};

export const closeSlider = (dispatch: Dispatch<Action>) => () => {
  dispatch({ type: Actions.HIDE });
};

export const cacheUrls = (dispatch: Dispatch<Action>) => (cache: UrlCache) => {
  dispatch({ type: Actions.CACHE, payload: cache });
};

export const setCurrent = (dispatch: Dispatch<Action>) => (index: number) => {
  dispatch({ type: Actions.SET_CURRENT, payload: index });
};

export const setMaxIndex = (dispatch: Dispatch<Action>) => (index: number) => {
  dispatch({ type: Actions.SET_MAX_INDEX, payload: index });
};
