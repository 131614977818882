import { useEffect } from 'react';

import type { PageID } from '@context/PageID';
import { usePageID } from '@context/PageID';
import usePageAccess from '@hooks/usePageAccess';
import { PageLoader } from '../Loading';
import type { Features } from '../types';

const Page: React.FC<{
  pageID: PageID;
  permit?: Features;
  loader?: any;
  children: any;
}> = ({ pageID, permit, loader, children }) => {
  const { isWaiting, permitted } = usePageAccess(permit);
  const { setPageID } = usePageID();

  const Loader = loader || <PageLoader />;

  useEffect(() => {
    if (!isWaiting && permitted) {
      setPageID(pageID);
    }

    return () => setPageID(null);
  }, [isWaiting, permitted]);

  if (isWaiting) {
    return Loader;
  } else if (permitted) {
    return <>{children}</>;
  }

  return null;
};

export default Page;
