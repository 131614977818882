import styled from 'styled-components';

const Container = styled.div<{ $duration?: number }>`
  ${({ $duration }) =>
    $duration !== undefined &&
    `&.animate__animated {
      --animate-duration: ${$duration}s
    }`}
`;

export default {
  Container
};
