import { PdfProvider } from '@angloeastern/react-library';
import Presentor from './Presentor';
import { Provider } from './context';

const ViewFile: React.FC<{
  topGutter?: number;
  leftGutter?: number;
  checkUrl?: boolean;
  fullscreen?: boolean;
  showFilename?: boolean;
  showCrossmark?: boolean;
  showFullscreenButton?: boolean;
  showDownload?: boolean;
  showIframeToolbar?: boolean;
}> = ({
  topGutter = 0,
  checkUrl = false,
  fullscreen = false,
  leftGutter = 56,
  showDownload = true,
  showCrossmark = true,
  showFilename = true,
  showIframeToolbar = false,
  showFullscreenButton = true
}) => {
  return (
    <PdfProvider>
      <Provider
        {...{
          checkUrl,
          showFilename,
          showCrossmark,
          showDownload,
          showIframeToolbar,
          fullscreen,
          showFullscreenButton
        }}
      >
        <Presentor
          {...{
            topGutter,
            leftGutter
          }}
        />
      </Provider>
    </PdfProvider>
  );
};

export default ViewFile;
