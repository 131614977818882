import { lazy, useEffect } from 'react';

import { useOrganizations } from '@context/Organizations';
import { PageID, usePageID } from '@context/PageID';
import { ANGLO_EASTERN } from './AEText';

const AEText = lazy(() => import('./AEText'));
const OrgDropdown = lazy(() => import('./OrgDropdown'));
const OurExpertsBar = lazy(() => import('./OurExpertsBar'));
const OpexSupportingsBar = lazy(() => import('./OpexSupportingsBar'));
const WhatsNewBar = lazy(() => import('./WhatsNewBar'));
const VesselDropdown = lazy(() => import('./VesselDropdown'));

const useViewModel = () => {
  const {
    state: { organizations },
    fetchData
  } = useOrganizations();
  const { isPage } = usePageID();

  useEffect(() => {
    let active = true;
    active && fetchData();

    return () => {
      active = false;
    };
  }, [fetchData]);

  if (isPage(PageID.DOCUMENTS)) {
    return organizations &&
      organizations.length === 1 &&
      organizations[0].name === ANGLO_EASTERN
      ? AEText
      : OrgDropdown;
  } else if (isPage(PageID.RESOURCES)) {
    return AEText;
  } else if (isPage(PageID.OUREXPERTS)) {
    return OurExpertsBar;
  } else if (isPage(PageID.OPEXSUPPORTING)) {
    return OpexSupportingsBar;
  } else if (isPage(PageID.WHATSNEW)) {
    return WhatsNewBar;
  }
  return VesselDropdown;
};

export default useViewModel;
