import { useSlide } from './context';
import SlideTop from './SlideTop';
import Viewport from './Viewport';

const Presentor: React.FC = () => {
  const { show } = useSlide();

  return show ? (
    <>
      <SlideTop />
      <Viewport />
    </>
  ) : null;
};

export default Presentor;
