import type { Size } from '@components/Kpi/Item/style';
import SC from '../style';
import type { NullableKPI } from '../types';
import useViewModel from './useViewModel';

const FooterExtension: React.FC<{
  size: Size;
  kpi: NullableKPI;
}> = ({ size, kpi }) => {
  const footerExt = useViewModel(kpi);

  if (!footerExt) return null;

  return (
    <SC.Container $size={size}>
      <SC.Label>{footerExt}</SC.Label>
    </SC.Container>
  );
};

export default FooterExtension;
