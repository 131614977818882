import { ViewFile } from '@components/index';
import type { SharePointfiles } from '@context/Sharepoint/types';
import Slider from './Slider';
import Thumbnails from './Thumbnails';
import SC from './style';
import useViewModel from './useViewModel';

export interface Props {
  gallery: SharePointfiles;
  slides?: SharePointfiles | null;
}

const Presentor: React.FC<Props> = ({ gallery, slides }) => {
  const { theSlides, sliderfiles } = useViewModel(slides);

  return (
    !!theSlides && (
      <SC.Container>
        <SC.Thumbnails>
          <Thumbnails gallery={gallery} theSlides={theSlides} />
        </SC.Thumbnails>
        <Slider files={sliderfiles} />
        <ViewFile topGutter={56} />
      </SC.Container>
    )
  );
};

export default Presentor;
