import { faLanguage } from '@fortawesome/pro-light-svg-icons';
import type { MouseEventHandler } from 'react';

import SC from '../style';

const Decorator: React.FC<{
  children: any;
  onClick: MouseEventHandler;
}> = ({ onClick = () => null, children }) => (
  <SC.HeadContainer onClick={onClick}>
    <SC.LangIcon icon={faLanguage} />
    <SC.LabelContainer>{children}</SC.LabelContainer>
  </SC.HeadContainer>
);

export default Decorator;
