import styled from 'styled-components';

const Label = styled.h4`
  margin: 0;
  flex: 1;
  color: ${({ theme }) => theme.colors.darkMidnightBlue};
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  gap: 11px;
  margin: 20px 32px;
  height: 100%;
  background: white;
  color: ${({ theme }) => theme.colors.licorice};
  font-size: 16px;
  font-weight: normal;
`;

const Container = styled.div.attrs({ 'aria-label': 'fav-form' })<{
  $isOpen?: boolean;
}>`
  position: absolute;
  border-radius: 0 0 8px 8px;
  margin: 0;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.07);
  background: white;
  box-sizing: border-box;
  top: 134px;
  left: 0;
  width: 100%;
  overflow-y: hidden;
  max-height: fit-content;
  display: none;

  @media only screen and (min-width: 768px) {
    top: 54px;
    left: 300px;
    width: 360px;
  }
  ${({ theme }) => theme.scrollbar}
  ${({ $isOpen }) =>
    $isOpen &&
    `display: block;
    overflow-y: visible;`}
`;

const Row = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  flex-wrap: nowrap;
  flex-direction: row;
`;

const ButtonsRow = styled(Row)`
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid ${({ theme }) => theme.colors.lightSilver};
`;

const Col = styled.div`
  flex: 0 0;
`;

const RightButton = styled.div`
  margin-left: auto;
`;

const Loaders = styled.div`
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background: ${({ theme }) => theme.colors.quartz};
`;

const SC = {
  Container,
  InnerContainer,
  Label,
  RightButton,
  Row,
  Col,
  Loaders,
  ButtonsRow
};

export default SC;
