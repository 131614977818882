import { useEffect } from 'react';

import { useModal } from '@angloeastern/react-library';
import { useProfile } from '@context/Profile';

const useViewModel = () => {
  const {
    state: { showMenu, showForm },
    openMenu,
    closeMenu
  } = useProfile();
  const { openModal, closeModal } = useModal();

  const handleClick = () => {
    if (showMenu) {
      closeMenu();
    } else {
      openMenu();
    }
  };

  useEffect(() => {
    if (showForm) {
      openModal();
    } else {
      closeModal();
    }
  }, [showForm]);

  return {
    showMenu,
    handleClick
  };
};

export default useViewModel;
