import { useTranslator } from '@hooks/useTranslator';
import SC from './style';

const Header: React.FC = () => {
  const { t } = useTranslator();

  return (
    <SC.Container>
      <SC.Heading>{t('myProfile.myProfile')}</SC.Heading>
    </SC.Container>
  );
};

export default Header;
