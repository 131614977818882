import { memo } from 'react';

import { useSlide } from '../context';
import SC from '../style';

const VideoPlayer: React.FC = memo(() => {
  const { previewUrl } = useSlide();
  return (
    <SC.Video width={'100%'} controls>
      <source src={`${previewUrl}?t=${new Date().getTime()}`} />
    </SC.Video>
  );
});

export default VideoPlayer;
