import SC from '../style';
import useViewModel from './useViewModel';

const Organizations: React.FC = () => {
  const { label, value } = useViewModel();

  return (
    <SC.FormControl>
      <SC.Label>{label}</SC.Label>
      <SC.Input type="text" name="organisation" value={value} disabled />
    </SC.FormControl>
  );
};

export default Organizations;
