export enum Actions {
  FETCH_FAVORITES = 'fetch-favorite',
  CREATE_FAVORITE = 'create-favorite',
  UPDATE_FAVORITE = 'update-favorite',
  REMOVE_FAVORITE = 'remove-favorite',
  CLEAR_FAVORITE = 'clear-favorite',
  SET_FAVORITE = 'set-favorite',
  SET_ACTIVE_ITEM = 'set-active-item',
  CLEAR_ACTIVE_ITEM = 'clear-active-item',
  CLEAR_SELECTION = 'clear-selection',
  SET_FORM_OPEN = 'set-form-open',
  SET_ERROR = 'error',
  SET_DISABLED = 'set-disabled',
  SET_LOADING = 'set-loading',
  SET_DIRTY = 'set-dirty',
  SHOW_TOAST = 'show-toast',
  HIDE_TOAST = 'hide-toast',
  SET_DISABLED_TOOLTIP = 'set-disabled-tooltip'
}

export interface Favorite {
  guid?: string;
  title?: string;
  vesselAeCodes?: string[];
}

export interface ToastData {
  message: string;
  success: boolean;
}

export interface State {
  isOpen?: boolean;
  dirty: boolean;
  toast: string;
  status?: boolean;
  isLoading?: boolean;
  selected?: Favorite;
  activeItem?: Favorite;
  favorites?: Favorite[];
  isDisabled: boolean;
  disabledToolTip: string;
}

export type Action =
  | { type: Actions.FETCH_FAVORITES; payload: Favorite[] }
  | { type: Actions.CREATE_FAVORITE; payload: Favorite[] }
  | { type: Actions.UPDATE_FAVORITE; payload: Favorite[] }
  | { type: Actions.SET_FAVORITE; payload: Favorite }
  | { type: Actions.SET_ACTIVE_ITEM; payload: Favorite }
  | { type: Actions.SHOW_TOAST; payload: ToastData }
  | { type: Actions.HIDE_TOAST }
  | { type: Actions.SET_ERROR; payload: string }
  | { type: Actions.CLEAR_FAVORITE }
  | { type: Actions.CLEAR_ACTIVE_ITEM }
  | { type: Actions.CLEAR_SELECTION }
  | { type: Actions.SET_FORM_OPEN; payload: boolean }
  | { type: Actions.SET_DIRTY; payload: boolean }
  | { type: Actions.SET_DISABLED; payload: boolean }
  | { type: Actions.SET_LOADING; payload: boolean }
  | { type: Actions.SET_DISABLED_TOOLTIP; payload: string }
  | { type: Actions.REMOVE_FAVORITE; payload: Favorite };
