import { useContext, useEffect } from 'react';

import { errorTracker } from '@utils/errorTracker';
import { BECategoryCodes } from '../../utils/BECategoryCodes';
import createDataContext from '../createDataContext';
import * as actions from './actions';
import reducer from './reducer';
import type { KpiPermission, State } from './types';

const initialState: State = {
  permissions: null,
  error: false
};

const VettingPerformance = [
  BECategoryCodes.RIGHTSHIP_SAFETY_SCORE,
  BECategoryCodes.TIME_SINCE_LAST_SIRE,
  BECategoryCodes.TIME_SINCE_LAST_CDI,
  BECategoryCodes.VETTING
];

const CommercialPerformance = [
  BECategoryCodes.SHIP_AVAILABILITY,
  BECategoryCodes.CHARTER_PARTY_COMPLIANCE,
  BECategoryCodes.FUEL_CONSUMPTION
];

const EnvironmentalPerformance = [
  BECategoryCodes.CO2_EMISSIONS,
  BECategoryCodes.EEOI,
  BECategoryCodes.CII_RATING,
  BECategoryCodes.GHG_Rating
];

const ShipManagementPerformance = [
  BECategoryCodes.OFFICER_RETENTION,
  BECategoryCodes.COC_OPEN_FINDINGS,
  BECategoryCodes.CREW_ON_TIME_RELIEF
];

const QualityAndSafety = [
  BECategoryCodes.INJURY_FREE,
  BECategoryCodes.ISM_AUDIT,
  BECategoryCodes.VESSEL_DETENTION,
  BECategoryCodes.PSC
];

export const { Context, Provider } = createDataContext(
  reducer,
  { ...actions },
  initialState
);

export const useKpiPermissions = () => {
  const ctx = useContext<any>(Context);
  const {
    state: { permissions },
    fetchData
  } = ctx;
  let isFetching = false;

  useEffect(() => {
    async function fetchKpiData() {
      // avoids multiple calls
      isFetching = true;
      try {
        await fetchData();
      } catch (e: any) {
        errorTracker(e);
      }
      isFetching = false;
    }

    if (!permissions && !isFetching) {
      fetchKpiData();
    }
  }, [permissions]);

  const hasPermission = (
    categoryCode: BECategoryCodes | BECategoryCodes[]
  ): boolean => {
    if (Array.isArray(categoryCode)) {
      return !categoryCode.every(
        (code: BECategoryCodes) => !hasPermission(code)
      );
    } else {
      return (
        (Array.isArray(permissions) &&
          permissions.some(
            (p: KpiPermission) =>
              p.beCode === categoryCode && p.accessType === true
          )) ||
        false
      );
    }
  };

  const isVisible_VettingPerformance = hasPermission(VettingPerformance);
  const isVisible_CommercialPerformance = hasPermission(CommercialPerformance);
  const isVisible_EnvironmentalPerformance = hasPermission(
    EnvironmentalPerformance
  );
  const isVisible_ShipManagementPerformance = hasPermission(
    ShipManagementPerformance
  );
  const isVisible_QualityAndSafety = hasPermission(QualityAndSafety);

  const isVisible_Any =
    isVisible_CommercialPerformance ||
    isVisible_EnvironmentalPerformance ||
    isVisible_QualityAndSafety ||
    isVisible_ShipManagementPerformance ||
    isVisible_VettingPerformance;

  return {
    ...ctx,
    hasPermission,
    isVisible_Any,
    isVisible_CommercialPerformance,
    isVisible_EnvironmentalPerformance,
    isVisible_QualityAndSafety,
    isVisible_ShipManagementPerformance,
    isVisible_VettingPerformance
  };
};
