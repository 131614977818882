import { createContext, useContext, useEffect, useState } from 'react';

import { useError } from '@hooks/useError';
import { errorTracker } from '@utils/errorTracker';
import { fetchFeatures } from './api';

export const Context = createContext({});

export enum Feature {
  ANIMATE_VESSEL = 'ANIMATE_VESSEL',
  CREW_TIMELINE_VIEW = 'CREW_TIMELINE_VIEW',
  NOTIFICATIONS = 'NOTIFICATIONS'
}

export const Provider: React.FC<{ children: any }> = ({ children }) => {
  const [features, setFeatures] = useState({});
  const [error, setError] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    async function fetchData() {
      try {
        const data = await fetchFeatures(signal);
        setFeatures(data);
      } catch (e: any) {
        errorTracker(e);
        setError(true);
      }
    }

    fetchData();

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <Context.Provider value={{ ...features, error }}>
      {children}
    </Context.Provider>
  );
};

export const useFeatureToggles = () => {
  const { error, ...features } = useContext<any>(Context);
  const { setError } = useError();

  useEffect(() => {
    if (error) {
      setError(true);
    }
  }, [error]);

  const isEnabled = (varName: string) => {
    return features[varName] || false;
  };

  return {
    features,
    error,
    isEnabled
  };
};
