import { Modal } from '@angloeastern/react-library';
import { useProfile } from '@context/Profile';
import ImageResizer from './ImageResizer';
import { useImageResizer } from './ImageResizer/context';
import ProfileForm from './ProfileForm';

const Presentor: React.FC = () => {
  const {
    state: { user }
  } = useProfile();
  const { show } = useImageResizer();

  if (!user) return null;

  return <Modal>{show ? <ImageResizer /> : <ProfileForm />}</Modal>;
};

export default Presentor;
