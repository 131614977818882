import type { VesselMarker } from '../VesselMarkers/types';

interface Cargo {
  title: string | null;
  description: string | null;
}

interface MapCoords {
  latitude: number;
  longitude: number;
}

export interface Voyage extends MapCoords {
  startDateTime: string;
}

interface Port extends MapCoords {
  port: string;
  country: string;
  timestamp: string;
}

export enum PositionStatus {
  AT_SEA = 'At Sea',
  IN_PORT = 'In Port'
}

export interface VesselVoyages {
  vesselAeCode?: string;
  vesselName?: string;
  vesselPositionStatus?: PositionStatus;
  isVis?: boolean;
  cargo?: Cargo;
  speedOverGround?: number | 'NULL';
  fuelConsumption?: number;
  engineSpeed?: number | 'NULL';
  departurePort?: Port;
  arrivalPort?: Port;
  nextPortAgent?: string;
  voyages?: Array<Voyage>;
  active?: boolean;
}

export enum Actions {
  SET_ACTIVE_VOYAGE = 'set-active-voyage',
  UNSET_VOYAGE = 'unset-voyage',
  SET_ERROR = 'set-error',
  SET_NO_DATA = 'set-no-data',
  SET_CENTERED = 'set-centered',
  SET_CONTROLLER = 'set-controller',
  APPEND_VOYAGE = 'append-voyage'
}

export interface State {
  activeVoyage: VesselVoyages | null;
  error: boolean;
  noData: boolean;
  centered: boolean;
  abortController: AbortController | null;
}

export type Action =
  | { type: Actions.SET_ACTIVE_VOYAGE; payload: VesselVoyages }
  | { type: Actions.SET_CONTROLLER; payload: AbortController }
  | { type: Actions.UNSET_VOYAGE }
  | { type: Actions.SET_ERROR }
  | { type: Actions.SET_NO_DATA }
  | { type: Actions.SET_CENTERED; payload: boolean }
  | { type: Actions.APPEND_VOYAGE; payload: VesselMarker };
