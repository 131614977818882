import { PageID, usePageID } from '@context/PageID';
import { useTranslator } from '@hooks/useTranslator';
import { useTheme } from 'styled-components';

const useViewModel = () => {
  const { t } = useTranslator();
  const {
    colors: { tiffanyBlue }
  } = useTheme();
  const { pageID } = usePageID();

  return {
    hide: pageID === PageID.WHATSNEW,
    color: tiffanyBlue,
    label: t('module.whatsnew')
  };
};

export default useViewModel;
