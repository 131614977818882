import { CenterMessage } from '@angloeastern/react-library';
import { BounceIn } from '@components/Animations/Div';
import { useTranslator } from '@hooks/useTranslator';
import SC from '../style';

const EmptyContent: React.FC<{ gutterSize?: number }> = ({
  gutterSize = 227
}) => {
  const { t } = useTranslator();

  return (
    <SC.MessageBox $gutterSize={gutterSize}>
      <CenterMessage>
        <BounceIn>{t('text.emptyContent')}</BounceIn>
      </CenterMessage>
    </SC.MessageBox>
  );
};

export default EmptyContent;
