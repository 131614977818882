import * as actions from './actions';
import type { State } from './types';

const initialState: State = {
  driveId: undefined,
  rootName: undefined,
  rootCode: '',
  rootFolder: undefined,
  categoryId: undefined,
  data: null,
  currentFolder: undefined,
  folderStack: [],
  searchToken: undefined,
  loadMore: false,
  isReady: false,
  page: 1,
  pageStack: [1],
  canBulkDownload: false,
  error: false
};

export { actions, initialState };
