import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 27px;
`;

const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 0 16px;
  gap: 30px;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
    padding: 0 38px;
    width: 654px;
  }
`;

const ButtonsRow = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  flex-direction: column-reverse;
  padding: 30px 16px;
  border-top: 0.5px dotted ${({ theme }) => theme.colors.philippineGray};

  @media only screen and (min-width: 768px) {
    padding: 3px 38px 42px;
    flex-direction: row;
    gap: 46px;
    border: none;
  }
`;
interface Props {
  width?: string;
}

const FormControl = styled.div<Props>`
  display: flex;
  flex-direction: column;
  gap: 9px;
  flex: 1 0;
  width: ${(props) => props.width || ''};
`;

const LangControl = styled(FormControl)`
  flex: 0 0 48%;
`;

const Label = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.formLabel};
`;

const Input = styled.input`
  border: 0.5px solid ${({ theme }) => theme.colors.gray400};
  background: ${({ theme }) => theme.colors.alabasterGray};
  color: ${({ theme }) => theme.colors.formValue};
  border-radius: 8px;
  line-height: 20px;
  padding: 8px 14px;
  text-overflow: ellipsis;
`;

const ThumbFlex = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 16px;
`;

const ThumbIcon = styled(FontAwesomeIcon)`
  font-size: 72px;
  align-self: center;
`;

const ThumbsUp = styled(ThumbIcon)`
  color: ${({ theme }) => theme.colors.mantis};
`;

const ThumbsDown = styled(ThumbIcon)`
  color: ${({ theme }) => theme.colors.flamingo};
`;

const ToastMsg = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  max-width: 275px;
  text-align: center;
`;

const SC = {
  ButtonsRow,
  FormContainer,
  FormControl,
  FormRow,
  Input,
  Label,
  LangControl,
  ThumbsDown,
  ThumbFlex,
  ThumbsUp,
  ToastMsg
};

export default SC;
