import SC from './style';
import useViewModel from './useViewModel';

const ProfileMenu: React.FC = () => {
  const { labels, handleClick, handleClickLogout } = useViewModel();

  return (
    <SC.Container>
      <SC.Option onClick={handleClick}>{labels.myProfile}</SC.Option>
      <SC.Option onClick={handleClickLogout}>{labels.logout}</SC.Option>
    </SC.Container>
  );
};

export default ProfileMenu;
