import { faEllipsisVertical } from '@fortawesome/pro-solid-svg-icons';

import { useListCardMenu } from '@components/ListCardMenu/context';
import SC from './style';

const Presentor: React.FC<{ children: any }> = ({ children }) => {
  const { open, setOpen } = useListCardMenu();

  const handleClick = () => setOpen(!open);

  return (
    <SC.Container>
      <SC.Trigger icon={faEllipsisVertical} onClick={handleClick} />
      {open && <SC.Menu>{children}</SC.Menu>}
    </SC.Container>
  );
};

export default Presentor;
