import styled from 'styled-components';

const Container = styled.div`
  background: white;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const SC = {
  Container
};

export default SC;
