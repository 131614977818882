import { useContext } from 'react';

import createDataContext from '@context/createDataContext';
import * as actions from './actions';
import sliderReducer from './reducer';
import type { State } from './types';

const initialState: State = {
  show: false,
  current: 0,
  cache: undefined,
  maxIndex: 0
};

export const { Context, Provider } = createDataContext(
  sliderReducer,
  { ...actions },
  initialState
);

export const useSlider = () => {
  const ctx = useContext<any>(Context);
  const {
    state: { current, maxIndex },
    setCurrent
  } = ctx;

  const onPrevious = () => {
    setCurrent(current === 0 ? maxIndex : current - 1);
  };

  const onNext = () => {
    const next = current === maxIndex ? 0 : current + 1;
    setCurrent(next);
  };

  return {
    ...ctx,
    onPrevious,
    onNext
  };
};
