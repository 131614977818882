import {
  faFile,
  faFileAudio,
  faFileBinary,
  faFileCode,
  faFileCsv,
  faFileExcel,
  faFileImage,
  faFilePdf,
  faFilePowerpoint,
  faFileSpreadsheet,
  faFileVideo,
  faFileWord,
  faFileZipper
} from '@fortawesome/pro-duotone-svg-icons';

import { FileTypes } from '@components/index';
import useFileTypes from '@hooks/useFileTypes';
import { useMemo } from 'react';

const useViewModel = (filetype: FileTypes | string, filename: string) => {
  const { getExtension } = useFileTypes();

  const theIcon = useMemo(() => {
    const ext = getExtension(filename);
    switch (filetype) {
      case FileTypes.PDF:
        return faFilePdf;
      case FileTypes.WORD:
        return faFileWord;
      case FileTypes.EXCEL:
        return ext === 'csv' ? faFileCsv : faFileExcel;
      case FileTypes.POWERPOINT:
        return faFilePowerpoint;
      case FileTypes.VIDEO:
        return faFileVideo;
      case FileTypes.AUDIO:
        return faFileAudio;
      case FileTypes.IMAGE:
        return faFileImage;
      case FileTypes.SPREADSHEET:
        return faFileSpreadsheet;
      case FileTypes.ARCHIVE:
        return faFileZipper;
      case FileTypes.CODE:
        return faFileCode;
      default:
        switch (ext) {
          case 'csv':
            return faFileCsv;
          case 'exe':
            return faFileBinary;
          default:
            return faFile;
        }
    }
  }, [filetype, filename]);

  return theIcon;
};

export default useViewModel;
