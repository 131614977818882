import { Provider } from './ImageResizer/context';
import Presentor from './Presentor';

const MyProfile: React.FC = () => {
  return (
    <Provider>
      <Presentor />
    </Provider>
  );
};

export default MyProfile;
