import { faThumbsDown, faThumbsUp } from '@fortawesome/pro-solid-svg-icons';

import { useProfile } from '@context/Profile';
import { useTranslator } from '@hooks/useTranslator';
import { useProfileTab } from '../context';

const initToastState = {
  show: false,
  message: '',
  success: undefined
};

const useViewModel = () => {
  const { setDirty, toastState, setToastState } = useProfileTab();
  const { t } = useTranslator();
  const { closeForm } = useProfile();

  const handleToastClose = () => {
    setToastState(initToastState);
    setDirty(false);
    closeForm();
  };

  return {
    label: t('action.ok'),
    toastState,
    icons: {
      success: faThumbsUp,
      failure: faThumbsDown
    },
    handleToastClose
  };
};

export default useViewModel;
