import { TabContent, Tabs } from '@angloeastern/react-library';
import { useTranslator } from '@hooks/useTranslator';
import CustomTab from './CustomTab';
import Header from './Header';
import ProfileTab from './ProfileTab';
import SecurityTab from './SecurityTab';
import SC from './style';

const ProfileForm: React.FC = () => {
  const { t } = useTranslator();

  return (
    <>
      <Header />
      <SC.TabLine />
      <Tabs renderer={CustomTab} name="myprofile">
        <TabContent id="profile" label={t('myProfile.profile')}>
          <ProfileTab />
        </TabContent>
        <TabContent id="security" label={t('myProfile.security')}>
          <SecurityTab />
        </TabContent>
      </Tabs>
    </>
  );
};

export default ProfileForm;
