import SC from './style';
import useViewModel from './useViewModel';

const View: React.FC<{
  widget?: any;
  clearCrumbs?: boolean;
  children: any;
}> = ({ widget, clearCrumbs = true, children }) => {
  const {
    theCrumbs,
    theTiltle,
    theWidget: Widget
  } = useViewModel(widget, clearCrumbs);

  return (
    <>
      <SC.TopArea>
        <SC.TitleArea>
          <SC.BreadCrumbs>{theCrumbs}</SC.BreadCrumbs>
          <SC.Title>{theTiltle}</SC.Title>
        </SC.TitleArea>
        <SC.Widgets>{Widget}</SC.Widgets>
      </SC.TopArea>
      <SC.Body>{children}</SC.Body>
    </>
  );
};

export default View;
