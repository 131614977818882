import type { Action, SearchResult, State } from './types';
import { Actions } from './types';

const reducer = (state: State, action: Action): State => {
  const folderStack = [...state.folderStack];
  const pageStack = [...state.pageStack];
  let currentPage = pageStack[pageStack.length - 1];

  const search = (payload: SearchResult) => {
    const { nextLink, results } = payload;
    return {
      ...state,
      data: state.data ? [...state.data, ...results] : results,
      searchToken: nextLink,
      isReady: true,
      error: false,
      loadMore: false
    };
  };

  switch (action.type) {
    case Actions.FETCH_DATA:
      return {
        ...state,
        data: action.payload,
        isReady: true,
        error: false
      };
    case Actions.SEARCH:
      return search(action.payload);
    case Actions.SEARCH_MORE:
      return {
        ...state,
        loadMore: action.payload
      };
    case Actions.RESET_DATA:
      return {
        ...state,
        data: null,
        searchToken: undefined,
        isReady: false,
        error: false
      };
    case Actions.SET_DRIVE_ID:
      return {
        ...state,
        driveId: action.payload
      };
    case Actions.SET_CURRENT_FOLDER:
      return {
        ...state,
        currentFolder: action.payload
      };
    case Actions.SET_ROOT_FOLDER:
      if (action.payload) {
        return {
          ...state,
          rootFolder: action.payload,
          categoryId: action.payload.id
        };
      } else {
        return {
          ...state,
          isReady: true
        };
      }
    case Actions.SET_ROOT_NAME:
      return {
        ...state,
        rootName: action.payload,
        folderStack: []
      };
    case Actions.SET_ROOT_CODE:
      return {
        ...state,
        categoryId: undefined,
        data: null,
        isReady: false,
        searchToken: undefined,
        rootCode: action.payload,
        folderStack: []
      };
    case Actions.FOLDER_STACK_POP:
      folderStack.pop();
      pageStack.pop();
      currentPage = pageStack[pageStack.length - 1];

      return {
        ...state,
        folderStack,
        pageStack,
        page: currentPage,
        currentFolder: folderStack[folderStack.length - 1],
        categoryId: folderStack.length
          ? folderStack[folderStack.length - 1].id
          : state.rootFolder?.id
      };
    case Actions.FOLDER_STACK_PUSH:
      folderStack.push(action.payload);
      pageStack.push(1);

      return {
        ...state,
        folderStack,
        pageStack,
        currentFolder: action.payload,
        categoryId: action.payload.id,
        page: 1
      };
    case Actions.SET_PAGE:
      pageStack[pageStack.length - 1] = currentPage = action.payload;

      return {
        ...state,
        page: currentPage,
        pageStack
      };
    case Actions.SET_ERROR:
      return {
        ...state,
        error: true
      };
    case Actions.SET_BULK_DOWNLOAD:
      return {
        ...state,
        canBulkDownload: action.payload
      };

    default:
      return state;
  }
};

export default reducer;
