import { Buffer } from 'buffer';

const encode = (str: string) => {
  return Buffer.from(str).toString('base64');
};

const decode = (str: string) => {
  return Buffer.from(str, 'base64').toString();
};

const Base64 = {
  encode,
  decode
};

export default Base64;
