import { useContext, useEffect } from 'react';

import { useVessels } from '@context/Vessels';
import createDataContext from '@context/createDataContext';
import {
  clearActiveItem,
  clearSelection,
  createFavorite,
  fetchFavorites,
  hideToast,
  removeFavorite,
  select,
  setActiveItem,
  setDirty,
  setDisabled,
  setIsFormOpen,
  setLoading,
  showToast,
  unselect,
  updateFavorite
} from './actions';
import reducer from './reducer';
import type { Favorite, State } from './types';

export const initActiveItem: Favorite = {
  guid: undefined,
  title: undefined,
  vesselAeCodes: []
};

const initialState: State = {
  isOpen: false,
  dirty: false,
  toast: '',
  status: undefined,
  isDisabled: false,
  isLoading: false,
  disabledToolTip: '',
  selected: undefined,
  activeItem: initActiveItem,
  favorites: undefined
};

export const { Context, Provider } = createDataContext(
  reducer,
  {
    unselect,
    fetchFavorites,
    removeFavorite,
    updateFavorite,
    createFavorite,
    select,
    setDirty,
    setLoading,
    showToast,
    hideToast,
    setIsFormOpen,
    setDisabled,
    setActiveItem,
    clearActiveItem,
    clearSelection
  },
  initialState
);

export const useFavorite = () => {
  const {
    state: { favorite },
    setFavorite
  } = useVessels();

  const ctx = useContext<any>(Context);
  const {
    state: { activeItem, selected, favorites },
    setDirty,
    setActiveItem,
    select
  } = ctx;

  const exists = (title: string) =>
    favorites.some((fav: Favorite) => fav.title === title);

  useEffect(() => {
    // create
    if (
      activeItem &&
      !selected &&
      !activeItem.guid &&
      activeItem.vesselAeCodes?.length
    ) {
      setDirty(true);
    } else if (
      // update
      activeItem &&
      selected &&
      activeItem.guid &&
      activeItem.vesselAeCodes?.length &&
      ((activeItem.title &&
        activeItem.title?.toUpperCase() !== selected.title?.toUpperCase()) ||
        activeItem.vesselAeCodes?.length !== selected.vesselAeCodes?.length ||
        !activeItem.vesselAeCodes?.every((x: any) =>
          selected?.vesselAeCodes?.some((y: any) => x === y)
        ))
    ) {
      setDirty(true);
    } else {
      setDirty(false);
    }
  }, [activeItem, selected]);

  // on update of favorites list or favorite
  // selects the current favorite
  useEffect(() => {
    let item = null;
    if (favorites && !favorite && activeItem) {
      item = favorites.find((x: Favorite) => x.title === activeItem.title);
      if (item) {
        setFavorite(item);
        setActiveItem(item);
        select(item);
      }
    }
  }, [favorites, favorite, activeItem]);

  return { ...ctx, exists };
};
