import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export enum TranslationNameSpaces {
  CERTIFICATES = 'certificates',
  COMMON = 'common',
  CREW = 'crew',
  DRAWINGS = 'drawings',
  ERRORPAGE = 'errorpage',
  FINANCIAL = 'financial',
  KPIS = 'kpis',
  LOGIN = 'login',
  NAVIGATION = 'navigation',
  REPORTS = 'reports',
  RESOURCES = 'resources'
}

export const useTranslator = (
  ns:
    | string
    | string[]
    | TranslationNameSpaces
    | TranslationNameSpaces[] = 'common',
  baseKey?: string
) => {
  const i18nData = useTranslation(ns);
  const { t, ready, i18n } = i18nData;
  const { language } = i18n;

  const defaultLanguage = useMemo(() => {
    const { REACT_APP_DEFAULT_LANGUAGE } = process.env;
    return REACT_APP_DEFAULT_LANGUAGE || 'en';
  }, [process.env]);

  const supportedLanguages = useMemo(() => {
    const { REACT_APP_SUPPORTED_LANGUAGES } = process.env;
    return REACT_APP_SUPPORTED_LANGUAGES
      ? REACT_APP_SUPPORTED_LANGUAGES.split('|')
      : [defaultLanguage];
  }, [process.env]);

  const currentLanguage = useMemo(() => {
    const loweredLanguage = language.toLowerCase();
    return supportedLanguages.indexOf(loweredLanguage) >= 0
      ? loweredLanguage
      : defaultLanguage;
  }, [language]);

  const translate = useCallback(
    (key: string, params?: any, altTxt = '') => {
      const tKey = baseKey ? `${baseKey}.${key}` : key;
      return ready
        ? (t(tKey, {
            interpolation: { escapeValue: false },
            defaultValue:
              altTxt !== ''
                ? altTxt
                : params && params['defaultValue']
                  ? params['defaultValue']
                  : tKey,
            ...params
          }) as string)
        : altTxt;
    },
    [ready, baseKey, language]
  );

  return {
    ...i18nData,
    ...i18n,
    language,
    currentLanguage,
    supportedLanguages,
    defaultLanguage,
    t: translate
  };
};
