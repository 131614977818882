import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { getCurrentLanguage, getDefaultLanguage } from '@utils/index';
import { useTranslator } from './useTranslator';

export const useError = () => {
  const { language } = useTranslator();
  const navigate = useNavigate();
  const [error, setError] = useState(false);

  const goToError = () => {
    const defaultLang = getDefaultLanguage();
    const currentLang = getCurrentLanguage(language);
    const langPath = currentLang === defaultLang ? '' : `${currentLang}/`;
    navigate(`/${langPath}error`);
  };

  useEffect(() => {
    if (error) {
      goToError();
    }
  }, [error]);

  return {
    error,
    setError
  };
};
