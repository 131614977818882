import styled from 'styled-components';

const Button = styled.a.attrs({ 'aria-label': 'jibe-button' })`
  display: flex;
  padding: 18px 0;
  -webkit-text-decoration: none;
  text-decoration: none;
  outline: none;
  background: ${({ theme }) => theme.colors.jibeBlue};
  color: ${({ theme }) => theme.colors.alabaster};
  font-weight: bold;
  font-size: 16px;
  -ms-flex-item-align: center;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  gap: 8px;

  > svg {
    width: 50px;
    height: 19px;
  }

  &:hover {
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.5));
  }
`;

const SC = {
  Button
};

export default SC;
