export enum Actions {
  SHOW = 'show',
  HIDE = 'hide',
  SET_CURRENT = 'set-current',
  CACHE = 'cache',
  SET_MAX_INDEX = 'set-max-index'
}

export interface Urls {
  name: string;
  slide: string;
  thumbnail: string;
}

export interface UrlCache {
  id?: string;
  previewUrl: string;
  downloadUrl: string;
}

export interface UrlMap {
  [x: string]: UrlCache;
}

export interface State {
  show: boolean;
  current: number;
  cache: UrlMap | undefined;
  maxIndex: number;
}

export type Action =
  | { type: Actions.SHOW }
  | { type: Actions.HIDE }
  | { type: Actions.CACHE; payload: UrlCache }
  | { type: Actions.SET_CURRENT; payload: number }
  | { type: Actions.SET_MAX_INDEX; payload: number };
