import ConfirmRemove from './ConfirmRemove';
import Controls from './Controls';
import Dropdown from './Dropdown';
import SC from './style';
import useViewModel from './useViewModel';

const LoadFavorite: React.FC = () => {
  const {
    ready,
    label,
    selected,
    favorites,
    showControls,
    showConfirmRemove,
    onSelect,
    onRemove,
    onCancel,
    onContinue
  } = useViewModel();

  return (
    ready && (
      <>
        <SC.Label>{label}</SC.Label>
        <Dropdown
          key={selected}
          selected={selected}
          onClick={onSelect}
          options={favorites}
        />
        <Controls show={showControls} onRemove={onRemove} />
        <ConfirmRemove
          show={showConfirmRemove}
          onCancel={onCancel}
          onContinue={onContinue}
        />
      </>
    )
  );
};

export default LoadFavorite;
