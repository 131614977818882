import { Dialog } from '@angloeastern/react-library';
import SC from './style';
import useViewModel from './useViewModel';

const ConfirmRemove: React.FC<{
  show: boolean;
  onCancel(): void;
  onContinue(): void;
}> = ({ show, onContinue, onCancel }) => {
  const { label, dialogProps } = useViewModel();

  return (
    show && (
      <Dialog {...dialogProps} onYes={onContinue} onNo={onCancel}>
        <SC.AlertText>{label}</SC.AlertText>
      </Dialog>
    )
  );
};

export default ConfirmRemove;
