import { useFileViewer } from '@angloeastern/react-library';
import { useMemo } from 'react';
import { useViewFile } from '../../context';

const useViewModel = () => {
  const { valid, canPreview, handleLoad, showIframeToolbar } = useViewFile();
  const {
    state: { selected }
  } = useFileViewer();

  const thePreviewUrl = useMemo(() => {
    if (valid && selected && canPreview) {
      let previewUrl = `${selected.url}?env=Embedded`;
      if (showIframeToolbar) {
        previewUrl = `${previewUrl}&zoom=100&navpanes=1&toolbar=1`;
      } else {
        previewUrl = `${selected.url}#toolbar=0&view=FitH`;
      }

      return previewUrl;
    }

    return null;
  }, [valid, selected, canPreview, showIframeToolbar]);

  return {
    previewName: selected?.name,
    thePreviewUrl,
    iFrameStyle: { border: 'none' },
    handleLoad
  };
};

export default useViewModel;
