import { getDefaultLanguage } from '@utils/index';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['cookie', 'path', 'navigator'],
      caches: ['cookie']
    },
    fallbackLng: getDefaultLanguage(),
    react: {
      useSuspense: true
    },
    debug: false,
    ns: [
      'common',
      'certificates',
      'crew',
      'errorpage',
      'financial',
      'kpis',
      'login',
      'navigation',
      'reports'
    ],
    defaultNS: 'common',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
