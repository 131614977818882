import type { Dispatch } from 'react';

import type {
  SharePointfile,
  SharePointfiles
} from '@context/Sharepoint/types';
import { errorTracker } from '@utils/errorTracker';
import * as Api from './api';
import type { Action, RootFolder, SearchResult } from './types';
import { Actions } from './types';

const fetchData =
  (dispatch: Dispatch<Action>) =>
  async (driveId: string, categoryId: string, signal?: AbortSignal) => {
    try {
      const data: SharePointfiles = await Api.fetchItems(
        driveId,
        categoryId,
        signal
      );
      dispatch({ type: Actions.FETCH_DATA, payload: data });
    } catch (e: any) {
      if (e.name !== 'AbortError' && e.message !== 'canceled') {
        errorTracker(e);
        dispatch({ type: Actions.SET_ERROR });
      }
    }
  };

const setDriveId = (dispatch: Dispatch<Action>) => (driveId: string) => {
  dispatch({ type: Actions.SET_DRIVE_ID, payload: driveId });
};

const setRootName = (dispatch: Dispatch<Action>) => (folder: string) => {
  dispatch({ type: Actions.SET_ROOT_NAME, payload: folder });
};

const setRootCode = (dispatch: Dispatch<Action>) => (code: string) => {
  dispatch({ type: Actions.SET_ROOT_CODE, payload: code });
};

const setRootFolder =
  (dispatch: Dispatch<Action>) => (folder: RootFolder | null) => {
    dispatch({ type: Actions.SET_ROOT_FOLDER, payload: folder });
  };

const setCurrentFolder =
  (dispatch: Dispatch<Action>) => (folder: SharePointfile) => {
    dispatch({ type: Actions.SET_CURRENT_FOLDER, payload: folder });
  };

const folderStackPush =
  (dispatch: Dispatch<Action>) => (folder: SharePointfile) => {
    dispatch({ type: Actions.FOLDER_STACK_PUSH, payload: folder });
  };

const folderStackPop = (dispatch: Dispatch<Action>) => () => {
  dispatch({ type: Actions.FOLDER_STACK_POP });
};

const resetData = (dispatch: Dispatch<Action>) => () => {
  dispatch({ type: Actions.RESET_DATA });
};

const seeMore = (dispatch: Dispatch<Action>) => (more: boolean) => {
  dispatch({ type: Actions.SEARCH_MORE, payload: more });
};

const search =
  (dispatch: Dispatch<Action>) =>
  async (
    driveId: string,
    categoryId: string,
    term: string,
    nextLink: string | null,
    signal?: AbortSignal
  ) => {
    try {
      const data: SearchResult = await Api.search(
        driveId,
        categoryId,
        term,
        nextLink,
        signal
      );
      dispatch({ type: Actions.SEARCH, payload: data });
    } catch (e: any) {
      if (e.name !== 'AbortError' && e.message !== 'canceled') {
        errorTracker(e);
        dispatch({ type: Actions.SET_ERROR });
      }
    }
  };

const setPage =
  (dispatch: Dispatch<Action>) =>
  (page: number): void => {
    dispatch({ type: Actions.SET_PAGE, payload: page });
  };

const setBulkDownload = (dispatch: Dispatch<Action>) => (can: boolean) => {
  dispatch({ type: Actions.SET_BULK_DOWNLOAD, payload: can });
};

export {
  fetchData,
  folderStackPop,
  folderStackPush,
  resetData,
  search,
  seeMore,
  setBulkDownload,
  setCurrentFolder,
  setDriveId,
  setPage,
  setRootCode,
  setRootFolder,
  setRootName
};
