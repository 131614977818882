import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import type { SharePointfiles } from '@context/Sharepoint/types';
import Slide from './Slide';
import SC from './style';
import useViewModel from './useViewModel';

const Slider: React.FC<{
  files: SharePointfiles;
}> = ({ files }) => {
  const { show, icons, slide, onPrevious, onNext } = useViewModel(files);

  return (
    <SC.Background $show={show}>
      <SC.Container>
        <SC.Spacer />
        <SC.Chevron>
          <FontAwesomeIcon
            icon={icons.previous}
            onClick={onPrevious}
            aria-label="previous-slide"
          />
        </SC.Chevron>
        <SC.Center>{slide && <Slide file={slide} />}</SC.Center>
        <SC.Chevron>
          <FontAwesomeIcon
            icon={icons.next}
            onClick={onNext}
            aria-label="next-slide"
          />
        </SC.Chevron>
        <SC.Spacer />
      </SC.Container>
    </SC.Background>
  );
};

export default Slider;
