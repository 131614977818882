import { Button } from '@angloeastern/react-library';
import { useMessageBox } from '@context/MessageBox';
import { useTranslator } from '@hooks/useTranslator';
import SC from './style';

const MessageBox: React.FC = () => {
  const {
    state: { content, title, show },
    hideMessageBox
  } = useMessageBox();
  const { t } = useTranslator();

  return (
    <SC.Background $show={show}>
      <SC.Container>
        {title && <SC.Title>{title}</SC.Title>}
        <SC.Body>{content}</SC.Body>
        <SC.Footer>
          <Button onClick={hideMessageBox}>{t('action.ok')}</Button>
        </SC.Footer>
      </SC.Container>
    </SC.Background>
  );
};

export default MessageBox;
