import type { Favorite } from '@context/Favorite/types';
import getCurrencySymbol from '@utils/currency';
import type { Action, State, Vessel } from './types';
import { Actions, currencySymbols } from './types';

const vesselReducer = (state: State, action: Action): State => {
  const setVessel = (payload: Vessel | null) => {
    const currency = state.vesselCurrencies?.find(
      (x) => x.vesselAeCode === payload?.aeCode
    );
    if (currency && payload) {
      payload.currencySymbol =
        currency?.currencySymbol || getCurrencySymbol(currency?.currency); //currencySymbols.DEFAULT;
      payload.currency = currency?.currency || currencySymbols.DEFAULT;
      payload.locale = currency?.currency === 'INR' ? 'en-IN' : 'en-US';
    }
    return {
      ...state,
      vessel: payload ? { ...payload } : null
    };
  };

  const setFavorite = (payload: Favorite) => {
    const vesselAeCodes =
      payload && payload.vesselAeCodes ? payload.vesselAeCodes : [];
    const vessels = state.allVessels
      ? state.allVessels.filter(
          (v: Vessel) => vesselAeCodes.indexOf(v.aeCode) >= 0
        )
      : [];

    const selectedVesselInfilter = state.vessel
      ? vessels.some((v: Vessel) => v.id === state.vessel?.id)
      : false;

    return {
      ...state,
      favorite: payload,
      vessels,
      vessel: state.vessel && selectedVesselInfilter ? state.vessel : null
    };
  };

  switch (action.type) {
    case Actions.SET_FAVORITE_FILTER:
      return setFavorite(action.payload);
    case Actions.REMOVE_FAVORITE_FILTER:
      return {
        ...state,
        favorite: null,
        vessels: state.allVessels
      };
    case Actions.FETCH_VESSELS:
      return {
        ...state,
        allVessels: [...action.payload],
        error: false
      };
    case Actions.FETCH_CURRENCIES:
      return {
        ...state,
        vesselCurrencies: [...action.payload],
        error: false
      };
    case Actions.SET_VESSELS:
      return {
        ...state,
        vessels: action.payload,
        error: false
      };
    case Actions.SET_VESSEL:
      return setVessel(action.payload);
    case Actions.RESET_DATA:
      return {
        ...state,
        allVessels: null,
        vessels: null,
        error: false
      };
    case Actions.SET_ERROR:
      return {
        ...state,
        error: true
      };
    default:
      return state;
  }
};

export default vesselReducer;
