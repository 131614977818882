import styled from 'styled-components';

const LangControl = styled.div`
  flex: 1 0;
  display: flex;
  flex-direction: column;
  gap: 9px;

  @media only screen and (min-width: 768px) {
    flex: 0 0 48%;
  }
`;

const Label = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.formLabel};
`;

const SC = {
  Label,
  LangControl
};

export default SC;
