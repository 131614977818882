import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const FilterBox = styled.div<{
  $isDisabled?: boolean;
  $isOpen: boolean;
}>`
  padding: 0 24px;
  align-self: center;
  flex: 0 0;
  margin-left: 24px;
  cursor: pointer;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.6s;
  -moz-transition: all 0.6s;
  -o-transition: all 0.6s;
  transition: all 0.6s;
  --icon-color: ${({ $isOpen, theme }) =>
    $isOpen ? theme.colors.white : theme.colors.black};

  @media only screen and (min-width: 768px) {
    padding: 0 16px;
    margin-left: 0;

    background: ${({ $isOpen, $isDisabled, theme }) =>
      $isDisabled
        ? theme.colors.lightGray
        : $isOpen
          ? theme.colors.darkMidnightBlue
          : 'transparent'};
    vertical-align: middle;
    &:hover {
      background: ${({ theme }) => theme.colors.darkMidnightBlue};

      svg {
        color: white;
      }
    }
  }
`;

const Filter = styled(FontAwesomeIcon)`
  height: 24px;
  font-weight: 400;
  cursor: pointer;

  @media only screen and (min-width: 768px) {
    height: 20px;
    color: var(--icon-color);

    &:hover {
      filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.5));
      color: white;
    }
  }
`;

const Badge = styled.div`
  font-size: 11px;
  position: absolute;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  margin-top: -30px;
  margin-left: 10px;
  @media only screen and (min-width: 768px) {
    background: red;
  }
  color: white;
  text-align: center;
  vertical-align: middle;
  line-height: 18px;
`;

const SC = {
  Filter,
  FilterBox,
  Badge
};

export default SC;
