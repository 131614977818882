import FilterButton from './FilterButton';
import Form from './Form';

const Favorite: React.FC = () => (
  <>
    <FilterButton />
    <Form />
  </>
);

export default Favorite;
