import { faRotateLeft } from '@fortawesome/pro-regular-svg-icons';

import { useFavorite } from '@context/Favorite';
import { useVessels } from '@context/Vessels';
import { useTranslator } from '@hooks/useTranslator';

const useViewModel = () => {
  const { t } = useTranslator();
  const {
    state: { selected, dirty },
    unselect,
    clearActiveItem
  } = useFavorite();
  const { clearFavorite } = useVessels();

  const onClick = () => {
    unselect();
    clearFavorite();
    clearActiveItem();
  };

  return {
    hide: !(selected || dirty),
    tooltip: t('favorites.resetForm'),
    icon: faRotateLeft,
    onClick
  };
};

export default useViewModel;
