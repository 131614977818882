import { Provider as VesselMarkersProvider } from '@routes/Location/Navigation/contexts/VesselMarkers';
import Presentor from './Presentor';
import { Provider } from './contexts';
import { Provider as VesselLastTimeStampProvider } from './contexts/VesselLastTimeStamp';
import { Provider as VoyageProvider } from './contexts/Voyage';

const Location: React.FC<{ standAlone?: boolean }> = ({
  standAlone = false
}) => {
  const controller = new AbortController();

  return (
    <Provider standAlone={standAlone}>
      <VesselLastTimeStampProvider>
        <VoyageProvider>
          <VesselMarkersProvider controller={controller}>
            <Presentor />
          </VesselMarkersProvider>
        </VoyageProvider>
      </VesselLastTimeStampProvider>
    </Provider>
  );
};

export default Location;
