import { useVessels } from '@context/Vessels';
import { useTranslator } from '@hooks/useTranslator';

const useViewModel = (view?: string) => {
  const { t } = useTranslator();
  const {
    state: { vessel }
  } = useVessels();

  return view
    ? t('text.selectVesselToViewPage', { drive: view })
    : vessel
      ? t('text.selectedVessel', { name: vessel.name })
      : t('text.selectVessel');
};

export default useViewModel;
