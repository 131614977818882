import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const Icon = styled(FontAwesomeIcon).attrs({
  'aria-label': 'alert-button'
})`
  color: ${({ theme }) => theme.colors.redOrange50};
  font-size: 28px;
  display: flex;
  align-self: center;
`;

const Box = styled.div`
  display: inline-flex;
  outline: none;
  border-radius: 10px;
  background: transparent;
  border: none;
  line-height: 16px;
  font-size: 14px;
  width: 42px;
  height: 42px;
  justify-content: center;
  display: inline-lex;
`;

const SC = {
  Icon,
  Box
};

export default SC;
