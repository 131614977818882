import styled from 'styled-components';

export type hTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5';

interface Props {
  $duration?: number;
}

const addAnimationClass = ($duration?: number) =>
  $duration !== undefined &&
  `&.animate__animated {
  --animate-duration: ${$duration}s;
}`;

const Heading1 = styled.h1<Props>`
  ${({ $duration }) => addAnimationClass($duration)}
`;

const Heading2 = styled.h2<Props>`
  ${({ $duration }) => addAnimationClass($duration)}
`;

const Heading3 = styled.h3<Props>`
  ${({ $duration }) => addAnimationClass($duration)}
`;

const Heading4 = styled.h4<Props>`
  ${({ $duration }) => addAnimationClass($duration)}
`;

const Heading5 = styled.h5<Props>`
  ${({ $duration }) => addAnimationClass($duration)}
`;

export default {
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5
};
