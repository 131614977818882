import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const ThumbFlex = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 16px;
`;

const ThumbIcon = styled(FontAwesomeIcon)`
  font-size: 72px;
  align-self: center;
`;

const ThumbsUp = styled(ThumbIcon)`
  color: ${({ theme }) => theme.colors.mantis};
`;

const ThumbsDown = styled(ThumbIcon)`
  color: ${({ theme }) => theme.colors.flamingo};
`;

const ToastMsg = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  max-width: 275px;
  text-align: center;
`;

const SC = {
  ThumbIcon,
  ThumbFlex,
  ThumbsUp,
  ThumbsDown,
  ToastMsg
};

export default SC;
