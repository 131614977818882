import { ThemeProvider } from '@angloeastern/react-library';
import theme from '../../themes';

interface Props {
  children: any;
}

export const Provider: React.FC<Props> = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
