import { FailIcon } from '@angloeastern/react-library';
import { Flash } from '@components/Animations/Div';
import useViewModel from './useViewModel';

const Display: React.FC = () => {
  const failMessage = useViewModel();

  return (
    <FailIcon>
      <Flash>{failMessage}</Flash>
    </FailIcon>
  );
};

export default Display;
