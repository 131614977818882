import SC from './style';
import useViewModel from './useViewModel';

const BlankViewOrg: React.FC<{ view?: string }> = ({ view }) => {
  const message = useViewModel(view);

  return (
    <SC.Container>
      <SC.Message>{message}</SC.Message>
    </SC.Container>
  );
};

export default BlankViewOrg;
