import { useProfile } from '@context/Profile';
import { useTranslator } from '@hooks/useTranslator';
import { useProfileTab } from '../context';

const useViewModel = () => {
  const { closeForm } = useProfile();
  const { t } = useTranslator('common', 'myProfile');
  const { confirmCancel, setConfirmCancel } = useProfileTab();

  const onCancel = () => setConfirmCancel(false);

  const onContinue = () => {
    setConfirmCancel(false);
    closeForm();
  };

  return {
    confirmCancel,
    alertText: t('changeDiscardAlert'),
    dialogProps: {
      title: t('cancelConfirmation'),
      noLabel: t('cancel'),
      yesLabel: t('continue'),
      onYes: onContinue,
      onNo: onCancel
    }
  };
};

export default useViewModel;
