import { useSystemNotifications } from '../context';
import SC from './style';

const MessageList: React.FC = () => {
  const { showList, unreadCount, setUnreadCount } = useSystemNotifications();
  return showList ? (
    <SC.Container>
      <SC.TmpBtn
        onClick={() => {
          if (unreadCount > 0) {
            setUnreadCount(unreadCount - 1);
          }
        }}
      >
        Read...
      </SC.TmpBtn>
    </SC.Container>
  ) : null;
};

export default MessageList;
