import styled from 'styled-components';

const Container = styled.div`
  padding: 30px 16px;
`;

const AlertText = styled.div`
  font-size: 1 rem;
  font-weight: bold;
  text-align: center;
`;

const ButtonsRow = styled.div`
  display: flex;
  justify-content: center;
  gap: 40px;
  flex-direction: column-reverse;
  padding: 30px 16px;
  border-top: 0.5px dotted ${({ theme }) => theme.colors.philippineGray};
  @media only screen and (min-width: 768px) {
    padding: 40px 38px 42px;
    flex-direction: row;
    gap: 40px;
    border: none;
  }
`;

const Button = styled.button`
  font-family: 'Roboto';
  padding: 10px 16px;
  line-height: 16px;
  font-size: 14px;
  border-radius: 10px;
  cursor: pointer;
`;

const ContinueButton = styled(Button)`
  color: white;
  background: ${({ theme }) => theme.colors.darkMidnightBlue};
  border: 1px solid ${({ theme }) => theme.colors.buttonBorder};
`;

const CancelButton = styled(Button)`
  background: none;
  border: 1px solid ${({ theme }) => theme.colors.philippineGray};
  color: ${({ theme }) => theme.colors.philippineGray};
  box-shadow: 0 5px 10px 2px rgb(0 0 0 / 10%);
  @media only screen and (min-width: 768px) {
    border: none;
  }
`;

const SC = {
  Container,
  AlertText,
  ButtonsRow,
  ContinueButton,
  CancelButton
};

export default SC;
