import type { FileTypes } from '@components/index';
import SC from './style';
import useViewModel from './useViewModel';

const FileIcon: React.FC<{
  filetype: FileTypes | string;
  filename: string;
}> = ({ filetype, filename }) => {
  const icon = useViewModel(filetype, filename);

  return <SC.FileIcon $filetype={filetype} icon={icon} />;
};

export default FileIcon;
