import { useDetectOutsideClick } from '@angloeastern/react-library';
import { useRef, useState } from 'react';
import { useViewFile } from '../../context';

const useViewModel = () => {
  const { source, select } = useViewFile();
  const [showSourceMenu, setShowSourceMenu] = useState(false);
  const menuBoxRef = useRef(null);

  useDetectOutsideClick(menuBoxRef, showSourceMenu, () => {
    setShowSourceMenu(false);
  });

  return {
    source,
    showMenuBox: Array.isArray(source) && source.length > 1,
    menuBoxRef,
    showSourceMenu,
    onShowMenuBox: () => setShowSourceMenu(true),
    changeSelected: (idx: number) => {
      setShowSourceMenu(false);
      select(idx);
    }
  };
};

export default useViewModel;
