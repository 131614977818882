import { useEffect } from 'react';

import { useView } from '@context/View';
import { useTranslator } from '@hooks/useTranslator';

const useSetCrumbs = (title: string | null, crumbs: string[] | null) => {
  const { t, language } = useTranslator();
  const { setCrumbs, setTitle } = useView();

  useEffect(() => {
    if (crumbs) {
      setCrumbs(crumbs.map((crumb: string) => t(crumb)));
    }
    if (title) {
      setTitle(t(title));
    }
  }, [title, language]);
};

export default useSetCrumbs;
