import { Trans } from 'react-i18next';

import type { Size } from '@components/Kpi/Item/style';
import SC from '../style';
import type { NullableKPI } from '../types';
import Arrow from './Arrow';
import useViewModel from './useViewModel';

const Footer: React.FC<{
  showArrow: boolean;
  size: Size;
  kpi: NullableKPI;
}> = ({ showArrow, size, kpi }) => {
  const footer = useViewModel(kpi);

  if (!footer) return null;

  const { direction, color, subValueKey, figure, extension, TextWrapper } =
    footer;

  return (
    <SC.Container $size={size}>
      {showArrow && <Arrow color={color} direction={direction} />}
      <SC.Label>
        <Trans
          i18nKey={subValueKey}
          values={{ figure, extension }}
          components={[<TextWrapper>number</TextWrapper>]}
        />
      </SC.Label>
    </SC.Container>
  );
};

export default Footer;
