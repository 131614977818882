import { Provider } from '@components/ListCardMenu/context';
import MenuItem from './MenuItem';
import MenuItemDownload from './MenuItemDownload';
import MenuItemFullView from './MenuItemFullView';
import Presentor from './Presentor';

export { MenuItem, MenuItemDownload, MenuItemFullView };

const ListCardMenu: React.FC<{ children: any }> = ({ children }) => (
  <Provider>
    <Presentor>{children}</Presentor>
  </Provider>
);

export default ListCardMenu;
