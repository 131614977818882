import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import type { Features } from '@components/index';
import { useAuthUser } from '@context/AuthUser';
import { getCurrentLanguage, getDefaultLanguage } from '@utils/index';
import { useTranslator } from './useTranslator';

const usePageAccess = (permit?: Features) => {
  const { permissions, hasPermission } = useAuthUser();
  const navigate = useNavigate();
  const { language } = useTranslator();
  const [state, setState] = useState({
    isWaiting: true,
    permitted: false
  });

  useEffect(() => {
    if (permissions) {
      const stateCopy = { ...state };
      stateCopy.isWaiting = false;
      if (permit && !hasPermission(permit)) {
        const defaultLang = getDefaultLanguage();
        const currentLang = getCurrentLanguage(language);
        const langPath = currentLang === defaultLang ? '' : `${currentLang}/`;
        navigate(`/${langPath}404`);
      } else {
        stateCopy.permitted = true;
      }
      setState(stateCopy);
    }
  }, [permissions]);

  return {
    ...state
  };
};

export default usePageAccess;
