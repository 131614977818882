import { JibeLight } from '@angloeastern/react-library';
import SC from './style';
import useViewModel from './useViewModel';

const JibeButton: React.FC = () => {
  const { url, hasAccess } = useViewModel();

  return hasAccess ? (
    <SC.Button href={url} target="_blank">
      <JibeLight />
    </SC.Button>
  ) : null;
};

export default JibeButton;
