import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  text-align: center;
`;

const Thumbnails = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 24px 4px;
  flex-direction: row;
  margin: 0 auto;
  max-width: 361px;
  gap: 8px 5px;
  justify-content: center;

  @media only screen and (min-width: 768px) {
    padding: 24px 18px;
    max-width: 1284px;
    gap: 36px;
  }
`;

const SC = {
  Container,
  Thumbnails
};

export default SC;
