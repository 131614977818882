import { useCallback, useEffect, useState } from 'react';

import {
  download,
  useFileViewer,
  useSearch
} from '@angloeastern/react-library';
import { FileTypes } from '@components/index';
import { useSpDriveCtx } from '@context/Sharepoint/DriveCtx';
import type { SharePointfile } from '@context/Sharepoint/types';
import { useDateUtils } from '@hooks/useDateUtils';
import useFileTypes from '@hooks/useFileTypes';
import useMessageBoxPresets from '@hooks/useMessageBoxPresets';
import { useShortLivedUrls } from '@hooks/useShortLivedUrls';
import useSendReportEmail from '../TableRow/useSendReportEmail';

interface State {
  previewUrl: string | undefined;
  downloadUrl: string | undefined;
  timestamp: number | undefined;
  isReady: boolean;
  hasError: boolean;
}

const initialState = {
  previewUrl: undefined,
  downloadUrl: undefined,
  timestamp: undefined,
  isReady: false,
  hasError: false
};

const useSpFile = (data: SharePointfile) => {
  const ctx = useSpDriveCtx();
  const { formatDateTime } = useDateUtils();
  const {
    canPreview,
    getExtension,
    isAudio,
    isCode,
    isCompressed,
    isMimeAudio,
    isMimeCompressed,
    isMimeVideo,
    isVideo
  } = useFileTypes();
  const { setTerm } = useSearch();
  const { openViewer, setSource } = useFileViewer();
  const { getPreviewUrl, getDownloadUrl } = useShortLivedUrls(data);
  const [state, setState] = useState<State>(initialState);
  const previewAble = canPreview(data.type as FileTypes);
  const extension = getExtension(data.name);
  const { sendEmail } = useSendReportEmail(data);
  const { showEmailSuccess } = useMessageBoxPresets();

  const getFileType = () => {
    const knownTypes = Object.values(FileTypes);
    if (knownTypes.includes(data.type as FileTypes)) return data.type;
    else {
      if (isMimeVideo(data.mimeType) || isVideo(extension))
        return FileTypes.VIDEO;
      if (isMimeAudio(data.mimeType) || isAudio(extension))
        return FileTypes.AUDIO;
      if (isMimeCompressed(data.mimeType) || isCompressed(extension))
        return FileTypes.ARCHIVE;
      if (isCode(extension)) return FileTypes.CODE;
      switch (extension) {
        case 'exe':
          return FileTypes.BINARY;
        case 'csv':
          return FileTypes.CSV;
        default:
          return 'Unknown';
      }
    }
  };

  const fileType = getFileType();

  const setFetchError = useCallback(() => {
    if (!state.hasError) {
      setState({
        ...state,
        hasError: true
      });
    }
  }, [state.hasError]);

  const isExpired = () => {
    if (state.timestamp) {
      const now = new Date().getTime();
      const diff = now - state.timestamp;
      return diff >= 540000; // 9 min TTL
    }
    return false;
  };

  const handleClick = async () => {
    const {
      state: { driveId },
      folderStackPush
    } = ctx;
    const strippedName = data.name.replace(/(<([^>]+)>)/gi, '');
    if (data.type === FileTypes.FOLDER) {
      folderStackPush({ ...data, name: strippedName });
      setTerm('');
    } else if (driveId) {
      const hasValidUrls =
        state.previewUrl && state.downloadUrl && !isExpired();
      const urls = hasValidUrls ? { ...state } : await getUrls(driveId);

      if (urls) {
        setSource({
          url: urls.previewUrl as string,
          name: strippedName,
          type: fileType,
          dlUrl: urls.downloadUrl as string
        });
        openViewer();
      }
    }
  };

  const handleSendEmail = async () => {
    const result = await sendEmail();
    if (result) {
      showEmailSuccess();
    }
  };

  const handleDownload = useCallback(async () => {
    const {
      state: { driveId }
    } = ctx;
    if (data.type !== FileTypes.FOLDER) {
      if (!state.downloadUrl && driveId) {
        const { downloadUrl } = await getUrls(driveId);
        if (downloadUrl) {
          download(downloadUrl);
        } else {
          setFetchError();
        }
      } else if (state.downloadUrl) {
        download(state.downloadUrl);
      }
    } else {
      handleSendEmail();
    }
  }, [ctx, state, data]);

  async function getUrls(driveId: string) {
    const stateCopy = { ...state };
    if (driveId && data.type !== FileTypes.FOLDER && !stateCopy.hasError) {
      try {
        stateCopy.downloadUrl = await getDownloadUrl(driveId);
        stateCopy.previewUrl =
          !previewAble && data.type !== FileTypes.IMAGE
            ? '-'
            : await getPreviewUrl(driveId);

        if (state.previewUrl !== stateCopy.previewUrl) {
          stateCopy.timestamp = new Date().getTime();
        }
        stateCopy.isReady = true;
      } catch (e: any) {
        stateCopy.hasError = true;
        setFetchError();
      }
    }
    return stateCopy;
  }

  useEffect(() => {
    return () => setState(initialState);
  }, []);

  return {
    handleClick,
    handleDownload,
    modifiedOn: formatDateTime(data.modifiedOnUtc),
    isReady: state.isReady,
    hasError: state.hasError,
    fileType,
    extension,
    ctx
  };
};

export default useSpFile;
