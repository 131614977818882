import { initActiveItem } from '.';
import type { Action, State, ToastData } from './types';
import { Actions } from './types';

const reducer = (state: State, action: Action): State => {
  const showToast = (data: ToastData) => {
    const { message, success } = data;
    return {
      ...state,
      toast: message,
      status: success
    };
  };

  const clearSelection = () => {
    const activeItemCopy = Object.assign({}, state.activeItem);
    activeItemCopy.vesselAeCodes = [];

    return {
      ...state,
      activeItem: activeItemCopy,
      selected: activeItemCopy
    };
  };

  switch (action.type) {
    case Actions.FETCH_FAVORITES:
      return {
        ...state,
        favorites: action.payload,
        selected: undefined
      };
    case Actions.CREATE_FAVORITE:
      return {
        ...state,
        favorites: action.payload
      };
    case Actions.REMOVE_FAVORITE:
      return {
        ...state,
        favorites: state.favorites?.filter(
          (f) => f.guid !== action.payload.guid
        ),
        selected: undefined
      };
    case Actions.UPDATE_FAVORITE:
      return {
        ...state,
        favorites: action.payload
      };
    case Actions.SET_FAVORITE:
      return {
        ...state,
        selected: action.payload
      };
    case Actions.CLEAR_FAVORITE:
      return {
        ...state,
        selected: undefined
      };
    case Actions.SET_ACTIVE_ITEM:
      return {
        ...state,
        activeItem: action.payload
      };
    case Actions.CLEAR_ACTIVE_ITEM:
      return {
        ...state,
        activeItem: initActiveItem
      };
    case Actions.CLEAR_SELECTION:
      return clearSelection();
    case Actions.SET_FORM_OPEN:
      return {
        ...state,
        isOpen: action.payload
      };
    case Actions.SET_DIRTY:
      return {
        ...state,
        dirty: action.payload
      };
    case Actions.SET_LOADING:
      return {
        ...state,
        isLoading: action.payload
      };
    case Actions.SHOW_TOAST:
      return showToast(action.payload);
    case Actions.HIDE_TOAST:
      return {
        ...state,
        toast: '',
        status: undefined
      };
    case Actions.SET_DISABLED:
      return {
        ...state,
        isDisabled: action.payload
      };
    case Actions.SET_DISABLED_TOOLTIP:
      return {
        ...state,
        disabledToolTip: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
