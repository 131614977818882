import { useTranslator } from '@hooks/useTranslator';
import { useSecurityTab } from '../../context';

export const useViewModel = () => {
  const { t } = useTranslator('common', 'myProfile');
  const { isDisabled, inProgress, isOffice365, handleCancel, onUpdate } =
    useSecurityTab();

  return {
    labels: {
      cancel: t('cancel'),
      update: t('update')
    },
    isDisabled,
    inProgress,
    isOffice365,
    handleCancel,
    onUpdate
  };
};
