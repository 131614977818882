import { Sidebar } from '@angloeastern/react-library';
import Footer from './Footer';
import useViewModel from './useViewModel';

const MenuBar: React.FC = () => {
  const { menu, handleClick, handleChangePage } = useViewModel();

  return (
    <Sidebar
      menu={menu || []}
      tipWidth={120}
      footerComponent={Footer}
      handleChangePage={handleChangePage}
      handleClick={handleClick}
    />
  );
};

export default MenuBar;
