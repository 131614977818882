import { faCircleXmark } from '@fortawesome/pro-light-svg-icons';

import MessageRotator from './MessageRotator';
import SC from './style';
import useViewModel from './useViewModel';

const AdminNotifications: React.FC = () => {
  const { show, messages, closeNotifications } = useViewModel();

  return show ? (
    <SC.Container>
      <SC.Message>
        <MessageRotator messages={messages} />
      </SC.Message>
      <SC.IconBox>
        <SC.CrossIcon icon={faCircleXmark} onClick={closeNotifications} />
      </SC.IconBox>
    </SC.Container>
  ) : null;
};

export default AdminNotifications;
