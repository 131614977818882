import { Systems } from '@components/index';
import { useAuthUser } from '@context/AuthUser';
import SC from './style';

const JibeIcon: React.FC<{
  size?: number;
  color?: string;
  [x: string]: any;
}> = ({ size = 24, color = 'light', ...rest }) => {
  const { hasAccess } = useAuthUser();

  return hasAccess(Systems.JIBE) ? (
    <SC.Container $size={size} $color={color} {...rest}>
      <SC.Icon $color={color} />
    </SC.Container>
  ) : null;
};

export default JibeIcon;
