import styled from 'styled-components';

const HeadContainer = styled.div`
  margin-bottom: 0;
  padding: 18px 12px;
  box-shadow: 0px 7px 20px ${({ theme }) => theme.colors.black07};
  font-weight: 700;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.licorice};
  background: ${({ theme }) => theme.colors.white};
  position: relative;
  display: flex;
  color: white;
  background: transparent;
  padding: 24px;
  min-height: 32px;

  @media only screen and (min-width: 768px) {
    color: ${({ theme }) => theme.colors.licorice};
    box-shadow: none;
    background: linear-gradient(
      180deg,
      ${({ theme }) => theme.colors.gray98} 0%,
      ${({ theme }) => theme.colors.brightGray} 100%
    );
    border: 1px solid ${({ theme }) => theme.colors.brightGray};
    padding: 0;
    align-self: center;
    align-items: center;
    width: 300px;
    min-height: 30px;
    max-height: 54px;
    height: 100%;
  }
`;

const IconContainer = styled.div`
  ${({ theme }) => theme.icons.topBarCorner};
`;

const LabelContainer = styled.div`
  ${({ theme }) => theme.text.topBarCornerLabel};
`;

const SC = {
  HeadContainer,
  IconContainer,
  LabelContainer
};

export default SC;
