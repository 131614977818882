import { memo } from 'react';

import type { SharePointfile } from '@context/Sharepoint/types';
import Presentor from './Presentor';
import { Provider } from './context';

const Slide: React.FC<{
  file: SharePointfile;
}> = memo(({ file }) => {
  return (
    <Provider file={file}>
      <Presentor />
    </Provider>
  );
});

export default Slide;
