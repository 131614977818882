import { useCallback, useContext, useMemo } from 'react';

import createDataContext from '@context/createDataContext';
import { debounce } from '@utils/index';
import * as actions from './actions';
import reducer from './reducer';
import type { State } from './types';
import { PositionStatus } from './types';

const initialState: State = {
  activeVoyage: null,
  error: false,
  noData: false,
  centered: false,
  abortController: null
};

export const { Context, Provider } = createDataContext(
  reducer,
  { ...actions },
  initialState
);

export const useVoyage = () => {
  const ctx = useContext<any>(Context);
  const {
    state: { activeVoyage }
  } = ctx;

  const fetchVoyage = useMemo(
    () =>
      debounce(
        (
          vesselAeCode: string,
          fosVesselId: string,
          endDateTime: string,
          active = true,
          signal?: AbortSignal
        ) =>
          ctx.fetchVoyage(
            vesselAeCode,
            fosVesselId,
            endDateTime,
            active,
            signal
          ),
        300
      ),
    [ctx.fetchVoyage]
  );

  const getLastPositionTimeStamp = useCallback(() => {
    if (activeVoyage && activeVoyage.voyages && activeVoyage.voyages.length) {
      const lastpos = activeVoyage.voyages[activeVoyage.voyages.length - 1];
      return lastpos.startDateTime;
    }
    return null;
  }, [activeVoyage]);

  const getLastPosition = useCallback(() => {
    if (activeVoyage && activeVoyage.voyages && activeVoyage.voyages.length) {
      const lastpos = activeVoyage.voyages[activeVoyage.voyages.length - 1];
      return {
        lat: lastpos.latitude,
        lng: lastpos.longitude
      };
    } else if (
      activeVoyage.isVis &&
      activeVoyage.vesselPositionStatus === PositionStatus.IN_PORT &&
      activeVoyage.departurePort
    ) {
      return {
        lat: activeVoyage.departurePort.latitude,
        lng: activeVoyage.departurePort.longitude
      };
    }
    return null;
  }, [activeVoyage]);

  return {
    ...ctx,
    fetchVoyage,
    getLastPosition,
    getLastPositionTimeStamp
  };
};
