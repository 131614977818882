import { useViewFile } from '../context';

const useViewModel = () => {
  const {
    showTop,
    showDowloadButton,
    source,
    selected,
    downloading,
    showCrossmark,
    closeViewer,
    onDownloadClick,
    toggleFullScreen,
    isFullScreen,
    showFullscreenButton
  } = useViewFile();

  return {
    show: showTop && source && selected,
    showDowloadButton,
    downloading,
    showCrossmark,
    closeViewer,
    onDownloadClick,
    toggleFullScreen,
    isFullScreen,
    showFullscreenButton
  };
};

export default useViewModel;
