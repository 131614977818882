import { DownloadIcon, FailIcon } from '@angloeastern/react-library';
import DocIcon from '../DocIcon';
import type { Props } from '../ListItem';
import SC from './style';
import useViewModel from './useViewModel';

const TableRow: React.FC<Props> = ({ data }) => {
  const {
    hasError,
    fileType,
    modifiedOn,
    dataType,
    showDownload,
    failIcon,
    onNameClick,
    onDownload
  } = useViewModel(data);

  return (
    <SC.Row>
      <SC.Cell onClick={onNameClick}>
        <SC.Icon>
          <DocIcon hasError={hasError} data={data} fileType={fileType} />
        </SC.Icon>
        <SC.Name dangerouslySetInnerHTML={{ __html: data.name }} />
      </SC.Cell>
      <SC.Cell>{modifiedOn}</SC.Cell>
      <SC.Cell>{data.modifiedBy}</SC.Cell>
      <SC.Cell>{dataType}</SC.Cell>
      <SC.Cell>
        {showDownload ? (
          <DownloadIcon handleClick={onDownload} />
        ) : hasError ? (
          <FailIcon icon={failIcon} />
        ) : (
          ''
        )}
      </SC.Cell>
    </SC.Row>
  );
};

export default TableRow;
