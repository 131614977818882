import { Dropdown as BaseDropdown } from '@angloeastern/react-library';
import type { Favorite } from '@context/Favorite/types';
import useViewModel from './useViewModel';

const Dropdown: React.FC<{
  options: Array<Favorite>;
  selected?: any;
  onClick(...arg: any): void;
}> = ({ options, selected, onClick }) => {
  const props = useViewModel(options);

  return <BaseDropdown {...props} selected={selected} onClick={onClick} />;
};

export default Dropdown;
