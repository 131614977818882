import { WebStorageStateStore } from 'oidc-client-ts';
import type { ReactNode } from 'react';
import { AuthProvider } from 'react-oidc-context';

const {
  REACT_APP_OIDC_AUTHORITY: authority,
  REACT_APP_OIDC_CLIENT_ID: clientId,
  REACT_APP_OIDC_CLIENT_SCOPE: scope,
  REACT_APP_OIDC_REDIRECT_URI: redirectUri,
  REACT_APP_OIDC_RESPONSE_TYPE: responseType,
  REACT_APP_OIDC_CLIENT_ROOT: logoutUrl,
  REACT_APP_OIDC_SILENT_REDIRECT_URI: silentRedirectUri
} = process.env;

const oidcConfig = {
  authority: authority as string,
  client_id: clientId as string,
  redirect_uri:
    (redirectUri as string) || `${window.location.origin}/userCallback`,
  response_type: responseType as string,
  scope: scope as string,
  post_logout_redirect_uri:
    (logoutUrl as string) || `${window.location.origin}/`,
  silent_redirect_uri:
    (silentRedirectUri as string) ||
    `${window.location.origin}/silent-renew.html`,
  userStore: new WebStorageStateStore({ store: localStorage }),
  automaticSilentRenew: true,
  loadUserInfo: true
};

const Provider: React.FC<{ children: ReactNode }> = ({ children }) => {
  return <AuthProvider {...oidcConfig}>{children}</AuthProvider>;
};

export default Provider;
