import { DownloadIcon, FailIcon } from '@angloeastern/react-library';
import useSpFile from '@components/SharePointDocList/hooks/useSpFile';
import { FileTypes } from '@components/index';
import type { SharePointfile } from '@context/Sharepoint/types';
import DocIcon from '../DocIcon';
import SC from './style';

export interface Props {
  data: SharePointfile;
}

const Listitem: React.FC<Props> = ({ data }) => {
  const { ctx, modifiedOn, hasError, fileType, handleClick, handleDownload } =
    useSpFile(data);
  const {
    state: { canBulkDownload }
  } = ctx;
  const showDownload =
    data.type !== FileTypes.GALLERY &&
    ((data.type === FileTypes.FOLDER && canBulkDownload) ||
      data.type !== FileTypes.FOLDER);

  return (
    <SC.Container>
      <SC.IconContainer onClick={handleClick}>
        <DocIcon data={data} fileType={fileType} />
      </SC.IconContainer>
      <SC.DetailsContainer onClick={handleClick}>
        <SC.DocName dangerouslySetInnerHTML={{ __html: data.name }} />
        <SC.DocDate>{modifiedOn}</SC.DocDate>
      </SC.DetailsContainer>
      <SC.ActionsContainer>
        {showDownload ? (
          <DownloadIcon handleClick={handleDownload} />
        ) : hasError ? (
          <FailIcon icon={{ color: '', size: 18 }} />
        ) : (
          ''
        )}
      </SC.ActionsContainer>
    </SC.Container>
  );
};

export default Listitem;
