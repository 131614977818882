import type { Size } from '@components/Kpi/Item/style';
import { useVessels } from '@context/Vessels';
import { getCurrencyLocale, isCurrencyCode } from '@utils/currency';
import SC from './style';
import type { NullableKPI } from './types';

const useViewModel = (
  title: string,
  tooltip?: string,
  kpi?: NullableKPI,
  disable = false,
  showArrow = true,
  showBody = true,
  size = 'default',
  showZero = false
) => {
  const {
    state: { vessel }
  } = useVessels();
  const locale = vessel?.locale;

  if (!kpi || (!kpi.value && !kpi.subValue && !kpi.footerValue)) {
    return {
      itemProps: {
        title,
        tooltip,
        disable,
        size,
        body: showZero ? '0' : '-'
      },
      footer: null,
      footerExt: null
    };
  }

  const {
    value,
    valueUnit,
    subValue,
    subValueLabel,
    subValueLabelUnit,
    footerLabel,
    footerValue
  } = kpi;
  const isNegative = value !== null ? (value as unknown as number) < 0 : false;
  let isCurrency = false;
  let displayValue = '-';
  const absValue =
    value !== null
      ? isNaN(value as any)
        ? `${value}${valueUnit}`
        : Math.abs(value as unknown as number)
      : null;

  if (absValue !== null) {
    switch (valueUnit) {
      case '$':
        isCurrency = true;
        displayValue = Math.round(absValue as unknown as number).toLocaleString(
          locale
        );
        break;
      case '%':
        displayValue = `${absValue.toLocaleString('en')}%`;
        break;
      case 'tonnes':
        displayValue = `${absValue.toLocaleString('en')} t`;
        break;
      case 't/d':
        displayValue = `${absValue.toLocaleString('en')} t/d`;
        break;
      default:
        if (isCurrencyCode(valueUnit)) {
          isCurrency = true;
          displayValue = Math.round(
            absValue as unknown as number
          ).toLocaleString(getCurrencyLocale(valueUnit));
        } else {
          displayValue = absValue.toString();
        }
        break;
    }
  }

  const body =
    isNegative && isCurrency ? (
      <SC.RedBody>{displayValue}</SC.RedBody>
    ) : (
      displayValue
    );

  const itemProps = {
    title,
    tooltip,
    body,
    disable,
    size,
    showBody
  } as any;

  let footer = null;
  let footerExt = null;

  if (
    subValueLabelUnit &&
    subValueLabelUnit?.trim?.() !== '' &&
    subValueLabel &&
    subValueLabel.trim?.() !== '' &&
    subValue !== null &&
    subValue !== undefined
  ) {
    footer = {
      showArrow,
      size: size as Size,
      kpi
    };
  }

  if (footerLabel && footerValue) {
    footerExt = {
      size: size as Size,
      kpi
    };
  }

  return {
    footer,
    footerExt,
    itemProps
  };
};

export default useViewModel;
