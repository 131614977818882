import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useProfile } from '@context/Profile';
import { useTranslator } from '@hooks/useTranslator';
import { getSupportedLanguages } from '@utils/index';
import { useProfileTab } from '../context';

const useViewModel = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const {
    t,
    i18n: { changeLanguage }
  } = useTranslator('common', 'myProfile');
  const { dirty, profile, locale, setConfirmCancel, setToastState } =
    useProfileTab();
  const { update, closeForm } = useProfile();

  const handleChange = (lang: string) => {
    const supported = getSupportedLanguages();
    const pathSplit = pathname.split('/');
    pathSplit.shift();
    if (supported.includes(pathSplit[0])) pathSplit.shift();

    navigate(
      `/${lang === 'en' || lang === '' ? '' : lang + '/'}${pathSplit.join('/')}`
    );
    changeLanguage(lang);
  };

  const onUpdate = () => {
    if (profile) {
      try {
        update({ ...profile, language: locale });
        handleChange(locale);
        setToastState({
          show: true,
          message: t('updateSuccess'),
          success: true
        });
      } catch (error) {
        setToastState({
          show: true,
          message: t('updateFailed'),
          success: false
        });
      }
    }
  };

  const onCancel = useCallback(() => {
    setConfirmCancel(dirty);
    if (!dirty) {
      closeForm();
    }
  }, [dirty]);

  return {
    labels: {
      cancel: t('cancel'),
      update: t('update')
    },
    onCancel,
    onUpdate
  };
};

export default useViewModel;
