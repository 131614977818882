import { memoize } from '@angloeastern/react-library';
import { FileTypes } from '@components/index';
import audioExtensions from './audio-extensions.json';
import audioMimes from './audio-mimes.json';
import codeExtensions from './code-extensions.json';
import codeMimes from './code-mimes.json';
import compressedExtensions from './compressed-extensions.json';
import compressedMimes from './compressed-mimes.json';
import imageMimes from './image-mimes.json';
import spreadSheetMimes from './spreadsheet-mimes.json';
import videoExtensions from './video-extensions.json';
import videoMimes from './video-mimes.json';

const useFileTypes = () => {
  const getExtension = (fileName: string) => fileName.split('.').pop();

  const canPreview = memoize((type: FileTypes) => {
    const previewSupported = [FileTypes.PDF]; //, FileTypes.POWERPOINT];

    return previewSupported.indexOf(type) >= 0;
  });

  const getFileTypeFromMime = memoize((type: string) => {
    switch (type) {
      case 'application/pdf':
        return FileTypes.PDF;
      case 'application/vnd.ms-powerpoint':
      case 'application/vnd.ms-powerpoint.presentation.macroEnabled.12':
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        return FileTypes.POWERPOINT;
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      case 'application/vnd.ms-excel.sheet.binary.macroEnabled.12':
      case 'application/vnd.ms-excel':
      case 'application/vnd.ms-excel.sheet.macroEnabled.12':
        return FileTypes.EXCEL;
      case 'application/msword':
      case 'application/vnd.ms-word.document.macroEnabled.12':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.template':
        return FileTypes.WORD;
      case 'text/csv':
        return FileTypes.CSV;
      default:
        if (isMimeCompressed(type)) return FileTypes.ARCHIVE;
        if (isMimeVideo(type)) return FileTypes.VIDEO;
        if (isMimeAudio(type)) return FileTypes.AUDIO;
        if (isMimeImage(type)) return FileTypes.IMAGE;
        if (isMimeCode(type)) return FileTypes.CODE;
        if (isMimeSpreadSheet(type)) return FileTypes.SPREADSHEET;
        return 'Unknown';
    }
  });

  const findInList = (listSource: any, item: string) => {
    const { list } = listSource;

    return list.indexOf(item.toLocaleLowerCase()) >= 0;
  };

  const isVideo = memoize((ext: string) => findInList(videoExtensions, ext));

  const isCode = memoize((ext: string) => findInList(codeExtensions, ext));

  const isAudio = memoize((ext: string) => findInList(audioExtensions, ext));

  const isCompressed = memoize(async (ext: string) =>
    findInList(compressedExtensions, ext)
  );

  const isMimeVideo = memoize((mimeType: string) =>
    findInList(videoMimes, mimeType)
  );

  const isMimeAudio = memoize((mimeType: string) =>
    findInList(audioMimes, mimeType)
  );

  const isMimeCompressed = memoize((mimeType: string) =>
    findInList(compressedMimes, mimeType)
  );

  const isMimeImage = memoize((mimeType: string) =>
    findInList(imageMimes, mimeType)
  );

  const isMimeSpreadSheet = memoize((mimeType: string) =>
    findInList(spreadSheetMimes, mimeType)
  );

  const isMimeCode = memoize((mimeType: string) =>
    findInList(codeMimes, mimeType)
  );

  const isFileVideo = (fileName: string) => isVideo(getExtension(fileName));

  const isFileAudio = (fileName: string) => isAudio(getExtension(fileName));

  const isFileCode = (fileName: string) => isCode(getExtension(fileName));

  const isFileCompressed = (fileName: string) =>
    isCompressed(getExtension(fileName));

  return {
    getExtension,
    canPreview,
    getFileTypeFromMime,
    isVideo,
    isCode,
    isAudio,
    isMimeAudio,
    isMimeVideo,
    isCompressed,
    isMimeCompressed,
    isMimeImage,
    isMimeSpreadSheet,
    isMimeCode,
    isFileVideo,
    isFileAudio,
    isFileCode,
    isFileCompressed
  };
};

export default useFileTypes;
