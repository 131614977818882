import { useTranslator } from './useTranslator';

export const useDateUtils = () => {
  const { t } = useTranslator();

  const getLocalDate = (d: Date, ms = 0): Date => {
    const offset: number = d.getTimezoneOffset();
    return new Date(d.getTime() - offset * 60 * 1000 + ms);
  };

  const parseDate = (d: string) => {
    const theDate = getLocalDate(new Date(d));
    const [datePart, timePart] = theDate.toJSON().split('T');
    const [year, monthnum, day] = datePart.split('-');
    const time = timePart.substring(0, 5);
    const month = getMonthShort(monthnum);

    return { day, month, year, time, monthnum };
  };

  const getMonth = (monthNumber: string) => {
    const month = getMonthShort(monthNumber);

    return t(`months.${month}`);
  };

  const isValidDate = (d: string) =>
    d && !['', '-', '0'].includes(d.trim()) && !isNaN(Date.parse(d));

  const parseDateLocalized = (d: string) => {
    const { day, year, time, monthnum } = parseDate(d);
    const month = getMonth(monthnum);

    return { day, month, year, time, monthnum };
  };

  const formatDate = (d: string) => {
    if (!isValidDate(d)) return '';
    const { day, month, year } = parseDateLocalized(d);
    return t('format.date', { day, month, year });
  };

  const formatDateTime = (d: string) => {
    if (!isValidDate(d)) return '';
    const parsedDate = parseDateLocalized(d);
    return t('format.datetime', { ...parsedDate });
  };

  const getMonthShort = (monthNumber: string) => {
    const months = [
      'jan',
      'feb',
      'mar',
      'apr',
      'may',
      'jun',
      'jul',
      'aug',
      'sep',
      'oct',
      'nov',
      'dec'
    ];
    const index = parseInt(monthNumber, 10) - 1;

    return months[index];
  };

  const dayDiff = (d1: Date, d2: Date) =>
    Math.ceil((d1.getTime() - d2.getTime()) / (1000 * 3600 * 24));

  const dayDiffFromNow = (d: Date | string) => {
    const today = new Date();
    const d2 = d instanceof Date ? d : new Date(d);
    return dayDiff(today, d2);
  };

  return {
    dayDiff,
    dayDiffFromNow,
    getMonth,
    getMonthShort,
    isValidDate,
    formatDate,
    parseDateLocalized,
    formatDateTime,
    parseDate,
    getLocalDate
  };
};
