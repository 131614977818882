import { FileTypes } from '@components/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const FileIcon = styled(FontAwesomeIcon)<{ $filetype: FileTypes | string }>`
  --fa-secondary-color: white;
  --fa-secondary-opacity: 1;
  font-size: 36px;

  ${({ $filetype, theme }) => {
    switch ($filetype) {
      case FileTypes.PDF:
        return `
        color: ${theme.colors.flamingo};
      `;
      case FileTypes.EXCEL:
        return `
        color: ${theme.colors.darkSpringGreen};
      `;
      case FileTypes.WORD:
        return `
        color: ${theme.colors.celticBlue};
      `;
      case FileTypes.POWERPOINT:
        return `
        color: ${theme.colors.darkPastelRed};
      `;
      case FileTypes.IMAGE:
        return `
        color: magenta;
      `;
      case FileTypes.VIDEO:
        return `
        color: blue;
      `;
      case FileTypes.AUDIO:
        return `
        color: ${theme.colors.magicPotion};
      `;
      case FileTypes.ARCHIVE:
        return `
        color: ${theme.colors.buttercup50};
      `;
      case FileTypes.CODE:
        return `
        color: ${theme.colors.purpleNavy};
      `;
      default:
        return `
        color: ${theme.colors.licorice};
      `;
    }
  }}
`;

const SC = {
  FileIcon
};

export default SC;
