import { Image, useFileViewer } from '@angloeastern/react-library';
import { useConfig } from '@context/Config';
import SC from './style';

const ImagePreview: React.FC = () => {
  const {
    state: { selected }
  } = useFileViewer();
  const { DUMMY_SLIDE } = useConfig();

  return selected ? (
    <SC.Container>
      <Image
        renderer={SC.Image}
        src={selected.url}
        alt={selected.name}
        fallbackSrc={DUMMY_SLIDE}
      />
    </SC.Container>
  ) : null;
};

export default ImagePreview;
