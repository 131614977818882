import { apiGet } from '@api/callAPI';
import type { Downloadable, SharePointfile } from '@context/Sharepoint/types';

export const getDownloadURL = async (
  driveId: string,
  file: Downloadable,
  signal?: AbortSignal
) => {
  const { url } = await apiGet(`/urls/download/${driveId}/${file.id}`, false, {
    signal
  });
  return url;
};

export const getPreviewURL = async (
  driveId: string,
  file: Downloadable,
  headers?: any
) => {
  const typeSuffix = (file as SharePointfile).type
    ? `/${(file as SharePointfile).type}`
    : '';
  const { url } = await apiGet(
    `/urls/preview/${driveId}/${file.id}${typeSuffix}`,
    false,
    {
      ...headers,
      'X-App-Path': window.location.pathname
    }
  );
  return url;
};
