// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import PDFWorker from 'pdfjs-dist/legacy/build/pdf.worker.min.mjs';
import { pdfjs } from 'react-pdf';

export const SetupPDFJS = () => {
  const config = (process.env.REACT_APP_PDFJS_CONFIG || '').split(';');

  const useExternalWorkerSource =
    config.includes('useExternalWorkerSource') || false;

  const usePdfPort = config.includes('usePdfPort') || false;

  const workerPath = useExternalWorkerSource
    ? `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`
    : new URL(
        'pdfjs-dist/legacy/build/pdf.worker.min.mjs',
        import.meta.url
      ).toString();

  if (usePdfPort) {
    const pdfWorker = new PDFWorker();
    pdfjs.GlobalWorkerOptions.workerPort = pdfWorker.port;
  } else {
    pdfjs.GlobalWorkerOptions.workerSrc = workerPath;
  }
};
