import { useState, type ReactNode } from 'react';
import type { hTag } from './style';
import SC from './style';

const getStyledHeader = (tag: hTag) => {
  switch (tag) {
    case 'h1':
      return SC.Heading1;
    case 'h2':
      return SC.Heading2;
    case 'h4':
      return SC.Heading4;
    case 'h5':
      return SC.Heading5;
    default:
      return SC.Heading3;
  }
};

export const AnimatedHeading: React.FC<{
  tag: hTag;
  animation: string;
  duration?: number;
  className?: string;
  children: ReactNode;
}> = ({ tag: Tag, animation, duration, className, children }) => {
  const StyledTag = getStyledHeader(Tag);

  const possibleDuration = [0.5, 1.0, 1.5, 2.0, 2.5];
  const [theDuration] = useState(() => {
    return duration === undefined
      ? possibleDuration[Math.floor(Math.random() * possibleDuration.length)]
      : duration;
  });
  const classNames = `animate__animated animate__${animation} ${className}`;

  return (
    <StyledTag $duration={theDuration} className={classNames}>
      {children}
    </StyledTag>
  );
};

export const SlideInLeft: React.FC<{
  tag: hTag;
  className?: string;
  duration?: number;
  children: ReactNode;
}> = ({ tag, className, duration, children }) => (
  <AnimatedHeading
    tag={tag}
    animation="slideInLeft"
    className={className}
    duration={duration}
  >
    {children}
  </AnimatedHeading>
);

export const SlideInDown: React.FC<{
  tag: hTag;
  className?: string;
  duration?: number;
  children: ReactNode;
}> = ({ tag, className, duration, children }) => (
  <AnimatedHeading
    tag={tag}
    animation="slideInDown"
    className={className}
    duration={duration}
  >
    {children}
  </AnimatedHeading>
);

export const SlideInLeftH3: React.FC<{
  className?: string;
  duration?: number;
  children: ReactNode;
}> = ({ className, duration, children }) => (
  <AnimatedHeading
    tag={'h3'}
    animation="slideInLeft"
    className={className}
    duration={duration}
  >
    {children}
  </AnimatedHeading>
);

export const FlipInXH1: React.FC<{
  className?: string;
  duration?: number;
  children: ReactNode;
}> = ({ className, duration, children }) => (
  <AnimatedHeading
    tag={'h1'}
    animation="flipInX"
    className={className}
    duration={duration}
  >
    {children}
  </AnimatedHeading>
);

export const SlideInUpH4: React.FC<{
  className?: string;
  duration?: number;
  children: ReactNode;
}> = ({ className, duration, children }) => (
  <AnimatedHeading
    tag={'h4'}
    animation="slideInUp"
    className={className}
    duration={duration}
  >
    {children}
  </AnimatedHeading>
);

export const SlideInDownH3: React.FC<{
  className?: string;
  duration?: number;
  children: ReactNode;
}> = ({ className, duration, children }) => (
  <AnimatedHeading
    tag={'h3'}
    animation="slideInDown"
    className={className}
    duration={duration}
  >
    {children}
  </AnimatedHeading>
);
