export interface Organization {
  id?: number | null;
  name: string;
  aeCode?: string | null;
  logo?: string | null;
  countryName: string;
  countryIso3: string;
  countryId: number;
  createdOnUtc: string;
  organizationAeCode: string;
  sortKey?: string | null;
  photoUrl?: string | null;
}

export type Organizations = Array<Organization>;

export enum Actions {
  FETCH_DATA = 'fetch-data',
  SET_CURRENT = 'set-current',
  RESET_DATA = 'reset-data',
  SET_ERROR = 'set-error'
}

export interface State {
  organizations: Organizations | null;
  organization: Organization | null;
  error: boolean;
}

export type Action =
  | { type: Actions.FETCH_DATA; payload: Organizations }
  | { type: Actions.SET_CURRENT; payload: Organization | null }
  | { type: Actions.RESET_DATA }
  | { type: Actions.SET_ERROR };
