import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { MouseEventHandler } from 'react';

import MenuItem from '../MenuItem';
import useViewModel from './useViewModel';

const MenuItemFullView: React.FC<{ handleClick: MouseEventHandler }> = ({
  handleClick
}) => {
  const { label, icon } = useViewModel();

  return (
    <MenuItem label={label} handleClick={handleClick}>
      <FontAwesomeIcon icon={icon} />
    </MenuItem>
  );
};

export default MenuItemFullView;
