import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  min-height: calc(100vh - 96px);
  width: 100%;
  align-content: center;
  background: white;
`;

const Message = styled.div.attrs({
  className: 'animate__animated animate__rubberBand'
})`
  margin: 0 auto;
  font-size: 22px;
  font-weight: 700;
  align-self: center;
  color: ${({ theme }) => theme.colors.weldonBlue50};
  max-width: 326px;
  text-align: center;
`;

const SC = {
  Container,
  Message
};

export default SC;
