import { useTranslator } from '@hooks/useTranslator';

const useViewModel = () => {
  const { t } = useTranslator();

  return {
    label: t('favorites.deleteFilterAlert'),
    dialogProps: {
      title: t('favorites.removeConfirmation'),
      noLabel: t('favorites.cancel'),
      yesLabel: t('favorites.continue')
    }
  };
};

export default useViewModel;
