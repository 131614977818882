import { ModalProvider } from '@angloeastern/react-library';

import Presentor from './Presentor';

const Avatar: React.FC = () => (
  <ModalProvider>
    <Presentor />
  </ModalProvider>
);

export default Avatar;
