import type { MouseEventHandler } from 'react';

import { useListCardMenu } from '../context';
import SC from '../style';

const MenuItem: React.FC<{
  label: string;
  handleClick: MouseEventHandler;
  children: any;
}> = ({ label, children, handleClick }) => {
  const { setOpen } = useListCardMenu();

  return (
    <SC.MenuItem
      onClick={(e: any) => {
        handleClick(e);
        setOpen(false);
      }}
    >
      {children}
      {label}
    </SC.MenuItem>
  );
};

export default MenuItem;
