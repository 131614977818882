import type { BECategoryCodes } from '../../utils/BECategoryCodes';

export enum Actions {
  FETCH_DATA = 'fetch-data',
  SET_ERROR = 'set-error'
}

export interface KpiPermission {
  id?: number;
  name?: string;
  categoryId?: number;
  categoryName?: string;
  accessType: boolean;
  beCode: BECategoryCodes;
}

export interface State {
  permissions: KpiPermission[] | null;
  error: boolean;
}

export type Action =
  | { type: Actions.FETCH_DATA; payload: KpiPermission[] }
  | { type: Actions.SET_ERROR };
