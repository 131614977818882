import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import type {
  IAutoExceptionTelemetry,
  IEventTelemetry,
  ITelemetryItem
} from '@microsoft/applicationinsights-web';
import {
  ApplicationInsights,
  SeverityLevel
} from '@microsoft/applicationinsights-web';
import { createBrowserHistory } from 'history';

import { fetchConfig } from '@context/Config/api';
import { decode64 } from '@utils/encryption';

let _reactPlugin: ReactPlugin;
let _reactConfig: any = {};
let _appInsights: ApplicationInsights;
let _isInitialized = false;

/**
 * @param enableReactTracking enables tracking browser history
 */
const initializeAppInsights = async (enableReactTracking = false) => {
  try {
    const envConfigBase64 = await fetchConfig();
    const { APPLICATIONINSIGHTS_CONNECTION_STRING } = JSON.parse(
      decode64(envConfigBase64)
    );
    const {
      REACT_APP_APPLICATIONINSIGHTS_ROLENAME: APPLICATIONINSIGHTS_ROLENAME
    } = process.env;

    if (!APPLICATIONINSIGHTS_CONNECTION_STRING || !APPLICATIONINSIGHTS_ROLENAME)
      return false;

    const browserHistory = createBrowserHistory();
    _reactPlugin = new ReactPlugin();

    if (enableReactTracking) {
      _reactConfig = {
        extensions: [_reactPlugin],
        extensionConfig: {
          [_reactPlugin.identifier]: { history: browserHistory }
        }
      };
    }

    _appInsights = new ApplicationInsights({
      config: {
        connectionString: APPLICATIONINSIGHTS_CONNECTION_STRING,
        ..._reactConfig
      }
    });

    _appInsights.loadAppInsights();

    _appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => {
      if (!envelope) return;
      if (!envelope.tags) envelope.tags = [];
      envelope.tags['ai.cloud.role'] = APPLICATIONINSIGHTS_ROLENAME;
    });

    _isInitialized = true;
    // eslint-disable-next-line no-empty
  } catch {}
};

/**
 * @returns returns Application insights instance, Initializes if does not exist
 */
export const getAppInsights = async (): Promise<ApplicationInsights> => {
  if (!_isInitialized) await initializeAppInsights();
  return _appInsights;
};

export const trackEvent = async (event: IEventTelemetry) => {
  const insights = await getAppInsights();
  if (insights) insights.trackEvent(event);
};

/**
 * @param error details of exception
 */
export const trackException = async (error?: IAutoExceptionTelemetry) => {
  const insights = await getAppInsights();
  if (insights)
    insights.trackException({
      exception: error,
      severityLevel: SeverityLevel.Error
    });
};
