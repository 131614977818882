import { useEffect, useState } from 'react';

import { useProfile } from '@context/Profile';
import { useTranslator } from '@hooks/useTranslator';
import { useProfileTab } from '../context';
import Decorator from './Decorator';

const options = [
  {
    id: 'en',
    name: 'English (Default)'
  },
  {
    id: 'zh-hk',
    name: 'Chinese (中文)'
  },
  {
    id: 'ja',
    name: 'Japanese (日語)'
  }
];

const useViewModel = () => {
  const {
    state: {
      user: { profile },
      showForm
    }
  } = useProfile();
  const { t } = useTranslator();
  const { setDirty, setLocale } = useProfileTab();
  const [selected, setSelected] = useState(options[0].id);

  const handleSelect = (lang: string) => {
    const isDirty = lang !== profile.profileData?.language;
    setDirty(isDirty);
    if (isDirty) {
      setLocale(lang);
    }
  };

  useEffect(() => {
    if (profile && profile.language !== selected) {
      setSelected(profile.language);
    }
  }, [profile, selected]);

  return {
    label: t('myProfile.language'),
    show: showForm,
    selected,
    options,
    Decorator,
    handleSelect
  };
};

export default useViewModel;
