import { Loaders } from '@angloeastern/react-library';
import SC from './style';

const AppSuspense: React.FC<{
  size?: number;
  color?: string;
  bgColor?: string;
}> = ({ size, color, bgColor }) => (
  <SC.Container $bgColor={bgColor}>
    <Loaders.Ripples size={size} color={color} />
  </SC.Container>
);

export default AppSuspense;
