import { FlipInXH1 } from '@components/Animations/Heading';
import styled from 'styled-components';

const TopArea = styled.div`
  display: block;
  height: auto;

  @media only screen and (min-width: 768px) {
    display: flex;
  }
`;

const Body = styled.div.attrs({ id: 'view-body' })`
  height: 100%;

  div[aria-label='tab-content'] {
    background: ${({ theme }) => theme.colors.platinum};
    min-height: unset;
    height: 100%;
    overflow-y: auto;

    table {
      background: white;
      align-self: start;

      & + div {
        z-index: 10;
      }
    }
  }

  @media only screen and (min-width: 768px) {
    div[aria-label='tab-content'] {
      align-items: center;
      display: flex;
      flex-direction: column;
      overflow-y: hidden;

      > div[aria-label='fail-icon'] {
        background: white;
        min-height: 580px;
      }
    }
  }
`;

const TitleArea = styled.div`
  display: none;

  @media only screen and (min-width: 768px) {
    display: flex;
    flex-direction: column;
    flex: 1 0 60%;
  }
`;

const Widgets = styled.div`
  padding: 18px 20px;

  @media only screen and (min-width: 768px) {
    display: flex;
    flex: 0 0 40%;
    padding: 0;
    width: 474px;
    justify-content: end;
    align-items: center;
  }
`;

const Title = styled(FlipInXH1).attrs<{ $duration?: number }>(
  ({ $duration }) => ({
    duration: $duration || 0.75
  })
)`
  display: none;

  @media only screen and (min-width: 768px) {
    display: block;
    font-size: 22px;
    margin: 10px 0 15px 0;
  }
`;

const BreadCrumbs = styled.div`
  display: none;

  @media only screen and (min-width: 768px) {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 13px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.tiffanyBlue};
  }
`;

const ListContainer = styled.div`
  background: ${({ theme }) => theme.colors.platinum};
  height: calc(100vh - 210px);

  > div {
    overflow-y: auto;
    ${({ theme }) => theme.scrollbar}
  }

  @media only screen and (min-width: 768px) {
    height: auto;
    min-height: 580px;
    position: relative;

    > div:first-child {
      height: calc(100vh - 162px);

      table {
        background: white;

        thead {
          position: relative;

          tr {
            position: sticky;
            top: 0;
            background: ${({ theme }) => theme.colors.brightGray};
          }
        }
      }
    }
  }
`;

const SC = {
  Title,
  Body,
  TopArea,
  TitleArea,
  BreadCrumbs,
  Widgets,
  ListContainer
};

export default SC;
