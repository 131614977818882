import { faBars } from '@fortawesome/pro-light-svg-icons';
import { useNavigate } from 'react-router-dom';

import { useSidebar } from '@angloeastern/react-library';
import { useTranslator } from '@hooks/useTranslator';
import { useMemo } from 'react';

const useViewModel = () => {
  const {
    state: { activeItem },
    openSidebar
  } = useSidebar();
  const { t } = useTranslator();
  const navigate = useNavigate();

  const titleKey = useMemo(() => {
    if (activeItem) {
      const titleMap = {
        location: 'locationAndNavigation',
        accounts: 'financialOverview',
        resources: 'aeResources',
        drawings: 'vesselDrawings',
        kpi: 'kpis'
      } as any;

      const key =
        titleMap[activeItem.id] === undefined
          ? activeItem.id
          : titleMap[activeItem.id];

      return `module.${key}`;
    }
    return '';
  }, [activeItem]);

  return {
    icon: faBars,
    title: t(titleKey),
    showTitle: !!activeItem,
    openSidebar,
    onClick: () => navigate(`/whatsnew`)
  };
};

export default useViewModel;
