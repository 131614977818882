import { apiPost } from '@api/callAPI';

export const sendEmailReport = (
  driveId: string,
  categoryId: string,
  rootFolderName: string,
  name: string,
  vesselAeCode: string,
  recipientName: string,
  recipientEmail: string,
  ownerName: string,
  moduleName: string,
  signal?: AbortSignal
) =>
  apiPost(
    '/sp/sendemail',
    {
      driveId,
      categoryId,
      rootFolderName,
      name,
      vesselAeCode,
      recipientName,
      recipientEmail,
      ownerName,
      moduleName
    },
    false,
    { signal }
  );
