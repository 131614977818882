import { memoize } from '@angloeastern/react-library';
import currencyCodes from './currency.json';

interface CurrencyCodes {
  [key: string]: string;
}

export const isCurrencyCode = memoize((code?: string | null) => {
  return code && code in currencyCodes;
});

export const getCurrencyLocale = memoize((code?: string | null) => {
  return code === 'INR' ? 'en-IN' : 'en-US';
});

const getCurrencySymbol = memoize((code?: string | null) => {
  const codes: CurrencyCodes = currencyCodes;
  return code && code in currencyCodes ? codes[code] : codes['USD'];
});

export default getCurrencySymbol;
