import { useTheme } from 'styled-components';

import { Loaders } from '@angloeastern/react-library';
import SC from './style';

const Loader: React.FC = () => {
  const {
    colors: { antiFlashWhite }
  } = useTheme();

  return (
    <SC.Loader>
      <Loaders.Ripples color={antiFlashWhite} />
    </SC.Loader>
  );
};

export default Loader;
