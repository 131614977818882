import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const SC = {
  Container
};

export default SC;
