import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import {
  download as doDownload,
  useFileViewer,
  usePdfViewer
} from '@angloeastern/react-library';
import { fetchUrl } from '@api/Common';
import { urlCheck } from '@api/callAPI';
import { FileTypes } from '@components/index';
import { useConfig } from '@context/Config';
import useDownload from '@hooks/useDownload';
import useFileTypes from '@hooks/useFileTypes';

const initState = {
  checked: false,
  valid: false
};

const Context = createContext({});

export const Provider: React.FC<{
  checkUrl?: boolean;
  showFilename?: boolean;
  showCrossmark?: boolean;
  showDownload?: boolean;
  showFullscreenButton?: boolean;
  showIframeToolbar?: boolean;
  children: any;
  fullscreen: boolean;
}> = ({
  checkUrl,
  showFilename,
  showCrossmark,
  showDownload,
  showFullscreenButton,
  showIframeToolbar,
  children,
  fullscreen
}) => {
  const {
    state: { show, selected, source },
    closeViewer,
    select
  } = useFileViewer();
  const { fileDownload } = useDownload();
  const { canPreview, getExtension, getFileTypeFromMime, isVideo } =
    useFileTypes();
  const { setSelected } = usePdfViewer();
  const {
    deviceCheck: { isAboveTablet }
  } = useConfig();
  const [loading, setLoading] = useState(true);
  const [downloading, setDownloading] = useState(false);
  const [numPages, setNumPages] = useState<number>(0);
  const [validCache, setValidCahe] = useState<Array<string>>([]);
  const [linkState, setLinkState] = useState(initState);
  const [fileType, setFileType] = useState('');
  const [isFullScreen, setIsFullScreen] = useState(fullscreen);
  const controller = new AbortController();
  const signal = controller.signal;

  const toggleFullScreen = () => setIsFullScreen(!isFullScreen);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setLoading(false);
    setNumPages(numPages);
  }

  const handleLoad = () => {
    setLoading(false);
  };

  const isVerified = () => {
    if (!validCache.length) return false;
    let verified = selected && validCache.indexOf(selected.url) >= 0;
    if (selected && selected.dlUrl && selected.url !== selected.dlUrl) {
      verified = verified && validCache.indexOf(selected.dlUrl) >= 0;
    }
    return verified;
  };

  const addToValidCache = () => {
    let cache = [...validCache];
    if (selected) {
      cache = [...cache, selected.url];
      if (selected.dlUrl && selected.dlUrl !== selected.url) {
        cache = [...cache, selected.dlUrl];
      }
      setValidCahe(cache);
    }
  };

  const isFileVideo = useMemo(() => {
    selected && selected.name ? isVideo(getExtension(selected.name)) : false;
  }, [selected]);

  const onDownloadClick = async () => {
    if (selected) {
      setDownloading(true);

      const dlUrl = `${selected.dlUrl}?download=file`;
      if (
        dlUrl.match('angloeasterngroup.sharepoint.com') ||
        dlUrl.match('api/certificates')
      ) {
        doDownload(dlUrl);
      } else {
        const fileData = await fetchUrl(dlUrl, signal);
        if (fileData) {
          let fileContent = '';
          let fileType = '';
          if (
            typeof fileData === 'object' &&
            fileData.fileBase64 &&
            fileData.fileType
          ) {
            fileContent = fileData.fileBase64;
            fileType = fileData.fileType;
          } else {
            fileContent = fileData;
            fileType =
              selected.dlUrl?.split('/').pop() === 'pdf'
                ? 'application/pdf'
                : 'image/jpeg';
          }
          fileDownload(document, fileContent, selected.name, fileType);
        }
      }

      setTimeout(() => setDownloading(false), 1000);
    }
  };

  useEffect(() => {
    async function doCheckUrl() {
      if (selected) {
        const resp = await urlCheck(selected.url);
        const ok = resp !== null;
        if (ok && selected.type === '') {
          const type = getFileTypeFromMime(resp.headers['content-type']);
          setFileType(type);
        } else {
          setFileType(selected.type);
        }

        setLinkState({
          valid: ok,
          checked: true
        });
        if (ok) {
          addToValidCache();
        } else {
          setLoading(false);
        }
      }
    }

    setLoading(true);
    const allGood = {
      valid: true,
      checked: true
    };

    if (checkUrl) {
      if (selected && selected.url) {
        if (!isVerified()) {
          doCheckUrl();
        } else {
          setLinkState(allGood);
        }
      }
    } else if (selected && selected.url) {
      setLinkState(allGood);
      setFileType(selected.type);
      if (selected.type !== FileTypes.POWERPOINT) {
        setLoading(false);
      }
    }
  }, [selected, checkUrl]);

  useEffect(() => {
    if (!isAboveTablet && selected) {
      const { checked, valid } = linkState;
      if (checked && valid) {
        setSelected(selected);
      }
    }
  }, [isAboveTablet, selected, linkState]);

  useEffect(() => {
    return () => {
      controller.abort();
      setLinkState(initState);
    };
  }, []);

  const { checked, valid } = linkState;

  return (
    <Context.Provider
      value={{
        show,
        showTop:
          showFilename || showDownload || showCrossmark || showFullscreenButton,
        showFileIcon: !loading && showFilename && checked && valid,
        showDowloadButton: !loading && showDownload && valid && checked,
        showIframeToolbar,
        showFullscreenButton,
        fileType,
        selected,
        source,
        downloading,
        closeViewer: () => {
          setIsFullScreen(false);
          closeViewer();
        },
        checked,
        valid,
        canPreview: canPreview(fileType),
        loading,
        numPages,
        isImage: fileType === FileTypes.IMAGE,
        isVideo: isFileVideo,
        isFullScreen,
        showCrossmark,
        onDownloadClick,
        handleLoad,
        onDocumentLoadSuccess,
        select,
        toggleFullScreen
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useViewFile = () => useContext<any>(Context);
