import SC from './style';
import useViewModel from './useViewModel';

const Avatar: React.FC = () => {
  const { imgSrc, initial } = useViewModel();

  if (imgSrc) {
    return <SC.Avatar src={imgSrc} alt="avatar" />;
  } else {
    return <SC.Initial aria-label="avatar">{initial}</SC.Initial>;
  }
};

export default Avatar;
