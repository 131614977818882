import type { Dispatch } from 'react';

import type { Action, Crumbs, Title } from './types';
import { Actions } from './types';

export const setCrumbs = (dispatch: Dispatch<Action>) => (crumbs: Crumbs) => {
  dispatch({ type: Actions.SET_CRUMBS, payload: crumbs });
};

export const setTitle = (dispatch: Dispatch<Action>) => (title: Title) => {
  dispatch({ type: Actions.SET_TITLE, payload: title });
};
