import { lazy, useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useSidebar } from '@angloeastern/react-library';
import BlueWorldMapLoader from '@components/Loading/BlueWorldMapLoader';
import { pages as permits, useAuthUser } from '@context/AuthUser';
import { useTranslator } from '@hooks/useTranslator';
import { pages } from './pages';

const NotFound = lazy(() => import('@routes/NotFound'));

const REDIRECT_PATTERN = /^((ja|zh-hk)\/)?([^/?]+)(\/[^?]+)?(\?.+)?$/; ///^((ja|zh-hk)\/)?([^/]+)(\/(.+))?$/;

const useViewModel = () => {
  const navigate = useNavigate();
  const authUser = useAuthUser();
  console.log('authUser', authUser);
  const { permissions, hasPermission, landingPage } = authUser;
  const {
    state: { menu },
    setActiveItem,
    getMenuItem
  } = useSidebar();
  const {
    i18n: { changeLanguage }
  } = useTranslator();
  const redirectPage = sessionStorage.getItem('redirect');

  const shouldRedirect = useCallback(
    (redirectPage: string) => {
      if (permissions && permits) {
        const match = redirectPage.match(REDIRECT_PATTERN);
        const pageId = match ? match[3] : redirectPage;
        const permit = permits.find((p) => p.id === pageId);
        return permit && hasPermission(permit.permit);
      }
      return false;
    },
    [permissions, permits]
  );

  const theLandingPage = useMemo(() => {
    if (permissions) {
      let pgId: string;
      if (redirectPage) {
        const [redirectPageId] = redirectPage.split('?');
        pgId = redirectPageId;
      } else if (landingPage) {
        pgId = landingPage.id;
      }
      const lp = pages.find((p: any) => p.id === pgId);
      return lp || null;
    }
    return undefined;
  }, [landingPage, permissions]);

  useEffect(() => {
    if (menu && landingPage) {
      const menuItem = getMenuItem(landingPage.id);
      menuItem && setActiveItem(menuItem);
    }
  }, [menu, landingPage]);

  if (redirectPage && shouldRedirect(redirectPage)) {
    const match = redirectPage.match(REDIRECT_PATTERN);
    if (match && match[2]) {
      changeLanguage(match[2]);
    }
    sessionStorage.removeItem('redirect');
    setTimeout(() => {
      navigate(`/${redirectPage}`);
    }, 600);
    return BlueWorldMapLoader;
  }

  return theLandingPage === undefined
    ? BlueWorldMapLoader
    : theLandingPage === null
      ? NotFound
      : theLandingPage.component;
};

export default useViewModel;
