import ProfileMenu from '../ProfileMenu';
import Avatar from './Avatar';
import MyProfile from './MyProfile';
import SC from './style';
import useViewModel from './useViewModel';

const Presentor: React.FC = () => {
  const { showMenu, handleClick } = useViewModel();
  return (
    <>
      <SC.Container onClick={handleClick}>
        <SC.AvatarBox>
          <Avatar />
        </SC.AvatarBox>
        {showMenu && (
          <SC.Menu>
            <ProfileMenu />
          </SC.Menu>
        )}
      </SC.Container>
      <MyProfile />
    </>
  );
};

export default Presentor;
