import { useAuth } from 'react-oidc-context';

import { useProfile } from '@context/Profile';
import { useTranslator } from '@hooks/useTranslator';

const useViewModel = () => {
  const auth = useAuth();
  const { t } = useTranslator('common', 'action.profile');
  const { closeMenu, openForm } = useProfile();
  const { REACT_APP_OIDC_AUTHORITY: authority } = process.env;

  const handleClick = () => {
    openForm();
    closeMenu();
  };

  const handleClickLogout = () => {
    let accessToken = localStorage.getItem(`oidc.user:${authority}:spaclient`);
    accessToken =
      accessToken === null ? undefined : JSON.parse(accessToken).id_token;
    auth.removeUser();
    auth.signoutRedirect({ id_token_hint: `${accessToken}` });
    localStorage.clear();
  };

  return {
    labels: {
      myProfile: t('myProfile'),
      logout: t('logout')
    },
    handleClick,
    handleClickLogout
  };
};

export default useViewModel;
