import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  flex-direction: column-reverse;
  padding: 30px 16px;
  border-top: 0.5px dotted ${({ theme }) => theme.colors.philippineGray};

  @media only screen and (min-width: 768px) {
    padding: 3px 38px 42px;
    flex-direction: row;
    gap: 46px;
    border: none;
  }
`;

const SC = {
  Container
};

export default SC;
