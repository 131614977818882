import styled from 'styled-components';

const ListItem = styled.li`
  list-style: none;
  margin-bottom: 0;
  border-top: 1px solid ${({ theme }) => theme.colors.brightGrayEC};
  padding: 7px 12px;
  font-size: 13px;
  line-height: 23px;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active,
  &.selected {
    background: rgba(0, 178, 186, 0.25);
    outline: none;
    mix-blend-mode: multiply;
  }
`;

const SC = {
  ListItem
};

export default SC;
