import { Tooltip } from '@angloeastern/react-library';
import SC from './style';
import useViewModel from './useViewModel';

const UnloadButton: React.FC = () => {
  const { tooltip, icon, onClick } = useViewModel();

  return (
    <Tooltip direction="left" content={tooltip}>
      <SC.Button onClick={onClick}>
        <SC.Icon icon={icon} />
      </SC.Button>
    </Tooltip>
  );
};

export default UnloadButton;
