import AlertIcon from './AlertIcon';
import MessageList from './MessageList';
import { Provider } from './context';

const SystemNotifications: React.FC = () => (
  <Provider>
    <AlertIcon />
    <MessageList />
  </Provider>
);

export default SystemNotifications;
