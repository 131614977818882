import { useEffect, useMemo, useState } from 'react';

import { useAuthUser } from '@context/AuthUser';
import { useFavorite } from '@context/Favorite';
import { useVessels } from '@context/Vessels';
import type { Vessel } from '@context/Vessels/types';
import { useTranslator } from '@hooks/useTranslator';

const useViewModel = () => {
  const { t } = useTranslator();
  const {
    state: { activeItem, selected },
    setActiveItem,
    clearSelection
  } = useFavorite();
  const { roleName, vesselCodes } = useAuthUser();
  const {
    state: { allVessels }
  } = useVessels();
  const [options, setOptions] = useState<Array<Vessel>>([]);

  const sortOptions = (key: string) => (a: any, b: any) => {
    if (a[key]?.trim().toLowerCase() < b[key]?.trim().toLowerCase()) return -1;
    else if (a[key]?.trim().toLowerCase() > b[key]?.trim().toLowerCase())
      return 1;
    return 0;
  };

  useEffect(() => {
    if (Array.isArray(allVessels)) {
      const userVessels =
        roleName !== 'AE Admin' && allVessels
          ? allVessels?.filter(
              (v: Vessel) => vesselCodes.indexOf(v.aeCode) >= 0
            )
          : allVessels || [];

      const activeVessels = userVessels
        .filter((x: Vessel) => x.handoverDate === null)
        .sort(sortOptions('name'));

      const oomVessels = userVessels
        .filter((x: Vessel) => x.handoverDate !== null)
        .sort(sortOptions('name'));

      setOptions([...activeVessels, ...oomVessels]);
    } else {
      setOptions([]);
    }
  }, [allVessels, roleName, vesselCodes]);

  useEffect(() => {
    if (selected && activeItem) {
      if (selected.vesselAeCodes.length && !activeItem.vesselAeCodes.length) {
        setActiveItem(selected);
      }
    }
  }, [activeItem, selected]);

  const onChange = (items: Vessel[]) => {
    setActiveItem({
      ...activeItem,
      vesselAeCodes: items?.map((x) => x.aeCode) || []
    });
  };

  const multiSelected = useMemo(() => {
    return (
      (activeItem &&
        options &&
        options?.filter(
          (x: Vessel) => activeItem?.vesselAeCodes?.indexOf(x.aeCode) !== -1
        )) ||
      []
    );
  }, [activeItem]);

  return {
    label: t('favorites.filterVesselsBy'),
    prompt: t('favorites.selectVessels'),
    multiSelected,
    options,
    onChange,
    onClear: clearSelection
  };
};

export default useViewModel;
