import type { Favorite } from '@context/Favorite/types';

export interface Vessel {
  id?: number | null;
  imo: string;
  name: string;
  aeCode: string;
  vesselType: string;
  flag: string;
  organization: string;
  organizationAeCode: string;
  fosVesselId?: string | null;
  active: boolean;
  sortKey?: string | null;
  handoverDate?: string | null;
  selected?: boolean | null;
  currencySymbol?: string | null;
  currency?: string | null;
  locale?: string | null;
}

export interface IVesselCurrency {
  vesselAeCode: string;
  currency: string | null;
  currencySymbol: string | null;
  numberFormat: string | null;
}

export const currencySymbols: { [index: string]: string } = {
  USD: '$',
  EUR: '€',
  CAD: 'CA$',
  INR: '₹',
  YUAN: '¥',
  YEN: '¥',
  DEFAULT: '$'
};

export const currencyFormats = {
  INR: '##,##,##,##,###',
  DEFAULT: '###,###,###,###'
};

export type Vessels = Array<Vessel>;

export enum Actions {
  FETCH_VESSELS = 'fetch-vessels',
  SET_VESSELS = 'set-vessels',
  FETCH_CURRENCIES = 'fetch-currencies',
  SET_CURRENCIES = 'set-currencies',
  RESET_DATA = 'reset-data',
  SET_VESSEL = 'set-vessel',
  SET_ERROR = 'set-error',
  SET_FAVORITE_FILTER = 'set-favorite-filter',
  REMOVE_FAVORITE_FILTER = 'remove-favorite-filter'
}

export interface State {
  favorite: Favorite | null;
  allVessels: Vessels | null;
  vessels: Vessels | null;
  vesselCurrencies: IVesselCurrency[] | null;
  vessel: Vessel | null;
  error: boolean;
}

export type Action =
  | { type: Actions.FETCH_VESSELS; payload: Vessels }
  | { type: Actions.SET_VESSELS; payload: Vessels }
  | { type: Actions.FETCH_CURRENCIES; payload: IVesselCurrency[] }
  | { type: Actions.SET_VESSEL; payload: Vessel | null }
  | { type: Actions.RESET_DATA }
  | { type: Actions.SET_FAVORITE_FILTER; payload: Favorite }
  | { type: Actions.REMOVE_FAVORITE_FILTER }
  | { type: Actions.SET_ERROR };
