const useImgUtils = () => {
  const toBase64 = (file: any): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  /** removes begining of base64 */
  const removePrefix = (data: string) => {
    const splitted = data?.split(',', 2);
    if (splitted && splitted.length > 1) return splitted[1];
    return '';
  };

  /** add data attribute to base64 string */
  const addPrefix = (data: string | undefined | null) =>
    data ? `data:image/jpeg;base64,${data}` : '';

  return { toBase64, removePrefix, addPrefix };
};

export default useImgUtils;
