import { Loaders } from '@angloeastern/react-library';
import { useFavorite } from '@context/Favorite';
import SC from './style';

const Loader: React.FC = () => {
  const {
    state: { isLoading }
  } = useFavorite();

  return isLoading ? (
    <SC.LoadersLayInner>
      <SC.LoadersLayContent>
        <Loaders.Ellipsis />
      </SC.LoadersLayContent>
    </SC.LoadersLayInner>
  ) : null;
};

export default Loader;
