import SharePointDocList from '../SharePointDocList';
import useSetCrumbs from './useSetCrumbs';

export interface Props {
  title: string | null;
  moduleName?: string;
  crumbs: Array<string> | null;
  gutterSize?: number;
}

const Presentor: React.FC<Props> = ({
  title,
  moduleName,
  crumbs,
  gutterSize = 227
}) => {
  useSetCrumbs(title, crumbs);

  return (
    <SharePointDocList
      gutterSize={gutterSize}
      moduleName={moduleName || 'Client Documents'}
    />
  );
};

export default Presentor;
