import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const IconBox = styled.div`
  padding: 30px 0;
`;

const SC = {
  Container,
  IconBox
};

export default SC;
