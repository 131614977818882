import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

export const avatarEditorStyle = {
  width: '250px',
  height: '250px',
  borderRadius: '50%',
  border: '1px solid #00b2ba'
};

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  font-family: sans-serif;
  font-style: normal;
  border-radius: 5px;
  background-color: white;
`;

const Inner = styled.div`
  padding: 30px;
  background: white;
  width: 100%;
  z-index: 1;
  align-self: center;
`;

const Avatar = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

const Heading = styled.div`
  font-family: 'Franklin Gothic Book',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 25px;
  color: ${({ theme }) => theme.colors.tiffanyBlue};
  padding 25px 30px;
`;

const Icon = styled(FontAwesomeIcon)`
  margin-left: 32px;
  position: absolute;
  width: 24px;
  height: 24px;
`;

const Wrapper = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
`;

const Range = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 6px;
  border-radius: 5px;
  background: ${({ theme }) => theme.colors.lightGray};
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;
  margin: 19px 10px;
  width: 500px;

  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: 1px solid #00b2ba;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background: white;
    cursor: pointer;
    margin-top: 0;
  }

  ::-moz-range-thumb {
    border: 1px solid #000;
    height: 36px;
    width: 16px;
    border-radius: 3px;
    background: white;
    cursor: pointer;
  }
`;

const Increase = styled.button`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.formBorder};
  border: none;
  background: none;
  font-size: 50px;
`;

const Decrease = styled.button`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.formBorder};
  border: none;
  background: none;
  font-size: 50px;
  margin-top: -6px;
`;

const ButtonsRow = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  flex-direction: column-reverse;
  padding: 30px 16px;
  border-top: 0.5px dotted ${({ theme }) => theme.colors.philippineGray};

  @media only screen and (min-width: 768px) {
    padding: 24px 38px 42px;
    flex-direction: row;
    gap: 46px;
    border: none;
  }
`;

const SC = {
  Container,
  Inner,
  Heading,
  Icon,
  Avatar,
  ButtonsRow,
  Wrapper,
  Range,
  Increase,
  Decrease
};

export default SC;
