import { Features, Layout, Page } from '@components/index';
import { PageID } from '@context/PageID/types';
import Navigation from './Navigation';

const Location: React.FC = () => (
  <Page pageID={PageID.LOCATION} permit={Features.LOCATION_NAVIGATION}>
    <Layout>
      <Navigation />
    </Layout>
  </Page>
);

export default Location;
