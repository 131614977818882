import type { Dispatch } from 'react';

import type { Action, MapPosition } from './types';
import { Actions } from './types';

export const setZoomLevel =
  (dispatch: Dispatch<Action>) => (zoomLevel: number) => {
    dispatch({ type: Actions.SET_ZOOM_LEVEL, payload: zoomLevel });
  };

export const setFullScreen =
  (dispatch: Dispatch<Action>) => (expand: boolean) => {
    dispatch({ type: Actions.SET_FULLSCREEN, payload: expand });
  };

export const setCenter =
  (dispatch: Dispatch<Action>) => (point: MapPosition) => {
    dispatch({ type: Actions.SET_CENTER, payload: point });
  };

export const showLabels = (dispatch: Dispatch<Action>) => (): void => {
  dispatch({ type: Actions.SHOW_LABELS });
};

export const hideLabels = (dispatch: Dispatch<Action>) => (): void => {
  dispatch({ type: Actions.HIDE_LABELS });
};
