import { Fragment, lazy } from 'react';
import { Route } from 'react-router-dom';

import Home from '@routes/Home';
import Location from '@routes/Location';
import { getSupportedLanguages } from '@utils/index';
import Providers from './Providers';
import { SetupPDFJS } from './SetupPDFjs';

const Accounts = lazy(() => import('@routes/Accounts'));
const Certificates = lazy(() => import('@routes/Certificates'));
const Crew = lazy(() => import('@routes/Crew'));
const Documents = lazy(() => import('@routes/Documents'));
const Drawings = lazy(() => import('@routes/Drawings'));
const ErrorPage = lazy(() => import('@routes/ErrorPage'));
const Kpis = lazy(() => import('@routes/Kpis'));
const Login = lazy(() => import('@routes/Login'));
const NotFound = lazy(() => import('@routes/NotFound'));
const Oidc = lazy(() => import('@routes/Oidc'));
const OpexSupportings = lazy(() => import('@routes/OpexSupportings'));
const OurExperts = lazy(() => import('@routes/OurExperts'));
const Reports = lazy(() => import('@routes/Reports'));
const SSOPage = lazy(() => import('@routes/SSOPage'));
const WhatsNew = lazy(() => import('@routes/WhatsNew'));
const WorldMap = lazy(() => import('@routes/WorldMap'));

SetupPDFJS();

const RestrictedArea: React.FC = () => {
  return (
    <Providers>
      <Route path="/authSuccess" element={<Oidc />} />
      <Route path="/usercallback" element={<Oidc />} />
      <Route path="/sso" element={<SSOPage />} />
      <Route path="/silent-renew" element={<div>Silent Renew</div>} />
      {getSupportedLanguages().map((locale: string) => {
        const lang = locale === 'en' ? '' : `/${locale}`;
        return (
          <Fragment key={locale}>
            <Route
              path={`/${locale === 'en' ? '' : locale}`}
              element={<Home />}
            />
            <Route path={`${lang}/login`} element={<Login />} />
            <Route path={`${lang}/sso`} element={<SSOPage />} />
            <Route path={`${lang}/location`} element={<Location />} />
            <Route path={`${lang}/worldmap`} element={<WorldMap />} />
            <Route path={`${lang}/ourexperts`} element={<OurExperts />} />
            <Route
              path={`${lang}/opex-supporting/:vesselAeCode/:transactionId`}
              element={<OpexSupportings />}
            />
            <Route path={`${lang}/whatsnew`} element={<WhatsNew />} />
            <Route path={`${lang}/crew`} element={<Crew />} />
            <Route path={`${lang}/documents`} element={<Documents />} />
            <Route path={`${lang}/accounts`} element={<Accounts />} />
            <Route path={`${lang}/certificates`} element={<Certificates />} />
            <Route path={`${lang}/reports`} element={<Reports />} />
            <Route path={`${lang}/drawings`} element={<Drawings />} />
            <Route path={`${lang}/kpi`} element={<Kpis />} />
            <Route path={`${lang}/error`} element={<ErrorPage />} />
            <Route path={`${lang}/*`} element={<NotFound />} />
          </Fragment>
        );
      })}
    </Providers>
  );
};

export default RestrictedArea;
