import { useContext } from 'react';

import createDataContext from '../createDataContext';
import { hideMessageBox, showMessageBox } from './actions';
import reducer from './reducer';
import type { State } from './types';

const initialState: State = {
  show: false,
  title: null,
  content: null
};

export const { Context, Provider } = createDataContext(
  reducer,
  {
    showMessageBox,
    hideMessageBox
  },
  initialState
);

export const useMessageBox = () => useContext<any>(Context);
