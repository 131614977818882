import { camelize } from '@angloeastern/react-library';
import { useTranslator } from '@hooks/useTranslator';
import type { NullableKPI } from '../types';

const useViewModel = (kpi: NullableKPI) => {
  const { t } = useTranslator('kpis');

  if (!kpi || !kpi.footerLabel || !kpi.footerValue) {
    return null;
  }

  const { footerLabel, footerValue } = kpi;

  const subValueKey = `kpis:kpi.${camelize(footerLabel)}`;

  const theFigure = isNaN(footerValue as any)
    ? footerValue
    : parseFloat(footerValue).toLocaleString('en');

  return t(subValueKey, { figure: theFigure });
};

export default useViewModel;
