import styled from 'styled-components';

const Decorator = styled.div.attrs({ role: 'button' })<{ $isOpen: boolean }>`
  position: relative;
  display: flex;
  padding: 6px 10px;
  font-size: 13px;
  font-weight: normal;
  line-height: 24px;
  min-height: 26px;
  box-shadow: 0 7px 20px rgba(0,0,0,0.07);
  border 1px solid #ececec;
  border-radius: 6px;
  cursor: pointer;

  &:after {
    position: absolute;
    display: inline-flex;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font: var(--fa-font-solid);
    right: 12px;
    align-self: center;
    content: '\\f0d7';
    transition: all 0.4s;
    ${({ $isOpen }) => $isOpen && `transform :rotate(180deg);`}
  }
`;

const SC = {
  Decorator
};

export default SC;
