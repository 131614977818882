import type { Action, State } from './types';
import { Actions } from './types';

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case Actions.FETCH_DATA:
      return {
        ...state,
        permissions: action.payload,
        error: false
      };
    case Actions.SET_ERROR:
      return {
        ...state,
        error: true
      };
    default:
      return state;
  }
};

export default reducer;
