import { useCallback } from 'react';

import { sendEmailReport } from '@components/SharePointDocList/TableRow/api';
import { useAuthUser } from '@context/AuthUser';
import { useOrganizations } from '@context/Organizations';
import { PageID, usePageID } from '@context/PageID';
import { useSpDriveCtx } from '@context/Sharepoint/DriveCtx';
import type { SharePointfile } from '@context/Sharepoint/types';
import { useVessels } from '@context/Vessels';
import { useSharepointDocList } from '../context';

const useSendReportEmail = (data: SharePointfile) => {
  const {
    state: { driveId }
  } = useSpDriveCtx();
  const { profile } = useAuthUser();
  const {
    state: { organization }
  } = useOrganizations();
  const {
    state: { vessel }
  } = useVessels();
  const { pageID } = usePageID();
  const { moduleName } = useSharepointDocList();

  const sendEmail = useCallback(
    async (signal?: AbortSignal) => {
      try {
        if (driveId && driveId !== '' && data && data.id && data.id !== '') {
          if (pageID === PageID.DOCUMENTS) {
            await sendEmailReport(
              driveId,
              data.id,
              data.name,
              organization.name,
              '',
              profile.fullName,
              profile.email,
              '',
              'Client Documents',
              signal
            );
          } else {
            await sendEmailReport(
              driveId,
              data.id,
              data.name,
              vessel?.name || '',
              vessel?.aeCode || '',
              profile.fullName,
              profile.email,
              vessel.organization,
              moduleName || 'Client Documents',
              signal
            );
          }
          return true;
        } else {
          return false;
        }
      } catch (error) {
        return false;
      }
    },
    [moduleName, driveId, pageID, profile, vessel, organization]
  );

  return { sendEmail };
};

export default useSendReportEmail;
