import { Toast } from '@angloeastern/react-library';
import SC from './style';
import useViewModel from './useViewModel';

const ToastMessage: React.FC = () => {
  const { show, btnLabel, status, toast, icons, onClose } = useViewModel();

  return (
    show && (
      <Toast onClose={onClose} btnLabel={btnLabel}>
        <SC.ThumbFlex>
          {status ? (
            <SC.ThumbsUp icon={icons.success} />
          ) : (
            <SC.ThumbsDown icon={icons.failure} />
          )}
        </SC.ThumbFlex>
        <SC.ToastMsg>{toast}</SC.ToastMsg>
      </Toast>
    )
  );
};

export default ToastMessage;
