import { useSearchableView } from '@context/SearchableView';
import View from '../View';
import SearchBar from './SearchBar';

export interface Props {
  live?: boolean;
  children: any;
}

const Presentor: React.FC<Props> = ({ live = true, children }) => {
  const { show } = useSearchableView();

  const Widget = () => (show ? <SearchBar live={live} /> : null);

  return <View widget={Widget}>{children}</View>;
};

export default Presentor;
