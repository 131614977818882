import { Trans } from 'react-i18next';

import SC from './style';
import useViewModel from './useViewModel';

const ErrorPage: React.FC<{
  title: string;
  messageKey: string;
  imageUri: string;
}> = ({ title, messageKey, imageUri }) => {
  const { mailTo, icons, emailToSupport, goToHomepage, goToHome } =
    useViewModel();

  return (
    <SC.Container data-testid="error-page-container">
      <SC.TopRow data-testid="error-page-top-row">
        <SC.Title>{title}</SC.Title>
        <SC.Message>
          <Trans i18nKey={messageKey} components={{ par: <p /> }} />
        </SC.Message>
      </SC.TopRow>
      <SC.BottomRow data-testid="error-page-bottom-row">
        <SC.BottomLeft>
          <SC.CtaGroup>
            <SC.AnchorBox href={mailTo} data-testid="error-page-anchor-box">
              <SC.SupportIcon>
                <SC.HeadsetIcon icon={icons.headset} />
                <SC.EnvelopeIcon icon={icons.envelope} />
              </SC.SupportIcon>
              <SC.CtaText>{emailToSupport}</SC.CtaText>
            </SC.AnchorBox>
            <SC.CtaBox onClick={goToHome} data-testid="error-page-cta-box">
              <SC.HomeIcon>
                <SC.HouseIcon icon={icons.house} />
                <SC.CircleIcon icon={icons.circle} />
              </SC.HomeIcon>
              <SC.CtaText>{goToHomepage}</SC.CtaText>
            </SC.CtaBox>
          </SC.CtaGroup>
        </SC.BottomLeft>
        <SC.BottomCenter $imageUri={imageUri} />
        <SC.BottomRight>&nbsp;</SC.BottomRight>
      </SC.BottomRow>
    </SC.Container>
  );
};

export default ErrorPage;
