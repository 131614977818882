import { useContext } from 'react';

import createDataContext from '@context/createDataContext';
import * as actions from './actions';
import reducer from './reducer';
import type { State } from './types';

const initialState: State = {
  zoomLevel: 1,
  labelsShown: false,
  fullScreen: false,
  center: { lat: 0, lng: 0 }
};

export const { Context, Provider } = createDataContext(
  reducer,
  { ...actions },
  initialState
);

export const useMap = () => useContext<any>(Context);
