import { useEffect, useState } from 'react';

import { useFavorite } from '@context/Favorite';
import type { Favorite } from '@context/Favorite/types';
import { useVessels } from '@context/Vessels';
import { useTranslator } from '@hooks/useTranslator';

const useViewModel = () => {
  const { t } = useTranslator();
  const [confirmDelete, setConfirmDelete] = useState(false);

  const {
    state: { favorites, selected },
    setDirty,
    unselect,
    setActiveItem,
    clearActiveItem,
    setLoading,
    showToast,
    removeFavorite,
    select,
    setIsFormOpen
  } = useFavorite();

  const { setFavorite, clearFavorite } = useVessels();

  const controller = new AbortController();
  const signal = controller.signal;

  const onSelect = (guid: string) => {
    if ((guid && !selected) || (selected && guid !== selected.guid)) {
      const fav = guid
        ? favorites.find((x: Favorite) => x.guid === guid)
        : null;
      setDirty(false);
      setFavorite(fav);
      setActiveItem(fav);
      select(fav);
      if (fav) setIsFormOpen(false);
    }
  };

  const onRemove = async () => {
    setConfirmDelete(true);
  };

  const confirmRemove = async () => {
    if (selected) {
      try {
        setLoading(true);
        await removeFavorite(selected, signal);
        showToast(t('favorites.success.delete'), true);
        setLoading(false);
        unselect();
        clearFavorite();
        clearActiveItem();
      } catch (error) {
        showToast(t('favorites.errors.delete'), false);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  return {
    ready: !(!favorites || !favorites.length),
    label: t('favorites.loadFavorite'),
    selected: selected ? selected.guid : null,
    favorites: favorites || [],
    showControls: !!selected,
    showConfirmRemove: confirmDelete,
    onSelect,
    onRemove,
    onCancel: () => setConfirmDelete(false),
    onContinue: async () => {
      await confirmRemove();
      setConfirmDelete(false);
    }
  };
};

export default useViewModel;
