import { lazy, Suspense } from 'react';

import BlueWorldMapLoader from '@components/Loading/BlueWorldMapLoader';
import { Provider as MapConfigProvider } from './Map/contexts/MapConfig';
import SC from './style';
import useViewModel from './useViewModel';

const Map = lazy(() => import('./Map'));
const SideInfo = lazy(() => import('./SideInfo'));

const Presentor: React.FC = () => {
  const { SideInfoWrap, standAlone } = useViewModel();

  return (
    <SC.Container>
      <Suspense fallback={<BlueWorldMapLoader />}>
        <MapConfigProvider>
          <Map />
        </MapConfigProvider>
        {!standAlone && (
          <SideInfoWrap>
            <SideInfo />
          </SideInfoWrap>
        )}
      </Suspense>
    </SC.Container>
  );
};

export default Presentor;
