import { faEye } from '@fortawesome/pro-light-svg-icons';

import { useTranslator } from '@hooks/useTranslator';

const useViewModel = () => {
  const { t } = useTranslator();

  return {
    label: t('action.fullView'),
    icon: faEye
  };
};

export default useViewModel;
