import { Fragment } from 'react';
import { useTheme } from 'styled-components';

import { SideSwipeBox } from '@angloeastern/react-library';
import { useConfig } from '@context/Config';
import { useMap } from './contexts';

const useViewModel = () => {
  const {
    deviceCheck: { isAboveMobile }
  } = useConfig();
  const {
    colors: { robinEggBlue, darkCerulean }
  } = useTheme();
  const { standAlone } = useMap();

  return {
    standAlone,
    suspenseProps: {
      color: robinEggBlue,
      bgColor: darkCerulean
    },
    SideInfoWrap: isAboveMobile ? Fragment : SideSwipeBox
  };
};

export default useViewModel;
