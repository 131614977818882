import { useFileViewer } from '@angloeastern/react-library';
import { createElement, useMemo } from 'react';
import { Page } from 'react-pdf';
import { useViewFile } from '../../context';

const useViewModel = () => {
  const { numPages, onDocumentLoadSuccess } = useViewFile();

  const {
    state: { selected }
  } = useFileViewer();

  const onDocumentLoadError = (error: any) =>
    console.error('Error while loading document! ' + error.message);

  const thePages = useMemo(() => {
    const pages = [];
    for (let i = 1; i <= numPages; i++) {
      pages.push(createElement(Page, { key: `page_${i}`, pageNumber: i }));
    }

    return pages;
  }, [numPages]);

  return {
    thePages,
    thePreviewUrl: selected?.url,
    onDocumentLoadError,
    onDocumentLoadSuccess
  };
};

export default useViewModel;
