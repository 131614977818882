import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const Icon = styled(FontAwesomeIcon)`
  color: white;
  font-size: 13px;
  display: flex;
  align-self: center;
`;

const Button = styled.button.attrs({ 'aria-label': 'deletebtn' })`
  outline: none;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.flamingo};
  border: none;
  padding: 8px;
  width: 28px;
  height: 28px;
  justify-content: center;
  display: inline-lex;
  box-shadow: 5px 5px 15px ${({ theme }) => theme.colors.black07};
  cursor: pointer;

  &:hover {
    background: red;
  }
`;

const SC = {
  Icon,
  Button
};

export default SC;
