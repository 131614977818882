import styled from 'styled-components';

const Container = styled.div<{ $bgColor?: string }>`
  display: flex;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  background: ${({ theme, $bgColor }) =>
    $bgColor ? $bgColor : theme.colors.platinum};
`;

const SC = {
  Container
};

export default SC;
