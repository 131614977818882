import { faCircleEllipsisVertical } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import type { File } from '@angloeastern/react-library';

import SC from './style';
import useViewModel from './useViewModel';

const FileSelection: React.FC = () => {
  const {
    source,
    showMenuBox,
    menuBoxRef,
    showSourceMenu,
    onShowMenuBox,
    changeSelected
  } = useViewModel();

  return (
    showMenuBox && (
      <SC.MenuBox ref={menuBoxRef}>
        <SC.ControlIcon disabled={false}>
          <FontAwesomeIcon
            icon={faCircleEllipsisVertical}
            onClick={onShowMenuBox}
          />
        </SC.ControlIcon>
        {showSourceMenu && (
          <SC.Menu>
            {source.map((s: File, idx: number) => {
              return (
                <SC.MenuItem key={idx} onClick={() => changeSelected(idx)}>
                  {s.name}
                </SC.MenuItem>
              );
            })}
          </SC.Menu>
        )}
      </SC.MenuBox>
    )
  );
};

export default FileSelection;
