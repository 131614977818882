import { CenterMessage, FailIcon } from '@angloeastern/react-library';
import { BounceIn, Flash } from '@components/Animations/Div';
import { ListLoader } from '@components/index';
import { useTranslator } from '@hooks/useTranslator';
import SC from '../style';

const NoDriveOrData: React.FC<{
  isReady: boolean;
  error?: boolean | null;
  gutterSize?: number;
}> = ({ isReady, error, gutterSize = 227 }) => {
  const { t } = useTranslator();

  return isReady ? (
    <SC.MessageBox $gutterSize={gutterSize}>
      <CenterMessage>
        <BounceIn>{t('text.emptyContent')}</BounceIn>
      </CenterMessage>
    </SC.MessageBox>
  ) : error ? (
    <SC.MessageBox $gutterSize={gutterSize}>
      <FailIcon>
        <Flash>{t('error.somethingWrong')}</Flash>
      </FailIcon>
    </SC.MessageBox>
  ) : (
    <ListLoader bgColor="white" cols={5} />
  );
};

export default NoDriveOrData;
