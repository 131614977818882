import type { Size } from '@components/Kpi/Item/style';
import Footer from './Footer';
import FooterExtension from './FooterExtension';
import Item from './Item';
import type { NullableKPI } from './types';
import useViewModel from './useViewModel';

export interface Props {
  title: string;
  tooltip?: string;
  kpi?: NullableKPI;
  disable?: boolean;
  showArrow?: boolean;
  showFooter?: boolean;
  showBody?: boolean;
  size?: Size;
  visible?: boolean;
  showZero?: boolean;
}

const Kpi: React.FC<Props> = ({
  title,
  tooltip,
  kpi,
  disable = false,
  showArrow = true,
  showFooter = true,
  showBody = true,
  size = 'default',
  visible = true,
  showZero = false
}) => {
  const { footer, footerExt, itemProps } = useViewModel(
    title,
    tooltip,
    kpi,
    disable,
    showArrow,
    showBody,
    size,
    showZero
  );

  if (visible !== true) return null;

  return (
    <Item {...itemProps}>
      {showFooter && (
        <>
          {footer && <Footer {...footer} />}
          {footerExt && <FooterExtension {...footerExt} />}
        </>
      )}
    </Item>
  );
};

export default Kpi;
