import BaseItem from '@components/Kpi/Item';
import type { Size } from '@components/Kpi/Item/style';
import SC from './style';

const Item: React.FC<{
  title: string;
  body: any;
  tooltip?: string;
  disable?: boolean;
  size?: Size;
  children?: any;
  showBody?: boolean;
  duration?: number;
}> = ({
  title,
  body,
  tooltip,
  size = 'default',
  disable = false,
  children,
  showBody = true,
  duration
}) => {
  return (
    <SC.Container $duration={duration}>
      <BaseItem
        size={size}
        title={title}
        body={body}
        tooltip={tooltip}
        showBody={showBody}
        disable={disable}
      >
        {children}
      </BaseItem>
    </SC.Container>
  );
};

export default Item;
