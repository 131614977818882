import { SecondaryButton } from '@angloeastern/react-library';
import SC from '../style';
import { useViewModel } from './useViewModel';

export const SSOEnabled: React.FC = () => {
  const { labels, isOffice365, handleCancel } = useViewModel();

  return isOffice365 ? (
    <SC.FormContainer>
      <SC.FormRow>
        <SC.HeaderLabel>{labels.SSOMessage}</SC.HeaderLabel>
      </SC.FormRow>
      <SC.ButtonsRow>
        <SecondaryButton onClick={handleCancel}>
          {labels.cancel}
        </SecondaryButton>
      </SC.ButtonsRow>
    </SC.FormContainer>
  ) : null;
};

export default SSOEnabled;
