export interface VesselLastTimeInfo {
  vesselAeCode: string;
  timestamp?: string | null;
  isOutDate: boolean;
}

export type VesselTimeStampMap = {
  [aeCode: string]: VesselLastTimeInfo;
};

export enum Actions {
  SET_LOOKUP = 'set-lookup',
  UPDATE_LOOKUP = 'update-lookup'
}

export type State = VesselTimeStampMap;

export type Action =
  | { type: Actions.SET_LOOKUP; payload: VesselTimeStampMap }
  | { type: Actions.UPDATE_LOOKUP; payload: VesselLastTimeInfo };
