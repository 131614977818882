import { Tooltip } from '@angloeastern/react-library';
import SC from './style';
import useViewModel from './useViewModel';

const SaveButton: React.FC = () => {
  const { ready, icon, tooltip, onSaveClick } = useViewModel();

  return (
    ready && (
      <Tooltip direction="top" content={tooltip}>
        <SC.Button onClick={onSaveClick}>
          <SC.Icon icon={icon} />
        </SC.Button>
      </Tooltip>
    )
  );
};

export default SaveButton;
