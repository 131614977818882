import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
`;

const Trigger = styled(FontAwesomeIcon)`
  font-size: 21px;
  color: ${({ theme }) => theme.colors.darkMidnightBlue};
`;

const Menu = styled.ul`
  display: block;
  position: absolute;
  top: 5px;
  right: 5px;
  margin: 0;
  list-style: none;
  background: white;
  box-shadow: 0px 2px 2px ${({ theme }) => theme.colors.black10};
  border-radius: 10px;
  padding-inline-start: 0;
`;

const MenuItem = styled.li`
  padding: 8px 12px;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.trout};

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightSmoke};
  }

  > svg,
  > div {
    margin-right: 8px;
  }
`;

const SC = {
  Trigger,
  Container,
  Menu,
  MenuItem
};

export default SC;
