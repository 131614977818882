import { useEffect, useRef } from 'react';

import { useDetectOutsideClick } from '@angloeastern/react-library';
import { useFavorite } from '@context/Favorite';

const useViewModel = () => {
  const ref = useRef(null);
  const {
    state: { isOpen, favorites, isLoading },
    fetchFavorites,
    setLoading,
    setIsFormOpen
  } = useFavorite();

  useDetectOutsideClick(ref, isOpen, () => {
    setIsFormOpen(false);
  });

  const controller = new AbortController();

  useEffect(() => {
    let active = true;
    const signal = controller.signal;

    async function doFetch() {
      setLoading(true);
      await fetchFavorites(signal);
      setLoading(false);
    }

    if (active && favorites === undefined && !isLoading) {
      doFetch();
    }

    return () => {
      active = false;
    };
  }, [favorites, isLoading]);

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);

  return {
    isOpen,
    ref
  };
};

export default useViewModel;
