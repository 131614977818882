import type { MouseEventHandler } from 'react';

import { Tooltip } from '@angloeastern/react-library';
import SC from './style';
import useViewModel from './useViewModel';

const DeleteButton: React.FC<{ onClick: MouseEventHandler }> = ({
  onClick
}) => {
  const { tooltip, icon } = useViewModel();

  return (
    <Tooltip direction="right" content={tooltip}>
      <SC.Button onClick={onClick}>
        <SC.Icon icon={icon} />
      </SC.Button>
    </Tooltip>
  );
};

export default DeleteButton;
