import SC from '../style';
import useViewModel from './useViewModel';

const NameRow: React.FC = () => {
  const { firstName, lastName } = useViewModel();

  return (
    <SC.FormRow>
      <SC.FormControl>
        <SC.Label>{firstName.label} </SC.Label>
        <SC.Input
          type="text"
          name="firstName"
          value={firstName.value}
          disabled
        />
      </SC.FormControl>
      <SC.FormControl>
        <SC.Label>{lastName.label} </SC.Label>
        <SC.Input type="text" name="lastName" value={lastName.value} disabled />
      </SC.FormControl>
    </SC.FormRow>
  );
};

export default NameRow;
