import { Systems } from '@components/index';
import { useAuthUser } from '@context/AuthUser';
import { useConfig } from '@context/Config';
import { PageID, usePageID } from '@context/PageID';

const useViewModel = () => {
  const { hasAccess } = useAuthUser();
  const { isPage } = usePageID();
  const { JIBE_URL, JIBE_URL_CREW } = useConfig();

  const url = isPage(PageID.CREW) ? JIBE_URL_CREW : JIBE_URL;

  return {
    url,
    hasAccess: hasAccess(Systems.JIBE)
  };
};

export default useViewModel;
