import { faDownload } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useFileViewer } from '@angloeastern/react-library';
import { useTranslator } from '@hooks/useTranslator';
import SC from './style';

const NoPreview: React.FC = () => {
  const { t } = useTranslator();
  const {
    state: { selected }
  } = useFileViewer();

  return (
    selected && (
      <SC.Container>
        <SC.Content>
          <FontAwesomeIcon icon={faDownload} />
          <SC.Filename>{selected.name}</SC.Filename>
          <SC.Message>{t('text.noPreview')}</SC.Message>
          <SC.Button href={selected.dlUrl} download>
            {t('action.download')}
          </SC.Button>
        </SC.Content>
      </SC.Container>
    )
  );
};

export default NoPreview;
