import { apiDelete, apiGet, apiPost, apiPut } from '@api/callAPI';
import type { Favorite } from '@context/Favorite/types';

export const fetchFavorites = (signal?: AbortSignal) =>
  apiGet(`/favorite`, false, { signal });

export const createFavorite = (favorite: Favorite, signal?: AbortSignal) =>
  apiPost(`/favorite`, favorite, false, { signal });

export const updateFavorite = (favorite: Favorite, signal?: AbortSignal) =>
  apiPut(`/favorite/${favorite.guid}`, favorite, false, { signal });

export const removeFavorite = (favorite: Favorite, signal?: AbortSignal) =>
  apiDelete(`/favorite/${favorite.guid}`, undefined, false, { signal });
