import styled from 'styled-components';

import ParentSC from '../style';

const RightButton = styled.div`
  margin-left: auto;
  display: inline-flex;
  gap: 10px;
`;

const SC = {
  ...ParentSC,
  RightButton
};

export default SC;
