export enum Actions {
  SHOW = 'show',
  HIDE = 'hide'
}

export interface State {
  show: boolean;
  title: string | null;
  content: React.ReactNode;
}

export type Action =
  | {
      type: Actions.SHOW;
      payload: { title: string | null; content: React.ReactNode };
    }
  | { type: Actions.HIDE };
