import type { Action, State, UrlCache } from './types';
import { Actions } from './types';

const sliderReducer = (state: State, action: Action): State => {
  const cacheSlide = (payload: UrlCache) => {
    const { id, previewUrl, downloadUrl } = payload;
    const cache = { ...state.cache };
    cache[id as string] = { previewUrl, downloadUrl };
    return {
      ...state,
      cache
    };
  };

  switch (action.type) {
    case Actions.SHOW:
      return {
        ...state,
        show: true
      };
    case Actions.HIDE:
      return {
        ...state,
        show: false
      };
    case Actions.CACHE:
      return cacheSlide(action.payload);
    case Actions.SET_CURRENT:
      return {
        ...state,
        current: action.payload
      };
    case Actions.SET_MAX_INDEX:
      return {
        ...state,
        maxIndex: action.payload
      };
    default:
      return state;
  }
};

export default sliderReducer;
