import { useTranslator } from '@hooks/useTranslator';
import SC from '../../style';

export const Header: React.FC = () => {
  const { t } = useTranslator();

  return (
    <SC.FormRow>
      <SC.HeaderLabel>{t('myProfile.password')}</SC.HeaderLabel>
    </SC.FormRow>
  );
};

export default Header;
