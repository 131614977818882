import { Trans } from 'react-i18next';

import SC from './style';

const MessageContent: React.FC<{
  icon: any;
  i18nKey: string;
  values?: any;
}> = ({ icon, i18nKey, values }) => {
  return (
    <SC.Container>
      <SC.IconBox>{icon}</SC.IconBox>
      <Trans
        i18nKey={i18nKey}
        values={values}
        components={{
          par: <p />,
          break: <br />,
          bold: <strong />,
          em: <em />
        }}
      />
    </SC.Container>
  );
};

export default MessageContent;
