import { useEffect, useMemo, useState } from 'react';

import { List, Table, useSearch, useTable } from '@angloeastern/react-library';
import { FileTypes } from '@components/index';
import { useConfig } from '@context/Config';
import { useSpDriveCtx } from '@context/Sharepoint/DriveCtx';
import type {
  SharePointfile,
  SharePointfileField,
  SharePointfiles
} from '@context/Sharepoint/types';
import { RootFolder } from '@context/Sharepoint/types';
import { getPageSlice } from '@utils/index';
import ListItem from './ListItem';
import TableHead from './TableHead';
import TableRow from './TableRow';

const initState = {
  total: 0,
  dataset: []
};

const useViewModel = () => {
  const {
    deviceCheck: { isAboveTablet },
    PER_PAGE
  } = useConfig();
  const {
    state: {
      driveId,
      isReady,
      data,
      page,
      rootName,
      currentFolder,
      searchToken,
      error,
      loadMore
    },
    setPage,
    seeMore,
    folderStackPop,
    isAtRootFolder,
    sortFiles
  } = useSpDriveCtx();
  const { term } = useSearch();
  const {
    state: { sortBy, sortOrder }
  } = useTable();
  const [state, setState] = useState<{
    total: number;
    dataset: SharePointfiles;
  }>(initState);
  const [isGallery, setIsGallery] = useState<boolean>(false);
  const { total, dataset } = state;
  const pageSize = parseInt(PER_PAGE || '12', 10);

  const atRootWithEmptyData = useMemo(() => {
    return data ? isAtRootFolder && !data.length : false;
  }, [data]);

  useEffect(() => {
    const rootCheck = !!(rootName && rootName === RootFolder.REPORTS_GALLERY);
    const folderCheck = !!(currentFolder && currentFolder.isGalleryFolder);
    setIsGallery(rootCheck || folderCheck);
  }, [rootName, currentFolder]);

  useEffect(() => {
    if (data) {
      const query = term.trim();
      let _dataset = sortFiles(sortBy as SharePointfileField, sortOrder);
      if (query !== '') {
        const pattern = new RegExp(`(${query})`, 'i');
        _dataset = data
          // .filter((file) => pattern.test(file.name))
          .map((file: SharePointfile) => {
            const fileCopy = { ...file };
            fileCopy.name = file.name.replace(pattern, '<span>$1</span>');
            return fileCopy;
          });
      }

      const { sliceStart, sliceEnd } = getPageSlice(_dataset, page, pageSize);

      setState({
        total: _dataset.length,
        dataset: _dataset.slice(sliceStart, sliceEnd)
      });
    } else {
      setState(initState);
    }

    return () => {
      setState(initState);
    };
  }, [data, page, term, sortBy, sortOrder]);

  useEffect(() => {
    setPage(1);
  }, [term]);

  return {
    noDataOrDrive: !data || !driveId,
    atRootWithEmptyData,
    showParentFolder: !isAtRootFolder && term === '',
    showGallery: !(
      !isGallery &&
      (currentFolder === undefined ||
        (currentFolder && currentFolder.type === FileTypes.FOLDER))
    ),
    isReady,
    error,
    currentFolder,
    dataset,
    slides: data,
    DisplayComponent: isAboveTablet ? Table : List,
    displayProps: isAboveTablet
      ? {
          rowComponent: TableRow,
          headComponent: TableHead,
          data: dataset
        }
      : {
          data: dataset,
          itemComponent: ListItem
        },
    paginationProps: {
      total,
      page,
      pageSize,
      navigate: setPage
    },
    searchToken,
    loadMore,
    onSeeMore: () => seeMore(true),
    folderStackPop
  };
};

export default useViewModel;
