export enum Indicator {
  AIM_LOW = 'AimLow',
  AIM_HIGH = 'AimHigh'
}

export enum IndicatorGauge {
  MORE = 'more than',
  LESS = 'less than',
  ABOVE = 'above',
  BELOW = 'below',
  EQUAL = 'equal to',
  HIGHER = 'higher than',
  LOWER = 'lower than'
}

export enum Color {
  BLACK = 'black',
  GREEN = 'green',
  YELLOW = 'yellow'
}

export enum Direction {
  FLAT = 'flat',
  UP = 'up',
  DOWN = 'down'
}

export const upIndicators = [
  IndicatorGauge.MORE,
  IndicatorGauge.ABOVE,
  IndicatorGauge.HIGHER
];

export const downIndicators = [
  IndicatorGauge.LESS,
  IndicatorGauge.BELOW,
  IndicatorGauge.LOWER
];

export interface KPI {
  title?: string | null;
  value: string;
  valueUnit: string;
  subValue?: string | null;
  subValueUnit?: string | null;
  subValueLabel?: string | null;
  subValueLabelUnit?: IndicatorGauge | string | null;
  indicator?: Indicator | string | null;
  threshold?: number | null;
  subValueExtentionValue?: number | null;
  footerLabel?: string | null;
  footerValue?: string | null;
}

export type NullableKPI = KPI | null;
