import SC from './style';

const BlueWorldMapLoader: React.FC<{
  top?: number;
  left?: number;
  widthOffset?: number;
}> = ({ top = 0, left = 0, widthOffset = 0 }) => {
  const props = {
    $top: top,
    $left: left,
    $widthOffset: widthOffset
  };
  return (
    <SC.LoaderOverlay {...props}>
      <SC.MapOverlay />
    </SC.LoaderOverlay>
  );
};

export default BlueWorldMapLoader;
