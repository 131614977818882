import { createContext, useContext, useEffect, useState } from 'react';

import { SearchProvider } from '@angloeastern/react-library';
import * as Api from './api';
import type { DriveName, SharepointCategory, SharepointDrive } from './types';
import { RootFolder } from './types';

export const Context = createContext({});

export const Provider: React.FC<{
  children: any;
}> = ({ children }) => {
  const [drives, setDrives] = useState<Array<SharepointDrive> | null>(null);
  const [error, setError] = useState<boolean>(false);

  const getDriveId = (drive: DriveName) => {
    if (drives) {
      const d = drives.find((d) => d.name === drive);
      return d ? d.id : null;
    }
    return null;
  };

  const getCategoryId = async (
    driveId: string,
    category: string,
    code = '',
    signal?: AbortSignal
  ) => {
    const fetchCategory =
      category === RootFolder.RESOURCES_PSC ||
      category === RootFolder.RESOURCES_LOOKOUT
        ? Api.getCategoriesTechnical
        : Api.getCategories;

    if (driveId) {
      const categories = await fetchCategory(driveId, signal, code);
      if (categories) {
        const cat = categories.find(
          (c: SharepointCategory) =>
            c.name?.toUpperCase().trim() === category?.toUpperCase().trim()
        );
        return cat ? cat.id : null;
      }
    }
    return null;
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    async function fetchData() {
      try {
        const data = await Api.getDrives(signal);
        setDrives(data);
        setError(false);
      } catch (e: any) {
        setError(true);
        setDrives(null);
      }
    }

    fetchData();

    return () => {
      controller.abort();
      setDrives(null);
    };
  }, []);

  return (
    <Context.Provider
      value={{ error, drives, getDriveId, getCategoryId, setDrives }}
    >
      <SearchProvider>{children}</SearchProvider>
    </Context.Provider>
  );
};

export const useSharepoint = () => {
  return useContext<any>(Context);
};
