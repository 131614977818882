import type { VesselMarker } from '../VesselMarkers/types';
import type { Action, State } from './types';
import { Actions } from './types';

const reducer = (state: State, action: Action): State => {
  const appendVoyage = (marker: VesselMarker) => {
    if (
      state.activeVoyage &&
      state.activeVoyage.voyages &&
      marker.timestamp &&
      marker.latitude &&
      marker.longitude
    ) {
      const activeVoyage = { ...state.activeVoyage };
      activeVoyage?.voyages?.push({
        startDateTime: marker.timestamp,
        latitude: marker.latitude,
        longitude: marker.longitude
      });
      return activeVoyage;
    }
    return state.activeVoyage;
  };
  switch (action.type) {
    case Actions.SET_ACTIVE_VOYAGE:
      return {
        ...state,
        activeVoyage: action.payload,
        noData: false,
        error: false
      };
    case Actions.UNSET_VOYAGE:
      return {
        ...state,
        activeVoyage: null,
        noData: false,
        error: false
      };
    case Actions.SET_ERROR:
      return {
        ...state,
        noData: false,
        error: true
      };
    case Actions.SET_NO_DATA:
      return {
        ...state,
        noData: true
      };
    case Actions.SET_CENTERED:
      return {
        ...state,
        centered: action.payload
      };
    case Actions.SET_CONTROLLER:
      return {
        ...state,
        abortController: action.payload
      };
    case Actions.APPEND_VOYAGE:
      return {
        ...state,
        activeVoyage: appendVoyage(action.payload)
      };

    default:
      return state;
  }
};

export default reducer;
