import { Button, SecondaryButton } from '@angloeastern/react-library';
import { useTranslator } from '@hooks/useTranslator';
import SC from './style';

const SeeMoreButton: React.FC<{
  loading: boolean;
  [x: string]: any;
}> = ({ loading, ...rest }) => {
  const { t } = useTranslator();

  const Btn = loading ? SecondaryButton : Button;

  return (
    <SC.Container $disabled={loading}>
      <Btn {...rest} disabled={loading}>
        {t('action.seeMore')}
      </Btn>
    </SC.Container>
  );
};

export default SeeMoreButton;
