import { useContext, useEffect, useMemo } from 'react';

import type { SortOrder } from '@angloeastern/react-library';
import { useSearch } from '@angloeastern/react-library';
import { useSharepoint } from '@context/Sharepoint';
import type { DriveName, SharePointfileField } from '@context/Sharepoint/types';
import createDataContext from '@context/createDataContext';
import { getCategories } from '../api';
import { actions, initialState } from './globals';
import reducer from './reducer';
import type { RootFolder } from './types';

export const { Context, Provider } = createDataContext(
  reducer,
  actions,
  initialState
);

export const useSharepointDrive = (driveName: DriveName) => {
  const { error: spError, drives, getDriveId } = useSharepoint();
  const { term, setTerm } = useSearch();
  const ctx = useContext<any>(Context);
  const {
    state: {
      data,
      driveId,
      rootName,
      rootCode,
      rootFolder,
      categoryId,
      searchToken,
      folderStack,
      loadMore
    },
    fetchData,
    resetData,
    setDriveId,
    setRootFolder,
    setRootName,
    setRootCode,
    folderStackPush,
    folderStackPop,
    search,
    setPage,
    seeMore,
    setBulkDownload
  } = ctx;

  const controller = new AbortController();
  const signal = controller.signal;

  const getRootFolder = async () => {
    const root = await getCategory(rootCode);
    setRootFolder(root);
  };

  const getCategory = async (code: string) => {
    const categories = await getCategories(driveId, signal, code);
    if (categories) {
      const category = categories.find((c: RootFolder) => {
        return c.name.toUpperCase().trim() === rootName.toUpperCase().trim();
      });
      return category;
    }
    return null;
  };

  const getCurrentFolder = () => {
    const stackLength = folderStack.length;
    if (stackLength) {
      return folderStack[stackLength - 1];
    }
    return rootFolder;
  };

  const getParentFolder = () => {
    const stackLength = folderStack.length;
    if (stackLength) {
      return stackLength === 1 ? rootFolder : folderStack[stackLength - 1];
    }
    return null;
  };

  const getParentFolderName = () => {
    const folder = getParentFolder();
    return folder ? folder.name : '';
  };

  const isAtRootFolder = useMemo(() => {
    return rootFolder && rootFolder.id && categoryId
      ? rootFolder.id === categoryId
      : false;
  }, [rootFolder, categoryId]);

  const sortFiles = (sortBy: SharePointfileField, sortOrder: SortOrder) => {
    return (data as Array<any>).sort((a, b) => {
      if (sortBy && a[sortBy] && b[sortBy]) {
        if (a[sortBy] === b[sortBy]) {
          return 0;
        } else if (sortOrder === 'ASC') {
          return a[sortBy] < b[sortBy] ? -1 : 1;
        } else {
          return a[sortBy] > b[sortBy] ? -1 : 1;
        }
      }
      return 0;
    });
  };

  async function doFetch() {
    if (rootCode && categoryId && driveId) {
      resetData();
      await fetchData(driveId, categoryId, signal);
    }
  }

  useEffect(() => {
    if (drives) {
      const id = getDriveId(driveName);
      setDriveId(id);
    }
  }, [drives, driveName]);

  useEffect(() => {
    if (driveId && rootName && rootCode) {
      getRootFolder();
    }
  }, [driveId, rootName, rootCode]);

  useEffect(() => {
    doFetch();
  }, [driveId, categoryId, rootCode]);

  useEffect(() => {
    if (categoryId) {
      if (term && term.trim() !== '') {
        resetData();
        search(driveId, categoryId, term);
      } else {
        doFetch();
      }
    }
  }, [driveId, categoryId, term, rootCode]);

  useEffect(() => {
    if (loadMore && searchToken) {
      search(driveId, categoryId, term, searchToken);
    }
  }, [loadMore, searchToken]);

  useEffect(() => {
    setTerm('');
  }, [rootCode]);

  useEffect(() => {
    setPage(1);
  }, [term]);

  useEffect(() => {
    controller.abort();
  }, []);

  return {
    state: { ...ctx.state, error: spError || ctx.state.error },
    folderStackPush,
    folderStackPop,
    setRootName,
    setRootCode,
    setPage,
    seeMore,
    resetData,
    setBulkDownload,
    getCurrentFolder,
    isAtRootFolder,
    getParentFolder,
    getParentFolderName,
    sortFiles
  };
};
