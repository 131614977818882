import SC from './style';

const Decorator: React.FC<{
  onClick(): void;
  isOpen: boolean;
  children: any;
}> = ({ isOpen, onClick, children }) => (
  <SC.Decorator $isOpen={isOpen} onClick={onClick}>
    {children}
  </SC.Decorator>
);

export default Decorator;
