import { faCircleQuestion } from '@fortawesome/pro-light-svg-icons';

import { Tooltip } from '@angloeastern/react-library';
import type { Size } from './style';
import SC from './style';
import useViewModel from './useViewModel';

const Item: React.FC<{
  title: string;
  body: any;
  tooltip?: string;
  disable?: boolean;
  size?: Size;
  children?: any;
  showBody?: boolean;
}> = ({
  title,
  body,
  tooltip,
  size = 'default',
  disable = false,
  children,
  showBody = true
}) => {
  const fontSize = useViewModel(disable, size);
  return fontSize ? (
    <SC.Container $size={size}>
      <SC.Title
        dangerouslySetInnerHTML={{ __html: title }}
        title={title}
        $fontSize={fontSize}
      ></SC.Title>
      <SC.IconBox>
        {tooltip && (
          <Tooltip direction="left" content={tooltip} width={280}>
            <SC.TooltipIcon icon={faCircleQuestion} />
          </Tooltip>
        )}
      </SC.IconBox>

      {showBody && (
        <SC.Body title={typeof body === 'string' ? body : ''}>{body}</SC.Body>
      )}
      <SC.Footer>{children}</SC.Footer>
    </SC.Container>
  ) : null;
};

export default Item;
