import { useFileViewer } from '@angloeastern/react-library';
import { useConfig } from '@context/Config';
import { createElement } from 'react';
import { useViewFile } from '../context';
import DesktopView from './DesktopView';
import Failure from './Failure';
import ImagePreview from './ImagePreview';
import Loader from './Loader';
import MobileView from './MobileView';
import NoPreview from './NoPreview';
import VideoPreview from './VideoPreview';

const useViewModel = () => {
  const { checked, valid, loading, isImage, isVideo, canPreview } =
    useViewFile();
  const {
    state: { show, selected }
  } = useFileViewer();
  const {
    deviceCheck: { isAboveTablet }
  } = useConfig();

  const showPreview = valid && selected && canPreview;

  let Display = null;

  if (show) {
    if (!checked) {
      Display = Loader;
    } else if (valid) {
      if (showPreview) {
        Display = showPreview
          ? loading && !checked
            ? Loader
            : isAboveTablet
              ? DesktopView
              : MobileView
          : null;
      } else if (isImage) {
        Display = ImagePreview;
      } else if (isVideo) {
        Display = VideoPreview;
      } else {
        Display = NoPreview;
      }
    } else {
      Display = Failure;
    }
  }

  return Display ? createElement(Display) : null;
};

export default useViewModel;
