import { faPaperPlane } from '@fortawesome/pro-light-svg-icons';
import {
  faCircleCheck,
  faCircleExclamation,
  faCircleXmark
} from '@fortawesome/pro-solid-svg-icons';

import { useMessageBox } from '@context/MessageBox';
import MessageContent from './MessageContent';
import SC from './style';

const useMessageBoxPresets = () => {
  const { showMessageBox } = useMessageBox();

  const showSuccess = (i18nKey: string, values?: any) =>
    showMessageBox(
      null,
      <MessageContent
        icon={<SC.SuccessIcon icon={faCircleCheck} />}
        i18nKey={i18nKey}
        values={values}
      />
    );

  const showFailure = (i18nKey: string, values?: any) =>
    showMessageBox(
      null,
      <MessageContent
        icon={<SC.FailureIcon icon={faCircleXmark} />}
        i18nKey={i18nKey}
        values={values}
      />
    );

  const showInformation = (i18nKey: string, values?: any) =>
    showMessageBox(
      null,
      <MessageContent
        icon={<SC.InformationIcon icon={faCircleExclamation} />}
        i18nKey={i18nKey}
        values={values}
      />
    );

  const showEmailSuccess = () => {
    showMessageBox(
      null,
      <MessageContent
        icon={<SC.EmailIcon icon={faPaperPlane} />}
        i18nKey="common:text.emailSuccess"
      />
    );
  };

  return {
    showEmailSuccess,
    showSuccess,
    showFailure,
    showInformation
  };
};

export default useMessageBoxPresets;
