import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  width: 100%;
  height: calc(100vh - 56px);
  overflow: hidden;
  justify-content: center;
  background: ${({ theme }) => theme.colors.platinum};
`;

const SC = {
  Container
};

export default SC;
