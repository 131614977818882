import type { Action, State } from './types';
import { Actions } from './types';

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case Actions.SET_ZOOM_LEVEL:
      return {
        ...state,
        zoomLevel: action.payload
      };
    case Actions.SET_FULLSCREEN:
      return {
        ...state,
        fullScreen: action.payload
      };
    case Actions.SET_CENTER:
      return {
        ...state,
        center: action.payload
      };
    case Actions.SHOW_LABELS:
      return {
        ...state,
        labelsShown: true
      };
    case Actions.HIDE_LABELS:
      return {
        ...state,
        labelsShown: false
      };
    default:
      return state;
  }
};

export default reducer;
