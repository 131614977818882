import { clarity } from 'clarity-js';
import { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';

import BlueWorldMapLoader from '@components/Loading/BlueWorldMapLoader';
import { Provider as ThemeProvider } from '@context/Theme';
import useFreshDesk from '@hooks/useFreshDesk';
import PublicArea from './PublicArea';
import RestrictedArea from './RestrictedArea';

const App: React.FC = () => {
  const auth = useAuth();
  const { preFillForm: setupFreshdesk } = useFreshDesk();

  useEffect(() => {
    if (process.env.REACT_APP_CLARITY_ID) {
      clarity.start({
        projectId: process.env.REACT_APP_CLARITY_ID,
        upload: 'https://m.clarity.ms/collect',
        track: true,
        content: true
      });
      // Enable if consent to write cookies is required from the user
      // clarity.consent();
      return () => {
        clarity.stop();
      };
    }
  }, [process.env.REACT_APP_CLARITY_ID]);

  if (auth.isLoading) {
    return (
      <ThemeProvider>
        <BlueWorldMapLoader />
      </ThemeProvider>
    );
  }

  if (auth.error) {
    return <PublicArea />;
  }

  if (auth.isAuthenticated) {
    setupFreshdesk();
    return <RestrictedArea />;
  } else {
    return <PublicArea />;
  }
};

export default withTranslation()(App);
