import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  display: flex;
  gap: 10px;
`;

const Menu = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 20;
`;

const AvatarBox = styled.div`
  flex: 0 0;
  text-align: right;
  width: 30px;
  height: 30px;
  max-width: 30px;
  max-height: 30px;
  align-self: center;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.darkMidnightBlue};
  cursor: pointer;

  @media only screen and (min-width: 768px) {
    width: 36px;
    height: 36px;
    max-width: 36px;
    max-height: 36px;
  }
`;

const Name = styled.div`
  display: none;

  @media only screen and (min-width: 768px) {
    white-space: nowrap;
    cursor: pointer;
    align-items: center;
    display: flex;
  }
`;

const SC = {
  Container,
  AvatarBox,
  Menu,
  Name
};

export default SC;
