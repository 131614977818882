import { useFileViewer } from '@angloeastern/react-library';
import SC from './style';

const VideoPreview: React.FC = () => {
  const {
    state: { selected }
  } = useFileViewer();

  return selected ? (
    <SC.Container>
      <SC.Video width={'100%'} controls>
        <source src={`${selected.url}?t=${new Date().getTime()}`} />
      </SC.Video>
    </SC.Container>
  ) : null;
};

export default VideoPreview;
