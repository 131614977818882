import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const Viewport = styled.div`
  width: 100%;
  height: 100%;
  flex: 1 0;
  background: transparent;
  margin: 14px 0;
  position: relative;
  text-align: center;

  @media only screen and (min-width: 1280px) {
    flex: 0 0;
    margin: 35px 0;
    border: 2px solid ${({ theme }) => theme.colors.turquoise};
    width: 1134px;
    height: 644px;
  }
`;

const Slides = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
`;

const Slide = styled.div.attrs({ 'aria-label': 'slide' })`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (min-width: 1280px) {
    width: 1134px;
    height: 644px;
  }
`;

const SlideInner = `
  width: 100%;
  object-fit: contain;

  @media only screen and (min-width: 1280px) {
    width: auto;
    height: 100%;
    margin: 0 auto;
  }
`;

const Image = styled.img.attrs({ 'aria-label': 'slide-image' })`
  ${SlideInner}
`;

const Video = styled.video.attrs({ 'aria-label': 'slide-video' })`
  ${SlideInner}
`;

const SlideTop = styled.div`
  display: flex;
  margin: 4px 22px;

  @media only screen and (min-width: 1280px) {
    width: auto;
    height: 100%;
    margin: 0 auto;
  }
`;

const SlideBottom = styled.div`
  align-self: center;
`;

const Filename = styled.div`
  flex: 1 0;
  text-align: left;
  align-self: center;
  font-size: 16px;
`;

const TopControls = styled.div`
  flex: 0 0;
  display: flex;
  justify-content: space-between;

  @media only screen and (min-width: 1280px) {
    flex: 0 0 60px;
  }
`;

const TopControl = styled.div`
  align-self: center;
  cursor: pointer;

  svg {
    font-size: 22px;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.brightYellow};
  }
`;

const CloseIcon = styled(TopControl)`
  display: none;

  @media only screen and (min-width: 1280px) {
    display: revert;
  }
`;

const BackIcon = styled(FontAwesomeIcon)`
  font-size: 18.3px;
  align-self: center;
  margin-right: 22px;

  @media only screen and (min-width: 1280px) {
    display: none;
  }
`;

const SC = {
  SlideTop,
  SlideBottom,
  Filename,
  TopControls,
  TopControl,
  CloseIcon,
  BackIcon,
  Viewport,
  Slides,
  Slide,
  Image,
  Video
};

export default SC;
