import type { Favorite } from '@context/Favorite/types';
import { useTranslator } from '@hooks/useTranslator';
import { useMemo } from 'react';
import Decorator from './Decorator';
import SC from './style';

const useViewModel = (options: Favorite[]) => {
  const { t } = useTranslator();

  const theOptions = useMemo(() => {
    const optionItems: { [x: string]: string } = {};
    for (const { guid, title } of options) {
      optionItems[guid as string] = title as string;
    }
    return optionItems;
  }, [options]);

  return {
    prompt: t('favorites.selectFavorite'),
    displayComponent: Decorator,
    itemComponent: SC.ListItem,
    options: theOptions,
    enableUnselect: false
  };
};

export default useViewModel;
