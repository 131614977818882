import type { ReactNode } from 'react';
import { createContext, useContext } from 'react';

export type ContextType = {
  moduleName?: string;
};

const Context = createContext({} as ContextType);

export const Provider: React.FC<{
  moduleName?: string;
  children: ReactNode;
}> = ({ moduleName, children }) => {
  return (
    <Context.Provider
      value={{
        moduleName
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useSharepointDocList = () => useContext(Context);

export default Provider;
