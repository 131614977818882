import { SearchBar } from '@angloeastern/react-library';
import { useTranslator } from '@hooks/useTranslator';

const SearchBarWithLiveToggle: React.FC<{ live?: boolean }> = ({
  live = true
}) => {
  const { t } = useTranslator();

  return <SearchBar live={live} placeHolder={t('placeHolder.search')} />;
};

export default SearchBarWithLiveToggle;
