import { useCallback } from 'react';
import { useAuth } from 'react-oidc-context';

const useFreshDesk = () => {
  const auth = useAuth();
  const w = window as any;

  const fillForm = (name: string, email: string) => {
    w?.FreshworksWidget('identify', 'ticketForm', {
      name,
      email
    });
  };

  const hideForm = () => w?.FreshworksWidget('hide');
  const showForm = () => w?.FreshworksWidget('open');
  const hideLauncher = () => w?.FreshworksWidget('hide', 'launcher');
  const showLauncher = () => w?.FreshworksWidget('show', 'launcher');

  const preFillForm = useCallback(() => {
    const userData =
      auth?.user?.profile?.sub && JSON.parse(auth?.user?.profile?.sub);
    fillForm(userData.fullName, userData.email);
  }, [auth]);

  return {
    fillForm,
    hideForm,
    showForm,
    preFillForm,
    hideLauncher,
    showLauncher
  };
};

export default useFreshDesk;
