import { faPaperPlane } from '@fortawesome/pro-light-svg-icons';

import {
  faCircleCheck,
  faCircleExclamation,
  faCircleXmark
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

interface BackgroundProps {
  $show: boolean;
}

interface ContainerProps {
  minHeight?: string;
  minWidth?: string;
}

const ToastMessage = styled.div`
  font-size: 18px;
  text-align: center;
`;

const Title = styled.h3`
  ${({ theme }) => theme.headings.messageTitle}
`;

const EmailIcon = styled(FontAwesomeIcon).attrs({ icon: faPaperPlane })`
  font-size: 68px;
  background: ${({ theme }) => theme.colors.white};
  color: cadetblue;
  align-self: center;
  cursor: pointer;
`;

const SuccessIcon = styled(FontAwesomeIcon).attrs({ icon: faCircleCheck })`
  font-size: 48px;
  color: green;
  background: ${({ theme }) => theme.colors.white};
  align-self: center;
  cursor: pointer;
`;

const FailureIcon = styled(FontAwesomeIcon).attrs({ icon: faCircleXmark })`
  font-size: 48px;
  color: red;
  background: ${({ theme }) => theme.colors.white};
  align-self: center;
  cursor: pointer;
`;

const InformationIcon = styled(FontAwesomeIcon).attrs({
  icon: faCircleExclamation
})`
  font-size: 68px;
  background: ${({ theme }) => theme.colors.white};
  color: blue;
  align-self: center;
  cursor: pointer;
`;

const Background = styled.div<BackgroundProps>`
  display: none;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 52;
  background: ${({ theme }) => theme.colors.black60};

  ${({ $show }) =>
    $show &&
    `
    display: flex;
  `}
`;

const Container = styled.div<ContainerProps>`
  background: ${({ theme }) => theme.colors.white};
  font-family: 'Inter';
  font-style: normal;
  width: 100%;
  height: 100%;
  align-self: flex-start;
  overflow: auto;
  ${({ theme }) => theme.scrollbar}
  display: flex;
  flex-direction: column;
  min-height: ${(props) => props.minHeight || '295px'};
  min-width: ${(props) => props.minWidth || '365px'};

  @media only screen and (min-width: 737px) {
    margin: 0 auto;
    align-self: center;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.14);
    border-radius: 4px;
    width: auto;
    height: auto;
    position: relative;
    max-width: 365px;
  }
`;

const Body = styled.div`
  text-align: center;
  padding: 30px 40px;
  flex: 1 0;
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px 40px 30px 40px;
  gap: 20px;
`;

const SC = {
  Title,
  ToastMessage,
  EmailIcon,
  SuccessIcon,
  FailureIcon,
  InformationIcon,
  Background,
  Container,
  Body,
  Footer
};

export default SC;
