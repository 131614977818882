import {
  faArrowLeft,
  faDownload,
  faXmark
} from '@fortawesome/pro-light-svg-icons';

import { download } from '@angloeastern/react-library';
import { useSlider } from '../../../context';
import { useSlide } from '../context';

const useViewModel = () => {
  const { closeSlider } = useSlider();
  const {
    fileName,
    state: { downloadUrl }
  } = useSlide();

  const onClose = () => closeSlider();

  return {
    icons: {
      arrow: faArrowLeft,
      download: faDownload,
      close: faXmark
    },
    fileName,
    downloadUrl,
    onClose,
    onDownload: () => download(downloadUrl)
  };
};

export default useViewModel;
