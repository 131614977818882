import { faCheck, faXmark } from '@fortawesome/pro-solid-svg-icons';

import { useFavorite } from '@context/Favorite';
import { useTranslator } from '@hooks/useTranslator';

const useViewModel = () => {
  const {
    state: { toast, status },
    hideToast
  } = useFavorite();
  const { t } = useTranslator();

  return {
    show: toast !== '',
    btnLabel: t('action.ok'),
    toast,
    status,
    icons: {
      success: faCheck,
      failure: faXmark
    },
    onClose: hideToast
  };
};

export default useViewModel;
