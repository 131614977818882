import { useTranslator } from '@hooks/useTranslator';
import { useSecurityTab } from '../context';

export const useViewModel = () => {
  const { t } = useTranslator('common', 'myProfile');
  const { isOffice365, handleCancel } = useSecurityTab();

  return {
    labels: {
      SSOMessage: t('SSOMessage'),
      cancel: t('cancel')
    },
    isOffice365,
    handleCancel
  };
};
