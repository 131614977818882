import useViewModel from './useViewModel';

const DesktopView: React.FC = () => {
  const { previewName, thePreviewUrl, iFrameStyle, handleLoad } =
    useViewModel();

  return (
    thePreviewUrl && (
      <iframe
        title={previewName}
        src={thePreviewUrl}
        onLoad={handleLoad}
        style={iFrameStyle}
      />
    )
  );
};

export default DesktopView;
