import type { Action, State } from './types';
import { Actions } from './types';

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case Actions.SET_CRUMBS:
      return {
        ...state,
        crumbs: action.payload
      };
    case Actions.SET_TITLE:
      return {
        ...state,
        title: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
