import PasswordForm from './PasswordForm';
import SSOEnabled from './SSOEnabled';
import { Provider } from './context';
import SC from './style';

export const SecurityTab: React.FC = () => {
  return (
    <Provider>
      <SC.Container>
        <SSOEnabled />
        <PasswordForm />
      </SC.Container>
    </Provider>
  );
};

export default SecurityTab;
