export enum BECategoryCodes {
  INJURY_FREE = 'INJURY_FREE',
  ISM_AUDIT = 'ISM_AUDIT',
  VESSEL_DETENTION = 'VESSEL_DETENTION',
  PSC = 'PSC',
  CO2_EMISSIONS = 'CO2_EMISSIONS',
  EEOI = 'EEOI',
  CII_RATING = 'CII_RATING',
  GHG_Rating = 'GHG_Rating',
  SHIP_AVAILABILITY = 'SHIP_AVAILABILITY',
  CHARTER_PARTY_COMPLIANCE = 'CHARTER_PARTY_COMPLIANCE',
  FUEL_CONSUMPTION = 'FUEL_CONSUMPTION',
  OFFICER_RETENTION = 'OFFICER_RETENTION',
  COC_OPEN_FINDINGS = 'COC_OPEN_FINDINGS',
  CREW_ON_TIME_RELIEF = 'CREW_ON_TIME_RELIEF',
  RIGHTSHIP_SAFETY_SCORE = 'RIGHTSHIP_SAFETY_SCORE',
  TIME_SINCE_LAST_SIRE = 'TIME_SINCE_LAST_SIRE',
  TIME_SINCE_LAST_CDI = 'TIME_SINCE_LAST_CDI',
  VETTING = 'VETTING',
  LTIF = 'LTIF',
  TRCF = 'TRCF',
  FINANCIAL_OPEX_COMMENT = 'FINANCIAL_OPEX_COMMENT'
}
