import styled from 'styled-components';

const Container = styled.li.attrs({ 'aria-label': 'sp-list-item' })`
  padding: 20px 18px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.brightGray};
  display: flex;
  line-height: 15px;
  color: ${({ theme }) => theme.colors.trout};
  align-items: center;
`;

const IconContainer = styled.div`
  flex: 0 0;
  align-self: center;
  line-height: 24px;
`;

const DetailsContainer = styled.div`
  flex: 1 0;
  padding: 0 18px;
  color: ${({ theme }) => theme.colors.trout};
`;

const DocName = styled.div`
  font-size: 13px;

  > span {
    background: ${({ theme }) => theme.colors.turquoiseBlue};
  }
`;

const DocDate = styled.div`
  font-size: 10px;
`;

const ActionsContainer = styled.div`
  flex: 0 0;
  align-self: center;
`;

const SC = {
  Container,
  IconContainer,
  DetailsContainer,
  ActionsContainer,
  DocName,
  DocDate
};

export default SC;
