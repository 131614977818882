import { useState, type ReactNode } from 'react';
import SC from './style';

export const AnimatedDiv: React.FC<{
  animation: string;
  duration?: number;
  className?: string;
  children: ReactNode;
}> = ({ animation, duration, className, children }) => {
  const possibleDuration = [0.5, 1.0, 1.5, 2.0, 2.5];
  const [theDuration] = useState(() => {
    return duration === undefined
      ? possibleDuration[Math.floor(Math.random() * possibleDuration.length)]
      : duration;
  });
  const classNames = `animate__animated animate__${animation}${className ? ` ${className}` : ''}`;

  return (
    <SC.Container $duration={theDuration} className={classNames}>
      {children}
    </SC.Container>
  );
};

export const BounceIn: React.FC<{
  className?: string;
  duration?: number;
  children: ReactNode;
}> = ({ className, duration, children }) => (
  <AnimatedDiv animation="bounceIn" className={className} duration={duration}>
    {children}
  </AnimatedDiv>
);

export const ZoomIn: React.FC<{
  className?: string;
  duration?: number;
  children: ReactNode;
}> = ({ className, duration, children }) => (
  <AnimatedDiv animation="zoomIn" className={className} duration={duration}>
    {children}
  </AnimatedDiv>
);

export const Flash: React.FC<{
  className?: string;
  duration?: number;
  children: ReactNode;
}> = ({ className, duration, children }) => (
  <AnimatedDiv animation="flash" className={className} duration={duration}>
    {children}
  </AnimatedDiv>
);

export const FlipInX: React.FC<{
  className?: string;
  duration?: number;
  children: ReactNode;
}> = ({ className, duration, children }) => (
  <AnimatedDiv animation="flipInX" className={className} duration={duration}>
    {children}
  </AnimatedDiv>
);

export const FlipInY: React.FC<{
  className?: string;
  duration?: number;
  children: ReactNode;
}> = ({ className, duration, children }) => (
  <AnimatedDiv animation="flipInY" className={className} duration={duration}>
    {children}
  </AnimatedDiv>
);

export const SlideInLeft: React.FC<{
  className?: string;
  duration?: number;
  children: ReactNode;
}> = ({ className, duration, children }) => (
  <AnimatedDiv
    animation="slideInLeft"
    className={className}
    duration={duration}
  >
    {children}
  </AnimatedDiv>
);

export const SlideInDown: React.FC<{
  className?: string;
  duration?: number;
  children: ReactNode;
}> = ({ className, duration, children }) => (
  <AnimatedDiv
    animation="slideInDown"
    className={className}
    duration={duration}
  >
    {children}
  </AnimatedDiv>
);

export const SlideInUp: React.FC<{
  className?: string;
  duration?: number;
  children: ReactNode;
}> = ({ className, duration, children }) => (
  <AnimatedDiv animation="slideInUp" className={className} duration={duration}>
    {children}
  </AnimatedDiv>
);

export const RollIn: React.FC<{
  className?: string;
  duration?: number;
  children: ReactNode;
}> = ({ className, duration, children }) => (
  <AnimatedDiv animation="rollIn" className={className} duration={duration}>
    {children}
  </AnimatedDiv>
);

export const FadeInTopLeft: React.FC<{
  className?: string;
  duration?: number;
  children: ReactNode;
}> = ({ className, duration, children }) => (
  <AnimatedDiv
    animation="fadeInTopLeft"
    className={className}
    duration={duration}
  >
    {children}
  </AnimatedDiv>
);
