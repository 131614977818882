import { useAuthUser } from '@context/AuthUser';
import { useProfile } from '@context/Profile';

const useViewModel = () => {
  const {
    state: { user }
  } = useProfile();
  const { initial } = useAuthUser();

  return {
    imgSrc: user?.profile?.imageData
      ? `data:image/jpeg;base64,${user.profile.imageData}`
      : null,
    initial
  };
};

export default useViewModel;
