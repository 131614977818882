import { createContext, useContext, useState } from 'react';

import { useProfile } from '@context/Profile';

const { REACT_APP_DEFAULT_LANGUAGE: DEFAULT_LANGUAGE } = process.env;

const Context = createContext({});

const initToastState = {
  show: false,
  message: '',
  success: undefined
};

export const Provider: React.FC<{
  children: any;
}> = ({ children }) => {
  const {
    state: {
      user: { profile: profileData }
    }
  } = useProfile();
  const [dirty, setDirty] = useState(false);
  const [confirmCancel, setConfirmCancel] = useState(false);
  const [locale, setLocale] = useState(
    profileData ? profileData.language : DEFAULT_LANGUAGE
  );
  const [toastState, setToastState] = useState<any>(initToastState);

  return (
    <Context.Provider
      value={{
        dirty,
        locale,
        confirmCancel,
        profile: profileData,
        toastState,
        setDirty,
        setLocale,
        setConfirmCancel,
        setToastState
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useProfileTab = () => {
  return useContext<any>(Context);
};
