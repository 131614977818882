import { InputType } from '@angloeastern/react-library';
import { useTranslator } from '@hooks/useTranslator';
import { useSecurityTab } from '../../context';

export const useViewModel = () => {
  const { t } = useTranslator('common', 'myProfile');
  const { currentPassword, setCurrentPassword } = useSecurityTab();

  return {
    label: t('currentPassword'),
    inputProps: {
      type: InputType.PASSWORD,
      name: 'currentPassword',
      value: currentPassword,
      onChange: setCurrentPassword,
      required: true
    }
  };
};
