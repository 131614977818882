import { Pagination as Base } from '@angloeastern/react-library';
import SC from './style';
import useViewModel from './useViewModel';

const Pagination: React.FC<{
  page: number;
  total: number;
  pageSize: number;
  navigate?(n: number): void;
  info?: boolean;
}> = ({ page, total, pageSize, navigate, info = false }) => {
  const hookData = useViewModel(page, total, pageSize, navigate, info);

  if (!hookData) return null;

  const { isAboveMobile, infoText, baseProps } = hookData;

  return (
    <SC.Pagination>
      {isAboveMobile && <SC.Info>{infoText}</SC.Info>}
      <SC.Navigation>
        <Base {...baseProps} />
      </SC.Navigation>
    </SC.Pagination>
  );
};

export default Pagination;
