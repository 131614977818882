import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 27px;
  padding: 27px 0;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 27px;
`;

const FormSection = styled.div`
  flex: none;
`;

const HeaderLabel = styled.label`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.formLabel};
  width: 300px;
`;

const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 0 16px;
  gap: 30px;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
    padding: 0 38px;
    width: 654px;
  }
`;
const ButtonsRow = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  flex-direction: column-reverse;
  padding: 30px 16px;
  border-top: 0.5px dotted ${({ theme }) => theme.colors.philippineGray};

  @media only screen and (min-width: 768px) {
    padding: 3px 38px 42px;
    flex-direction: row;
    gap: 46px;
    border: none;
  }
`;

const FormControl = styled.div`
  display: flex;
  flex-direction: row;
  gap: 9px;
  flex: 1 0;
`;

const Label = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.formLabel};
  width: 300px;
`;

const Input = styled.input`
  border: 0.5px solid ${({ theme }) => theme.colors.gray400};
  background: ${({ theme }) => theme.colors.alabasterGray};
  color: ${({ theme }) => theme.colors.formValue};
  border-radius: 8px;
  line-height: 20px;
  padding: 8px 14px;
  text-overflow: ellipsis;
`;

const SC = {
  Container,
  ButtonsRow,
  FormContainer,
  FormSection,
  FormControl,
  FormRow,
  Input,
  Label,
  HeaderLabel
};

export default SC;
