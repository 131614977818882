import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const Container = styled.div`
  width: 30px;
  align-self: center;

  &:hover {
    color: ${({ theme }) => theme.colors.darkCyan};
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.5));

    svg {
      color: ${({ theme }) => theme.colors.darkCyan};
    }
  }
`;

const Icon = styled(FontAwesomeIcon)`
  height: 24px;
  font-weight: 400;
  cursor: pointer;

  @media only screen and (min-width: 768px) {
    height: 20px;
    color: ${({ theme }) => theme.colors.black};
  }
`;

const Count = styled.div`
  font-size: 12px;
  position: absolute;
  border-radius: 22px;
  max-width: 24px;
  min-width: 16px;
  height: 14px;
  margin-top: -30px;
  margin-left: 10px;
  background: red;
  color: white;
  text-align: center;
  vertical-align: middle;
  line-height: 14px;
  padding: 1px 4px;
`;

const SC = {
  Container,
  Icon,
  Count
};

export default SC;
