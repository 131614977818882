import type { MouseEvent, TouchEvent } from 'react';
import { useCallback, useState } from 'react';

import { useConfig } from '@context/Config';
import { useSlide } from '../context';

const useViewModel = () => {
  const {
    fileName,
    state: { previewUrl },
    onPrevious,
    onNext
  } = useSlide();
  const { DUMMY_SLIDE: fallbackImage } = useConfig();
  const [touchStart, setTouchStart] = useState(-1);
  const [touchEnd, setTouchEnd] = useState(-1);

  const handleTouchStart = useCallback(
    (e: TouchEvent<HTMLImageElement>) => {
      setTouchStart(e.targetTouches[0].clientX);
    },
    [setTouchStart]
  );

  const handleTouchMove = useCallback(
    (e: TouchEvent<HTMLImageElement>) => {
      const clientX = e.targetTouches[0].clientX;

      setTouchEnd(clientX);
    },
    [setTouchEnd]
  );

  const handleTouchEnd = useCallback(() => {
    const diffX = touchEnd - touchStart;
    if (diffX <= -20) {
      onPrevious();
    } else if (diffX >= 20) {
      onNext();
    }
  }, [touchStart, touchEnd, onPrevious, onNext]);

  const handleMouseDown = useCallback(
    (e: MouseEvent<HTMLImageElement>) => {
      setTouchStart(e.clientX);
    },
    [setTouchStart]
  );

  const handleMouseUp = useCallback(
    (e: MouseEvent<HTMLImageElement>) => {
      const diffX = e.clientX - touchStart;
      if (diffX <= -20) {
        onPrevious();
      } else if (diffX >= 20) {
        onNext();
      }
    },
    [touchStart, onPrevious, onNext]
  );

  return {
    previewUrl,
    fileName,
    fallbackImage,
    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
    handleMouseDown,
    handleMouseUp
  };
};

export default useViewModel;
