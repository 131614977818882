import type { Dispatch } from 'react';

import * as Api from '@context/KpiPermissions/api';
import type { Action } from './types';
import { Actions } from './types';

const getPermissions = async (signal?: AbortSignal) => {
  const data = await Api.fetchPermissions(signal);
  return data;
};

export const fetchData = (dispatch: Dispatch<Action>) => async () => {
  try {
    const permissions = await getPermissions();
    dispatch({ type: Actions.FETCH_DATA, payload: permissions });
  } catch (e: any) {
    dispatch({ type: Actions.SET_ERROR });
  }
};
