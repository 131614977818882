import type { ReactNode } from 'react';
import { createContext, useContext } from 'react';

import { useSharepointDrive } from '@context/Sharepoint/Drive';
import type { Ctx } from '../Drive/types';
import type { DriveName } from '../types';

export const Context = createContext({} as Ctx);

export const Provider: React.FC<{
  driveName: DriveName;
  children: ReactNode;
}> = ({ driveName, children }) => {
  const ctx = useSharepointDrive(driveName);

  return <Context.Provider value={{ ...ctx }}>{children}</Context.Provider>;
};

export const useSpDriveCtx = () => useContext(Context);

export default Provider;
