import { faBell } from '@fortawesome/pro-solid-svg-icons';

import { Feature, useFeatureToggles } from '@context/FeatureToggles';
import { useSystemNotifications } from '../context';
import SC from './style';

const AlertIcon: React.FC = () => {
  const { isEnabled } = useFeatureToggles();
  const { showList, unreadCount, setShowList } = useSystemNotifications();

  return isEnabled(Feature.NOTIFICATIONS) ? (
    <SC.Container
      onClick={() => {
        setShowList(!showList);
      }}
    >
      <SC.Icon icon={faBell} />
      {unreadCount > 0 && (
        <SC.Count>{unreadCount > 99 ? `99+` : unreadCount}</SC.Count>
      )}
    </SC.Container>
  ) : null;
};

export default AlertIcon;
