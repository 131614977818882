import { Tooltip } from '@angloeastern/react-library';
import SC from './style';
import useViewModel from './useViewModel';

const UMLink: React.FC = () => {
  const { icon, text, handleClick } = useViewModel();

  return (
    <SC.Container onClick={handleClick}>
      <Tooltip direction="right" content={text}>
        <SC.Icon icon={icon} />
      </Tooltip>
      <SC.MobileText>{text}</SC.MobileText>
    </SC.Container>
  );
};

export default UMLink;
