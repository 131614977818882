import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import { DropdownStyles } from '@angloeastern/react-library';

const { Header } = DropdownStyles;

const Container = styled.div`
  align-items: center;
  display: flex;

  > div > div:nth-child(2) > div {
    min-width: 90px;
  }

  @media only screen and (min-width: 768px) {
    min-width: 80px;
    min-height: 56px;
  }
`;

const HeadContainer = styled(Header).attrs({ 'aria-label': 'item-value' })`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  color: ${({ theme }) => theme.colors.licorice};
  background: transparent;
  padding: 0 !important;
  min-height: 32px;
  box-shadow: none;

  .circle {
    display: none;
  }

  &:after {
    font-size: 24px;
    right: 4px;
    align-items: center;
    height: 100%;
  }

  & + div li {
    padding: 12px 16px !important;
    text-wrap: nowrap;
  }

  div[aria-label='item-label'] > div > div {
    font-size: 14px;
    line-height: 14px;
  }

  @media only screen and (min-width: 768px) {
    padding: 12px 2px !important;
    min-height: 30px;
    height: 100%;
    gap: 5px;
    flex-direction: row;

    &:hover {
      color: ${({ theme }) => theme.colors.darkCyan};
      filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.5));
    }

    .fleet {
      font-size: 8px;
    }

    &:after {
      line-height: 56px;
      right: 5px;
      font-size: 14.4px;
    }

    div[aria-label='item-label'] > div > div {
      line-height: 30px;
      padding-left: 0px;
    }
  }
`;

const LabelContainer = styled.div`
  flex: 1 0;
  background: transparent;
  align-self: center;
  font-size: 14px;
  padding-right: 15px;

  @media only screen and (min-width: 768px) {
    padding-right: 0;
  }
`;

const LangIcon = styled(FontAwesomeIcon)`
  align-self: center;
  padding-right: 15px;

  @media only screen and (min-width: 768px) {
    padding-right: 0;
  }
`;

const SC = {
  Container,
  HeadContainer,
  LabelContainer,
  LangIcon
};

export default SC;
