import LoadFavorite from './LoadFavorite';
import Loader from './Loader';
import SaveFavorite from './SaveFavorite';
import SelectVessels from './SelectVessels';
import ToastMessage from './ToastMessage';
import SC from './style';
import useViewModel from './useViewModel';

const Form: React.FC = () => {
  const { isOpen, ref } = useViewModel();

  return (
    <SC.Container $isOpen={isOpen} ref={ref}>
      <ToastMessage />
      <Loader />
      <SC.InnerContainer>
        <LoadFavorite />
        <SelectVessels />
        <SaveFavorite />
      </SC.InnerContainer>
    </SC.Container>
  );
};

export default Form;
