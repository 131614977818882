import { faPaperPlane } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const Row = styled.tr.attrs({ 'aria-label': 'sp-list-row' })`
  &:hover > td {
    background: ${({ theme }) => theme.colors.brightGray};
  }
`;

const Cell = styled.td`
  padding: 6px 24px;
  border-top: 1px solid ${({ theme }) => theme.colors.brightGray};
  font-size: 13px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.trout};

  > div {
    justify-content: unset;

    &[aria-label='fail-icon'] > div {
      display: flex;
      align-self: flex-start;
    }
  }
`;

const Icon = styled.div`
  width: 36px;
  margin: 0 auto;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  padding-right: 15px;
  cursor: pointer;
`;

const Name = styled.span`
  cursor: pointer;

  > span {
    background: ${({ theme }) => theme.colors.turquoiseBlue};
  }
`;

const SuccessIcon = styled(FontAwesomeIcon).attrs({ icon: faPaperPlane })`
  font-size: 68px;
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.blueLagoon};
  align-self: center;
  cursor: pointer;
`;

const SC = {
  Row,
  Cell,
  Icon,
  Name,
  SuccessIcon
};

export default SC;
