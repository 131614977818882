import { faTrash } from '@fortawesome/pro-regular-svg-icons';

import { useTranslator } from '@hooks/useTranslator';

const useViewModel = () => {
  const { t } = useTranslator();

  return {
    tooltip: t('favorites.delete'),
    icon: faTrash
  };
};

export default useViewModel;
