import { SelectDropdown } from '@angloeastern/react-library';
import SC from './style';
import useViewModel from './useViewModel';

const LangSelector: React.FC = () => {
  const { currentLang, options, Decorator, handleClick } = useViewModel();

  return (
    <SC.Container>
      <SelectDropdown
        selected={currentLang}
        options={options}
        handleSelect={handleClick}
        enableUnselect={false}
        displayComponent={Decorator}
      />
    </SC.Container>
  );
};

export default LangSelector;
