import { Document } from 'react-pdf';

import useViewModel from './useViewModel';

const MobileView: React.FC = () => {
  const {
    thePages,
    thePreviewUrl,
    onDocumentLoadError,
    onDocumentLoadSuccess
  } = useViewModel();

  return (
    <Document
      file={thePreviewUrl}
      onLoadSuccess={onDocumentLoadSuccess}
      onLoadError={onDocumentLoadError}
    >
      {thePages}
    </Document>
  );
};

export default MobileView;
