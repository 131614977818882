import styled from 'styled-components';

const Container = styled.div.attrs({ 'aria-label': 'footer-flex' })`
  @media only screen and (min-width: 640px) {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`;

const SC = {
  Container
};

export default SC;
