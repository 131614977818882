import useSendReportEmail from '@components/SharePointDocList/TableRow/useSendReportEmail';
import useSpFile from '@components/SharePointDocList/hooks/useSpFile';
import { FileTypes } from '@components/index';
import type { SharePointfile } from '@context/Sharepoint/types';
import useMessageBoxPresets from '@hooks/useMessageBoxPresets';

const useViewModel = (data: SharePointfile) => {
  const {
    ctx,
    modifiedOn,
    isReady,
    hasError,
    fileType,
    handleClick,
    handleDownload
  } = useSpFile(data);
  const { sendEmail } = useSendReportEmail(data);
  const {
    state: { canBulkDownload }
  } = ctx;
  const { showEmailSuccess } = useMessageBoxPresets();

  const handleSendEmail = async () => {
    const result = await sendEmail();
    if (result) {
      showEmailSuccess();
    }
  };

  return {
    isReady,
    hasError,
    fileType,
    modifiedOn,
    dataType: data.type === FileTypes.PDF ? data.type.toUpperCase() : data.type,
    showDownload:
      data.type !== FileTypes.GALLERY &&
      ((data.type === FileTypes.FOLDER && canBulkDownload) ||
        data.type !== FileTypes.FOLDER),
    failIcon: { color: '', size: 18 },
    onNameClick: handleClick,
    onDownload:
      data.type === FileTypes.FOLDER ? handleSendEmail : handleDownload
  };
};

export default useViewModel;
