import http from '@api/http';
import { errorTracker } from '@utils/errorTracker';

export const fetchPermissions = async (signal?: AbortSignal) => {
  try {
    const { data } = await http.get(`/kpis/permission`, { signal });
    return data;
  } catch (error: any) {
    if (error.name !== 'AbortError' && error.message !== 'canceled') {
      errorTracker(error);
    }
    throw error;
  }
};
