import { faCircleQuestion } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
  padding: 18px 30px 0px;
`;

const Heading = styled.h3`
  font-family: 'Franklin Gothic Book', sans-serif;
  margin: 0;
  font-weight: 400;
  font-size: 22px;
  line-height: 25px;
  color: ${({ theme }) => theme.colors.tiffanyBlue};
`;

const Icon = styled(FontAwesomeIcon).attrs({
  icon: faCircleQuestion
})`
  width: 24px;
  height: 28px;
  color: ${({ theme }) => theme.colors.buttonBorder};
`;

const SC = {
  Container,
  Heading,
  Icon
};

export default SC;
