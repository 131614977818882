import { useCallback, useContext } from 'react';

import { useAuthUser } from '@context/AuthUser';
import createDataContext from '../createDataContext';
import * as actions from './actions';
import vesselReducer from './reducer';
import type { State } from './types';

const initialState: State = {
  organizations: null,
  organization: null,
  error: false
};

export const { Context, Provider } = createDataContext(
  vesselReducer,
  { ...actions },
  initialState
);

export const useOrganizations = () => {
  const ctx = useContext<any>(Context);
  const { orgAeCodes } = useAuthUser();
  const {
    state: { organizations }
  } = ctx;

  const fetchData = useCallback(
    async (signal?: AbortSignal) => {
      if (orgAeCodes && !organizations) {
        await ctx.fetchData(orgAeCodes, signal);
      }
    },
    [orgAeCodes, organizations]
  );

  return {
    ...ctx,
    fetchData
  };
};
