import { ZoomIn } from '@components/Animations/Div';
import styled from 'styled-components';

const Container = styled(ZoomIn).attrs<{ $duration?: number }>(
  ({ $duration }) => ({
    duration: $duration
  })
)`
  background: white;
  position: relative;
  flex: 1 0;
`;

const SC = {
  Container
};

export default SC;
