import { faFilter } from '@fortawesome/pro-light-svg-icons';

import { useFavorite } from '@context/Favorite';
import { useVessels } from '@context/Vessels';
import { useTranslator } from '@hooks/useTranslator';

const useViewModel = () => {
  const {
    state: { isOpen, isDisabled },
    setIsFormOpen
  } = useFavorite();
  const { t } = useTranslator();

  const {
    state: { favorite }
  } = useVessels();

  return {
    icon: faFilter,
    tooltip: t('favorites.filter'),
    isOpen: isDisabled ? false : isOpen,
    count: favorite ? favorite.vesselAeCodes.length : null,
    onClick: () => setIsFormOpen(!isOpen)
  };
};

export default useViewModel;
