import type { Action, State } from './types';
import { Actions } from './types';

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case Actions.FETCH_DATA:
      return {
        ...state,
        user: action.payload,
        error: false
      };
    case Actions.UPDATE:
      return {
        ...state,
        user: {
          ...state.user,
          profile: action.payload
        }
      };
    case Actions.SHOW_MENU:
      return {
        ...state,
        showMenu: action.payload
      };
    case Actions.SHOW_FORM:
      return {
        ...state,
        showForm: action.payload
      };
    case Actions.SET_ERROR:
      return {
        ...state,
        error: true
      };
    case Actions.SET_ERROR_UNAUTHORIZED:
      return {
        ...state,
        unauthorized: true
      };
    default:
      return state;
  }
};

export default reducer;
