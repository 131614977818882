import { Buffer } from 'buffer';

import { apiGet, apiPost } from '@api/callAPI';
import Base64 from '@utils/base64';

const useDownload = () => {
  const b64toBlob = (data: any, contentType = '', sliceSize = 512) => {
    const byteCharacters = Buffer.from(data, 'base64').toString('binary');
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  const fileDownload = (
    document: Document,
    fileData: any,
    fileName: string,
    fileType: string
  ) => {
    const blob = b64toBlob(fileData, fileType);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
  };

  const getDownload = (url: string, cache = false, signal?: AbortSignal) =>
    apiGet(`/common/download/${Base64.encode(url)}`, cache, { signal });

  const postDownload = (url: string, cache = false, signal?: AbortSignal) =>
    apiPost(`/common/download`, { url: Base64.encode(url) }, cache, { signal });

  return {
    b64toBlob,
    fileDownload,
    getDownload,
    postDownload
  };
};

export default useDownload;
