import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons';

import { Tooltip } from '@angloeastern/react-library';
import useFreshDesk from '@hooks/useFreshDesk';
import { useTranslator } from '@hooks/useTranslator';
import SC from './style';

const FreshdeskLink: React.FC = () => {
  const { t } = useTranslator();
  const { showForm } = useFreshDesk();

  return (
    <SC.Container onClick={showForm}>
      <Tooltip direction="right" content={t('menu.help')}>
        <SC.Icon icon={faQuestionCircle} />
      </Tooltip>
      <SC.MobileText>{t('menu.help')}</SC.MobileText>
    </SC.Container>
  );
};

export default FreshdeskLink;
