import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: white;
  flex-direction: column;
`;

const TopRow = styled.div`
  display: flex;
  flex: 0 0 182px;
  flex-direction: column;
  justify-content: center;
  padding: 30px 20px 0;

  @media only screen and (min-width: 768px) {
    padding: 40px 20px 0;
  }
`;

const Title = styled.h1`
  align-self: center;
  color: ${({ theme }) => theme.colors.darkMidnightBlue};
  font-weight: normal;
  font-size: 32px;
  margin: 0;
  text-align: center;

  @media only screen and (min-width: 768px) {
    font-size: 49px;
  }
`;

const Message = styled.h3`
  align-self: center;
  margin: 0;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.licorice};
  text-align: center;

  @media only screen and (min-width: 768px) {
    max-width: 706px;
    font-size: 20px;
    line-height: 30px;
  }
`;

const BottomRow = styled.div`
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
  gap: 10px;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 20px 40px;
    padding: 0 54px 30px;
  }
`;

const BottomSide = styled.div`
  display: flex;
  flex: 1 0;

  @media only screen and (min-width: 768px) {
    flex: 1 0 20%;
  }
`;

const BottomRight = styled(BottomSide)`
  color: transparent;
  display: none;

  @media only screen and (min-width: 768px) {
    display: flex;
  }
`;

const BottomLeft = styled(BottomSide)`
  align-items: end;
  justify-content: start;
  padding-bottom: 40px;

  @media only screen and (min-width: 768px) {
    white-space: nowrap;
  }
`;

const HomeIcon = styled.div`
  display: flex;
  flex: 0 0 40px;
  height: 40px;
  position: relative;
  justify-content: center;
  align-items: center;
`;

const CircleIcon = styled(FontAwesomeIcon)`
  font-size: 32px;
  position: absolute;

  @media only screen and (min-width: 768px) {
    font-size: 40px;
  }
`;

const HouseIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  justify-content: center;
  align-items: center;

  @media only screen and (min-width: 768px) {
    font-size: 25px;
  }
`;

const SupportIcon = styled.div`
  position: relative;
  width: 32px;
  height: 32px;

  @media only screen and (min-width: 768px) {
    width: 40px;
    height: 40px;
  }
`;

const EnvelopeIcon = styled(FontAwesomeIcon)`
  position: absolute;
  bottom: -5px;
  font-size: 32px;

  @media only screen and (min-width: 768px) {
    font-size: 40px;
  }
`;

const HeadsetIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 0;
  left: 50%;
  font-size: 20px;
  transform: translateX(-50%);

  @media only screen and (min-width: 768px) {
    font-size: 25px;
  }
`;

const BottomCenter = styled.div<{ $imageUri: string }>`
  display: flex;
  flex: 1 0 60%;
  background-image: url(/images/${({ $imageUri }) => $imageUri});
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;

  @media only screen and (min-width: 768px) {
    background-size: auto;
  }
`;

const AnchorBox = styled.a`
  display: flex;
  gap: 15px;
  align-items: center;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.darkMidnightBlue};
  width: 100%;
  justify-content: center;

  &:hover {
    color: ${({ theme }) => theme.colors.darkCyan};
  }

  @media only screen and (min-width: 768px) {
    justify-content: left;
  }
`;

const CtaBox = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.darkMidnightBlue};
  width: 100%;
  justify-content: center;

  &:hover {
    color: ${({ theme }) => theme.colors.darkCyan};
  }

  @media only screen and (min-width: 768px) {
    justify-content: left;
  }
`;

const CtaGroup = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  flex-direction: column;

  @media only screen and (min-width: 768px) {
    gap: 26px;
  }
`;

const CtaText = styled.div`
  font-weight: 300;
  font-style: normal;
  font-size: 20px;
  line-height: 22px;

  @media only screen and (min-width: 768px) {
    font-size: 25px;
    line-height: 30px;
  }
`;

const SC = {
  Container,
  TopRow,
  BottomRow,
  Title,
  Message,
  BottomSide,
  BottomCenter,
  BottomRight,
  BottomLeft,
  SupportIcon,
  HomeIcon,
  HouseIcon,
  CircleIcon,
  EnvelopeIcon,
  HeadsetIcon,
  AnchorBox,
  CtaBox,
  CtaText,
  CtaGroup
};

export default SC;
