import { Button, SecondaryButton } from '@angloeastern/react-library';
import SC from './style';
import useViewModel from './useViewModel';

const ButtonsRow: React.FC = () => {
  const { labels, onCancel, onUpdate } = useViewModel();

  return (
    <SC.Container>
      <SecondaryButton onClick={onCancel}>{labels.cancel}</SecondaryButton>
      <Button onClick={onUpdate}>{labels.update}</Button>
    </SC.Container>
  );
};

export default ButtonsRow;
