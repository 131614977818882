import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const Container = styled.div.attrs({ 'aria-label': 'um-link' })`
  background: transparent;
  padding: 14px 15px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);

  @media only screen and (min-width: 640px) {
    border-top: unset;
    background: ${({ theme }) => theme.colors.darkMidnightBlue};
    padding: 14px;
    box-sizing: border-box;
    cursor: pointer;
    width: 56px;
    height: 56px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  color: white;
  font-size: 21px;

  &:hover {
    color: ${({ theme }) => theme.colors.maize};
  }
`;

const MobileText = styled.div`
  display: inline-block;
  margin-left: 14px;
  text-transform: uppercase;

  @media only screen and (min-width: 640px) {
    display: none;
  }
`;

const SC = {
  Container,
  Icon,
  MobileText
};

export default SC;
