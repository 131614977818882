import {
  faCircle,
  faEnvelope,
  faHeadset,
  faHouse
} from '@fortawesome/pro-light-svg-icons';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useTranslator } from '@hooks/useTranslator';

const useViewModel = () => {
  const navigate = useNavigate();
  const { t, currentLanguage } = useTranslator('errorpage');

  const goToHome = useCallback(() => {
    const localePage =
      !currentLanguage || currentLanguage === 'en'
        ? '/'
        : `/${currentLanguage}`;
    navigate(localePage);
  }, [currentLanguage]);

  return {
    mailTo: `mailto:clientportal@angloeastern.com`,
    icons: {
      headset: faHeadset,
      envelope: faEnvelope,
      house: faHouse,
      circle: faCircle
    },
    emailToSupport: t('emailToSupport'),
    goToHomepage: t('goToHomepage'),
    goToHome
  };
};

export default useViewModel;
